import { combineReducers, compose, applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import wizardReducer from './wizard/reducer'
import campaignsReducer from './campaigns/reducer'
import hmReducer from './reducer'

export const rootReducer = combineReducers({ hmReducer, wizardReducer, campaignsReducer })

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
)

export default createStore(rootReducer, enhancer)
