/* eslint-disable class-methods-use-this,no-param-reassign */
import cloneDeep from 'lodash/cloneDeep'
import flow from 'lodash/flow'
import { getValueByFieldType, getResultValue, isDefined } from './utils'
import { validateForm, isFormValid, isFieldValid, Constrain, validateField } from './validator'

/**
 * FormManager
 *
 * Simple immutable form manager to help managing form and field states.
 * All mutation methods returns a new instance of the form so you
 * can properly update the rendering.
 *
 * @example
 * // Configure some form fields and pass them to the FormManager:
 * const form = new FormManager({
 *     firstName: {
 *       type: 'text',
 *       validations: [
 *         { test: Constrain.REQUIRED, value: true, message: 'Name is required' }
 *       ]
 *     },
 *     lastName: {
 *       type: 'text',
 *       validations: [
 *         { test: Constrain.REQUIRED, value: true, message: 'Name is required' }
 *       ]
 *     }
 * })
 *
 * // Manipulate the form
 * const nameField = form.get('firstName')
 * const nameValue = form.value('lastName')
 * const updatedForm = form.update('firstName', { value: 'My New Name' })
 * const validatedForm = form.validate()
 * const isFormValid = form.isValid()
 *
 * @example
 * // Updating and propagating changes to render
 * const [form, updateForm] = useValue(new FormManager({...}))
 *
 * // Somewhere else in your code
 * function handleChange(name, value) {
 *    updateForm(form.update(name, { value }))
 * }
 *
 * function render() {
 *   return (
 *     <input value={form.value('firstName')} onChange={value => handleChange('firstName', value)}>
 *   )
 * }
 *
 * If you need to work on the form values and fields before the data is submitted
 * use the `addBeforeSubmitCallback` method which is called right before each
 * campaign patch request.
 * Here you can update fields and values using either `updateValue` or simply `update`
 * to update any of the definitions, the resulting form will be sent to the server.
 *
 * Note: Callbacks added through `addBeforeSubmitCallback` are called after the form
 * has been validated with the user input, so if you change values and need to know
 * that they are valid, you will have to call `validate()` again in your code
 * before returning the updated form instance.
 *
 * @example
 * wizardForm.addBeforeSubmitCallBack(form => {
 *  const definition = form.get()
 *  const { myField, ...otherFields } = definition
 *
 *  // Make changes to your field and or value here
 *
 *  return form.update({
 *    ...otherFields,
 *    myField: { ...myField }
 *  })
 *})
 *
 * @author Jair Milanes <jair.milanesjr@gsquad.io>
 */
export class FormManager {
  definition

  name

  callbacks = {
    before: [],
    after: []
  }

  constructor(formName, definition, values) {
    this.name = formName
    this.definition = Object.keys(definition).reduce((def, name) => {
      const { valid, dirty, errors, validations } = definition[name]
      return {
        ...def,
        [name]: {
          ...definition[name],
          value: getResultValue(name, definition, values),
          valid: (errors || []).length === 0 && (!isDefined(valid) || (isDefined(valid) && valid)),
          dirty: !isDefined(dirty) ? false : dirty,
          errors: errors || [],
          validations: validations || []
        }
      }
    }, definition)
  }

  keys() {
    return Object.keys(this.definition)
  }

  /**
   * Simple method used to check if a form has the given name, helpful
   * when checking if the form you have is the form you want.
   *
   * @param formName The form name, normally defined in the form file.
   * @returns {boolean} True if the form name matches, false otherwise
   */
  is(formName) {
    return this.name === formName
  }

  /**
   * @public
   * @param name
   * @returns {Object}
   */
  get(name = null) {
    return name ? this.definition[name] : this.definition
  }

  /**
   * Returns all form values or a value of a given field by it's name.
   *
   * @public
   * @param {string} [name] (Optional) The input name to get it's value
   * @returns {Object|string|number|[]} The form value object or s single field value
   */
  value(name = undefined) {
    if (name) {
      return this.definition[name] ? this.definition[name].value : null
    }
    return this.keys().reduce(
      (values, field) => ({
        ...values,
        [field]: this.definition[field].value
      }),
      {}
    )
  }

  new(definition, preserveCallbacks) {
    const form = new FormManager(this.name, definition)
    if (preserveCallbacks) {
      form.callbacks = { ...this.callbacks }
    }
    return form
  }

  /**
   * Saves the full form or a given field by the name to the local storage to be used later.
   * If field name is provided and append is true, it appends the field to the previous
   * item stored in local storage with same key
   *
   * @param key
   * @returns {FormManager}
   */
  cache(key, name = undefined, append = true) {
    const previousItem = JSON.parse(localStorage.getItem(key || this.name))

    localStorage.setItem(
      key || this.name,
      JSON.stringify(
        name
          ? {
              ...((append && previousItem) || {}),
              [name]: this.value(name)
            }
          : this.value()
      )
    )
    return this
  }

  /**
   * Removes provided key from the local storage
   *
   * @param {*} key
   * @returns {FormManager}
   */

  clearCache(key) {
    if (key) {
      localStorage.removeItem(key)
    }
    return this
  }

  /**
   * Restore form values from local storage and returns a new
   * instance.
   *
   * It also removes the value from local storage if pop is true
   *
   * @param key
   * @param orData
   * @returns {FormManager}
   */
  restore(key, orData, pop = true) {
    const cached = localStorage.getItem(key || this.name)
    if (cached) {
      const data = JSON.parse(cached) || orData
      const definition = cloneDeep(this.definition)

      Object.keys(data).forEach((name) => {
        if (name in definition) {
          definition[name].value = data[name]
          definition[name].dirty = true
        }
      })

      if (pop) localStorage.removeItem(key)

      return this.new(definition, true)
    }

    return this
  }

  /**
   * Updates a single field in the form and returns a
   * new FormManager instance with the updated field.
   *
   * Returning a brand new instance allows form ui's
   * to re-render when there are changes to the form,
   * and prevents coupled references.
   *
   * After the field value is updated, the field also
   * gets validated so to allow for individual field
   * validations in the UI.
   *
   * @public
   * @param {string} [name] (Optional) The input name to update
   * @param {object} definition The new partial or full field definition
   * @param {boolean} [skipValidation=false] (Optional) Flag to skip validating the form after an update
   * @returns {FormManager} An updated new instance of FormManager
   */
  update(name, definition, skipValidation = false) {
    const form = this.new(
      {
        ...cloneDeep(this.definition),
        [name]: cloneDeep({
          valid: !definition.errors || !(definition.errors || []).length,
          value: null,
          validations: [],
          ...(this.definition[name] || {}),
          ...definition,
          dirty: true,
          errors: definition.errors || (this.definition[name] || {}).errors || null
        })
      },
      true
    )
    return skipValidation ? form : form.validate(name)
  }

  /**
   * Merges a new value to the existing field value.
   * This means that inputs with array or object values will be merged and
   * not replaced, so values will be overwritten if equal.
   *
   * @param {string} name The field name
   * @param {string|number|boolean|array|object} value The value to be merged
   * @param skipValidation True if the validation call at the end should be skipped
   * @returns {FormManager}
   */
  updateValue(name, value, skipValidation = false) {
    const clone = cloneDeep(this.definition)

    const field = clone[name]
    const form = this.new(
      {
        ...clone,
        [name]: {
          ...field,
          valid: true,
          dirty: true,
          value: getValueByFieldType(field, value)
        }
      },
      true
    )
    return skipValidation ? form : form.validate(name)
  }

  /**
   * Maps api validation error objects to form fields.
   * The error object is an object with field names as
   * props and an array of string error messages.
   *
   * @example
   * {
   *   inputOne: ['Invalid value'],
   *   inputTwo: ['Invalid value']
   * }
   *
   * @param errors Object
   * @returns {FormManager}
   */
  mapErrors(errors) {
    const definition = Object.keys(errors).reduce(
      (def, name) => ({
        ...def,
        [name]: {
          ...def[name],
          errors: errors[name]
        }
      }),
      this.get()
    )
    return new FormManager(this.name, definition, this.value())
  }

  /**
   * Checks if a given field has the REQUIRED constrain.
   *
   * @public
   * @param {string} [name] (Optional) The input name to check for the REQUIRED validation
   * @returns {boolean} True if is required false otherwise.
   */
  isRequired(name) {
    const { validations } = this.definition[name]
    return (
      (validations || []).find((constrain) => constrain.test === Constrain.REQUIRED) !== undefined
    )
  }

  /**
   * Checks if the form has any field in a "dirty" state, meaning
   * the user has changed it's value at least once.
   *
   * @returns {boolean} True if the form is dirty, false otherwise
   */
  isDirty() {
    return this.keys().reduce((dirty, field) => {
      if (dirty) {
        return dirty
      }
      return this.definition[field].dirty
    }, false)
  }

  /**
   * Checks if the form is in a "pristine" state by checking if
   * every field in the form is not "dirty", meaning the user
   * never changed any of the values, so the form is "pristine".
   *
   * @returns {boolean} True if the form is "pristine" state, false otherwise
   */
  isPristine() {
    return this.keys().reduce((pristine, field) => {
      if (!pristine) {
        return pristine
      }
      return !this.definition[field].dirty
    }, true)
  }

  /**
   * If the form has no validations, it is considered safe to submit.
   *
   * @returns {boolean}
   */
  isSafe() {
    return (
      Object.keys(this.definition).reduce(
        (ac, key) => ac + (this.definition[key].validations || []).length,
        0
      ) === 0
    )
  }

  /**
   * Validates the full form or a given field by the name, either way it
   * returns a new instance of FormManager.
   *
   * @public
   * @param {string} [name] (Optional) The input name to validate or null to validate the full form.
   * @returns {FormManager} A new instance of the FormManager with the updated fields validity.
   */
  validate(name = undefined) {
    if (!name) {
      return this.new(validateForm(this.definition), true)
    }

    return this.new(
      {
        ...this.definition,
        [name]: validateField(this.definition[name])
      },
      true
    )
  }

  /**
   * Checks if the whole form is valid or a given field by the name.
   *
   * @public
   * @param {string} [name] (Optional) A field name to validate instead of the full form
   * @returns {boolean} True if the form is valid, false otherwise
   */
  valid(name = undefined) {
    if (!name) {
      return isFormValid(this.get())
    }
    return isFieldValid(this.definition[name])
  }

  /**
   * Resets the form state and value by setting every field validity to
   * pristine and value to null.
   *
   * @returns {FormManager}
   */
  reset() {
    return this.new(
      this.keys().reduce((definition, field) => {
        definition[field] = this.resetField(definition[field], true)
        return definition
      }, cloneDeep(this.definition)),
      true
    )
  }

  /**
   * Resets the form error state by setting every field validity to
   * pristine.
   *
   * Note: After using this method all validation errors are removed,
   * even if the value is invalid.
   *
   * @returns {FormManager}
   */
  resetState() {
    return this.new(
      this.keys().reduce((definition, field) => {
        return {
          ...definition,
          [field]: this.resetField(definition[field])
        }
      }, cloneDeep(this.definition)),
      true
    )
  }

  /**
   * Resets he state of a single field anf returns a new one with
   * the pristine state.
   *
   * Optionaly you can reset the value also by passing the second parameter
   * as true.
   *
   * @param field The field object to reset
   * @param andValue
   * @returns {Object}
   */
  resetField(field, andValue) {
    return {
      ...cloneDeep(field),
      dirty: false,
      valid: true,
      errors: [],
      value: andValue ? null : field.value
    }
  }

  hasCallback(func1, runtime) {
    return this.callbacks[runtime].find((func) => `${func}` === `${func1}`) !== undefined
  }

  runCallbacks(beforeOrAfter, metadata) {
    if (beforeOrAfter === 'before') {
      return flow(...this.callbacks.before)(this, metadata)
    }
    return flow(...this.callbacks.after)(this, metadata)
  }

  addBeforeSubmitCallBack(callback) {
    if (!this.hasCallback(callback, 'before')) {
      this.callbacks.before.push(callback)
    }
    return this
  }

  addAfterSubmitCallBack(callback) {
    if (!this.hasCallback(callback, 'after')) {
      this.callbacks.after.push(callback)
    }
    return this
  }
}
