import styled from 'styled-components'
import { Card } from '@elevate_security/elevate-component-library'

export const ButtonsWrapper = styled.div`
  .wrapper-link {
    [class^='Link__RouterLink'] {
      text-decoration: underline;
    }
  }
`

export const WrapperCard = styled(Card)`
  margin: 0;
  background: #f5f6f8;
  padding: 20px;

  & > div:first-child {
    background: transparent;
    padding: 0;
    margin: 0 0 10px 0;
    height: auto !important;
  }

  & > div:last-child {
    margin: 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 100%;
`

export const BaseContainer = styled.div`
  max-width: 680px;
  min-width: 680px;
`

export const FileUploadContainer = styled(BaseContainer)`
  flex: 1 0 auto;
  padding-top: 24px;
`

export const InstructionsContainer = styled(BaseContainer)`
  flex: 0 1 auto;
  margin-top: 24px;
`

export const GoToCampaignSessions = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
`
