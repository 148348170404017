import { customerSuccessLink, linkDemoFile } from '../../../../constants'

export const extractErrorsUploadingRooms = (errors) => {
  const { base_errors, row_errors } = errors
  const errorsList = {
    uploadErrors: base_errors,
    validationErrors: []
  }

  base_errors.forEach((base_error) => errorsList.push(base_error))

  Object.keys(row_errors).forEach((row) =>
    errorsList.validationErrors.push({ row, error: row_errors[row] })
  )

  return errorsList
}

export const downloadSampleCsv = (e) => {
  e.preventDefault()
  window.location.href = linkDemoFile
}

export const goToCustomerSuccessPage = (e) => {
  e.preventDefault()
  window.open(customerSuccessLink, '_blank')
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  // eslint-disable-next-line no-restricted-properties
  return `(${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]})`
}

export const getDropZoneState = (isValid) => {
  if (isValid) {
    return 'valid'
  }

  if (isValid !== null && !isValid) {
    return 'error'
  }
  return 'default'
}

export const getErrorMessage = (rejectedFile, uploadFail) => {
  if (uploadFail) {
    return 'Some errors were found while validating your file. Please resolve the errors and try again'
  }

  if (rejectedFile) {
    return 'You can only upload one file at a time.'
  }

  return 'Unsupported file format. Please make sure you’re using a CSV.'
}
