/* eslint-disable no-param-reassign */
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { matchPath } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import capitalize from 'lodash/capitalize'
import { STEP, STEPS } from '../../constants'

export function campaignOutOfSync(id, campaign) {
  return id && (!campaign.id() || id !== campaign.id())
}

export function formOutOfSync(id, formName, compareId, wizardForm) {
  if (!id && (!wizardForm || !wizardForm.is(formName))) {
    return true
  }
  return (
    !wizardForm ||
    !wizardForm.is(formName) ||
    (id && id !== wizardForm.value('id')) ||
    (id && compareId && compareId !== wizardForm.value('id'))
  )
}

export function stepOutOfSync(wizardStep, currentStep) {
  return wizardStep !== currentStep
}

export function isStepReady(wizardForm, formName, id, campaign) {
  return wizardForm && wizardForm.is(formName) && (!id || id === campaign.id()) && !campaign.busy
}

/**
 * Spreads out the behaviour tags api response into their
 * own categories.
 *
 * Each behavior get's a label which is created from the tag name
 * last portion, and formatted to a user readable string.
 *
 * @param data The /behavior api response
 * @returns {{behaviors: *, audiences: *, short: *}}
 */
export function spreadBehaviours(data, org) {
  const customShortTagName = `hackersmind-${org}-short`
  const customShortTag = data.find((tag) => tag.name.includes(customShortTagName))
  return {
    behaviors: data
      .filter((tag) => tag.name.includes('behavior'))
      .map((behavior) => ({
        ...behavior,
        label: capitalize(behavior.name.split('-').pop())
      })),
    audiences: data
      .filter((tag) => tag.name.includes('audience'))
      .map((audience) => ({
        ...audience,
        label: capitalize(audience.name.split('-').pop())
      })),
    /**
     * If the tag hackersmind-{orgname}-short exists this tag should be used.
     * If tag hackersmind-{orgname}-short DOES NOT EXIST hackersmind-short should be used.
     */
    shortTagOrg: customShortTag || data.find((tag) => tag.name.includes('hackersmind-short'))
  }
}

export const mapNamesToBehaviors = (names, behaviors) => {
  if (Array.isArray(behaviors) && Array.isArray(names)) {
    return names
      .filter((name) => !name.includes('audience'))
      .map((name) => {
        const selected = behaviors.find((behavior) => behavior.name.includes(name))
        return selected ? selected.label : name
      })
  }
  return []
}

export function interpolateParams(step, params) {
  return step.split('/').reduce((url, fragment) => {
    if (fragment.substr(0, 1) === ':') {
      const keyword = fragment.substr(1)

      if (keyword in params) {
        return url.replace(fragment, params[keyword])
      }
    }
    return url
  }, step)
}

export function getUrl(step, params, query) {
  const queryString = Object.keys(query || {}).reduce(
    (qs, key) => qs.concat(`${key}=${query[key]}`),
    ''
  )
  return `${interpolateParams(step, params)}${queryString ? `?${queryString}` : ''}`
}

export function getStepFromPathName(pathname) {
  const name = Object.keys(STEP).find((key) => {
    const match = matchPath(pathname, {
      path: STEP[key],
      exact: true,
      strict: false
    })
    return match !== null
  })
  return name ? STEP[name] : null
}

export function getStepByIndex(index) {
  return STEPS[index] ? STEP[STEPS[index]] : null
}

export function getStepIndex(wizardStep) {
  const idx = STEPS.reduce((rs, stepName, index) => {
    if (wizardStep === STEP[stepName]) {
      return index
    }
    return rs
  }, -1)

  return idx >= 0 ? idx : 0
}

export const getHTML = (state) => {
  if (!state) return null
  return draftToHtml(convertToRaw(state.getCurrentContent()))
}

export function useActions(actions, deps) {
  const dispatch = useDispatch()
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : deps
  )
}
