export const ControlType = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  TOGGLE: 'toggle',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  SELECT: 'select',
  MULTI_SELECT: 'multi-select',
  NUMBER: 'number',
  DATE: 'date',
  HIDDEN: 'hidden',
  BOOL: 'bool'
}
