import React from 'react'
import PropTypes from 'prop-types'
import { ConfirmationModal } from '../../../../../../components'
import { pluralize } from '../../../../../../services/utils'

function DeleteConfirmationModal({ rooms, onConfirm, onClose }) {
  const plural = rooms.length > 1
  // eslint-disable-next-line react/no-unstable-nested-components
  function Message() {
    return (
      <span>
        You&apos;re about to remove
        <strong>{` ${rooms.length} ${pluralize('session', plural)} `}</strong>for this campaign.
      </span>
    )
  }

  return (
    <ConfirmationModal
      data-id="hm-sessions-delete-confirmation"
      isOpen
      headerText={`Remove ${pluralize('Session', plural)}`}
      bodyText={<Message />}
      helpText="Are you sure you want to perform this action?"
      confirmText={`Yes, Remove ${pluralize('Session', plural)}`}
      dismissText="No, Don't Remove"
      onClose={() => onClose()}
      onConfirm={() => onConfirm()}
    />
  )
}

DeleteConfirmationModal.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

DeleteConfirmationModal.defaultProps = {
  onClose: () => null,
  onConfirm: () => null
}

export default DeleteConfirmationModal
