import styled from 'styled-components'
import Layout from '@elevate_security/elevate-component-library/dist/Layout'

export const Row = styled(Layout).attrs({ container: true })`
  padding: 0;
  margin: 0;
`
export const Column = styled(Layout).attrs({ item: true })`
  padding: 0;
  margin: 0;
`

export const HeaderContainer = styled.div`
  h1 {
    margin-bottom: 0;
  }
`

export const TabsContainer = styled.div`
  ul[role='tablist'] {
    margin: 0;
  }
`

export const GoToSessionsUpload = styled.div`
  position: absolute;
  right: 48px;
`
