import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import Moment from 'moment'
import Timezone from 'moment-timezone'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation, Redirect } from 'react-router-dom'
import Header from '@elevate_security/elevate-component-library/dist/Header'
import Tabs from '@elevate_security/elevate-component-library/dist/Tabs'
import Tab from '@elevate_security/elevate-component-library/dist/Tab'
import { campaignsSelector, wizardSelector } from '../../services/redux/selectors'
import * as wizardActions from '../../services/redux/wizard/actions'
import {
  HM_CAMPAIGNS_BASE_PATH,
  HM_OVERVIEW_BASE_PATH,
  HM_BASE_PATH,
  CAMPAIGN_STATUS_NAME,
  ROLLOUT_TYPE
} from '../../constants'
import { useActions } from '../CampaignWizard/utils'
import {
  formatSurveyPercentages,
  getCampaignCompletionGraph,
  getlineGRaphOptions,
  getInfoPieGraphSurvey
} from './utils'
import {
  downloadCompletionReportCSV,
  downloadScheduleSessionLinksCSV,
  downloadScheduleEnrollmentCSV,
  getCampaignStatistics
} from '../../services/redux/overview/actions'
import { HeaderContainer, TabsContainer, GoToSessionsUpload } from './styles'
import { ActivityIndicator, Badge, CampaignToasts, IconLink } from '../../components'
import SurveyResponses from './components/SurveyResponses'
import ScoreBoxes from './components/ScoreBoxes'
import CampaignMetrics from './components/CampaignMetrics'
import CampaignInfoHeader from './components/CampaignInfoHeader'
import Sessions from '../CampaignWizard/components/CampaignSessions/components/Sessions'
import CampaignSessionsUpload from '../CampaignWizard/components/CampaignSessionsUpload'
import { UNLOAD_WIZARD } from '../../services/redux/wizard/types'
import PageHeader from '../../components/PageHeader'
import PredefinedLeadSessionsManager from './components/PredefinedLeadSessionsManager'
import ScoreSummary from './components/ScoreSummary'

function CampaignOverview(props) {
  const { history } = props
  const { id: campaignId } = useParams()
  const location = useLocation()
  const { campaign, behaviors } = useSelector(wizardSelector)
  const { campaignMessages } = useSelector(campaignsSelector)
  const dispatch = useDispatch()
  const { getCampaign, getCampaignRooms, getCampaignLeads, getCampaignTargets, getTags } =
    useActions(wizardActions)
  const data = campaign ? campaign.data() : null

  if (!campaignId) return <Redirect from={window.location.origin} to={HM_BASE_PATH} />

  const [state, setState] = useState({
    completion_percentage: 0,
    bugs_in_human_hardware: {},
    campaignStartDate:
      data && data.start_date ? Moment(Timezone.tz(data.start_date, data.timezone).utc()) : null,
    campaignEndDate:
      data && data.end_date
        ? Moment(Timezone.tz(campaign.end_date, campaign.timezone).utc())
        : null,
    attack_vectors: {},
    survey_responses_completion_percentage: 0,
    survey_average: 0,
    survey_responses_total: 0,
    survey_responses_percentages: {
      not_at_all_likely: 0,
      probably_not_likely: 0,
      neutral: 0,
      likely: 0,
      extremely_likely: 0
    },
    dates: {}
  })

  useEffect(() => {
    getCampaign(campaignId)
    dispatch(getCampaignStatistics(campaignId, setState))
    if (!behaviors.length) getTags()
    if (!campaign.isTargetsLoaded()) getCampaignTargets({ campaignId })
    if (!campaign.isLeadsLoaded()) getCampaignLeads({ campaignId })
    if (!campaign.isRoomsLoaded()) getCampaignRooms({ campaignId })
    return () =>
      dispatch({
        type: UNLOAD_WIZARD
      })
  }, [])

  const isLoading =
    !data ||
    !data.id ||
    !campaign.isLeadsLoaded() ||
    !campaign.isRoomsLoaded() ||
    !campaign.isTargetsLoaded()

  const isManageSessionTab = location.pathname.includes('manage-sessions')
  const isSessionsUpload = location.pathname.includes('manage-sessions/upload')
  const isSessionsManual = isManageSessionTab && !isSessionsUpload

  const handleOnTabChange = (tab) => {
    history.push(
      tab === 0
        ? `${HM_OVERVIEW_BASE_PATH}/${campaignId}`
        : `${HM_OVERVIEW_BASE_PATH}/${campaignId}/manage-sessions`
    )
  }

  const handleDownloadCsv = ({ name }) => {
    if (name === 'download-results') dispatch(downloadCompletionReportCSV(campaignId))
    if (name === 'download-session-links') dispatch(downloadScheduleSessionLinksCSV(campaignId))
    if (name === 'download-enrollment')
      dispatch(
        downloadScheduleEnrollmentCSV({ rooms: campaign.getRooms(), campaignName: data.name })
      )
  }

  const lineGRaphOptions = getlineGRaphOptions('percentOverTime')

  const renderScoreBoxes = (
    <ScoreBoxes
      surveyCount={state.survey_responses_total > 0}
      formattedSurveyScores={formatSurveyPercentages(state.survey_responses_percentages)}
    />
  )

  const renderScoreSummary = (
    <ScoreSummary
      formattedSurveyScores={formatSurveyPercentages(state.survey_responses_percentages)}
    />
  )

  const formattedSurveyPercentages = formatSurveyPercentages(state.survey_responses_percentages)
  const infoPieGraphSurvey = getInfoPieGraphSurvey(formattedSurveyPercentages)

  const renderOverview = (
    <>
      <CampaignInfoHeader
        campaign={data}
        behaviors={behaviors}
        handleDownloadCsv={handleDownloadCsv}
      />
      <hr />
      <SurveyResponses
        surveyCount={state.survey_responses_total > 0}
        scoreBoxes={renderScoreBoxes}
        surveyProps={infoPieGraphSurvey}
        scoreSummary={renderScoreSummary}
      />
      <hr />
      <CampaignMetrics
        campaignProps={getCampaignCompletionGraph(state.dates)}
        completed={state.completion_percentage}
        surveyResponse={state.survey_responses_completion_percentage}
        lineGRaphOptions={lineGRaphOptions}
        attackVectors={state.attack_vectors}
        bugs={state.bugs_in_human_hardware}
        surveyAvg={state.survey_average}
      />
    </>
  )
  const breadcrumbLinks = [
    {
      label: 'Hacker’s Mind',
      link: HM_CAMPAIGNS_BASE_PATH
    },
    {
      label: 'Campaigns',
      link: HM_CAMPAIGNS_BASE_PATH
    },
    {
      label: data && data.name ? data.name : 'Campaign',
      link: `${HM_OVERVIEW_BASE_PATH}/${campaignId}`
    }
  ]

  const renderPageHeaderTitle = (name) =>
    name ? (
      <>
        {name}
        <Badge badgeColor={data.status} data-id={`hm-campaigns-row-status-${data.status}`}>
          {CAMPAIGN_STATUS_NAME[data.status]}
        </Badge>
      </>
    ) : (
      'Campaign'
    )

  const renderPearScheduledManager = () => (
    <>
      {isSessionsManual && (
        <GoToSessionsUpload>
          <IconLink
            text="Add sessions via CSV upload"
            iconName="Upload"
            fontWeight="bold"
            onClick={() =>
              history.push(`${HM_OVERVIEW_BASE_PATH}/${campaignId}/manage-sessions/upload`)
            }
          />
        </GoToSessionsUpload>
      )}
      {isSessionsManual && <Sessions filter modal />}
      {isSessionsUpload && (
        <CampaignSessionsUpload
          wizardView={false}
          onGoToCampaignSessions={() => {
            history.push(`${HM_OVERVIEW_BASE_PATH}/${campaignId}/manage-sessions`)
          }}
        />
      )}
    </>
  )

  const isPredefinedLeads = campaign.getType() === ROLLOUT_TYPE.PREDEFINED_LEADS
  const rows = isPredefinedLeads ? campaign.sessions : campaign.rooms
  const managerTitle = `Manage Sessions${rows.length ? ` (${rows.length})` : ''}`

  return (
    <>
      <CampaignToasts messages={campaignMessages} fixed width="60%" />
      <HeaderContainer>
        <Header>
          <PageHeader
            title={renderPageHeaderTitle(data && data.name)}
            breadcrumbLinks={breadcrumbLinks}
          />
        </Header>
      </HeaderContainer>
      {isLoading && !isSessionsManual && <ActivityIndicator active />}
      <TabsContainer>
        <Tabs onChange={handleOnTabChange}>
          <Tab title="Campaign Overview" selected={!isManageSessionTab}>
            {!isLoading && renderOverview}
          </Tab>
          <Tab title={managerTitle}>
            {isPredefinedLeads ? <PredefinedLeadSessionsManager /> : renderPearScheduledManager()}
          </Tab>
        </Tabs>
      </TabsContainer>
    </>
  )
}
CampaignOverview.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
}
export default CampaignOverview
