import { createBrowserHistory } from 'history'
import { HM_CAMPAIGNS_BASE_PATH, STEP } from '../../../constants'
import { getStepIndex, getUrl, spreadBehaviours } from '../../../scenes/CampaignWizard/utils'
import { FormManager } from '../../form'
import {
  getWizardForm,
  getOrganizationId,
  getCampaignInstance,
  getWizardCurrentStep,
  getUser,
  getCompany,
  getEmailTemplate,
  getCampaignId,
  getNextStep,
  canSkipWizardStep
} from '../selectors'
import {
  SET_WIZARD_STEP,
  SET_STEPS_SUBMITTED,
  CAMPAIGN_LOADING,
  SET_WIZARD_CAMPAIGN,
  SET_CAMPAIGN_ROOMS,
  SET_WIZARD_FORM,
  SET_TAGS,
  BEFORE_SUBMIT_WIZARD,
  AFTER_SUBMIT_WIZARD,
  SUBMIT_WIZARD_ERROR,
  WIZARD_FORM_INVALID,
  SET_PARTICIPANT_GROUPS,
  SET_WIZARD_PROPS,
  UNLOAD_WIZARD,
  SET_WIZARD_EMPLOYEE_COUNT,
  SET_EMAIL_TEMPLATES,
  SET_CAMPAIGN_LEADS,
  SET_CAMPAIGN_TARGETS,
  SET_DYNAMICALLY_TARGETS,
  SET_ROLLOUT_TYPE,
  SET_CAMPAIGN_SESSIONS
} from './types'
import * as api from './api'
import { setCampaignStatuses, createCampaignMessage } from '../campaigns/actions'
import * as campaignApi from '../campaigns/api'
import { getCampaignMessage } from '../../../scenes/Campaigns/utils'
import { HM_CUSTOM_ERROR_MESSAGE_FETCH_CAMPAIGN, DEFAULT_API_MESSAGES } from '../../api'

export function setWizardForm(form) {
  return { type: SET_WIZARD_FORM, data: form }
}

export const setWizardStep = (step) => ({ type: SET_WIZARD_STEP, data: step })

export const setStepsSubmitted = (step) => ({ type: SET_STEPS_SUBMITTED, data: step })

export const setWizardModalProps = (props) => ({ type: SET_WIZARD_PROPS, data: props })

export const createCampaign = (campaign) => async (dispatch, getState) => {
  const orgId = getOrganizationId(getState())
  const { data, error } = await api.createCampaign({ ...campaign.getInfo(), organization: orgId })
  if (!error) {
    dispatch({
      type: SET_WIZARD_CAMPAIGN,
      data
    })
    dispatch(setCampaignStatuses())
  }
}

export const getCampaign = (id) => async (dispatch) => {
  const history = createBrowserHistory()
  dispatch({
    type: CAMPAIGN_LOADING
  })
  const { data, error } = await api.fetchCampaign({ id })

  if (!error) {
    dispatch({
      type: SET_WIZARD_CAMPAIGN,
      data
    })
  } else {
    const message = HM_CUSTOM_ERROR_MESSAGE_FETCH_CAMPAIGN[error.response.status]
    if (message) {
      dispatch(createCampaignMessage(message, 'error'))
    } else {
      dispatch(createCampaignMessage(DEFAULT_API_MESSAGES[404]))
    }
    history.push(HM_CAMPAIGNS_BASE_PATH)
  }
}

export function getGroups() {
  return async (dispatch, getState) => {
    const { data, error } = await api.fetchGroups(getOrganizationId(getState()))

    if (!error) {
      const { results } = data
      dispatch({
        type: SET_PARTICIPANT_GROUPS,
        data: results.filter((group) => !group.deleted)
      })
    }
  }
}

export const getEmployeeCount = () => {
  return async (dispatch) => {
    const {
      data: { count },
      error
    } = await api.fetchEmployees()

    if (!error) {
      dispatch({
        type: SET_WIZARD_EMPLOYEE_COUNT,
        data: count
      })
    }
  }
}

export const getTags = () => async (dispatch, getState) => {
  const { data, error } = await api.fetchTags()
  const state = getState()
  const { shortname } = getCompany(state)
  if (!error) {
    dispatch({
      type: SET_TAGS,
      data: spreadBehaviours(data, shortname)
    })
  }
}

export const getCampaignRooms =
  ({ campaignId, filter, formatted, cb }) =>
  async (dispatch) => {
    const { data, error } = await api.fetchCampaignRooms({ campaignId, filter, formatted })

    if (!error) {
      dispatch({
        type: SET_CAMPAIGN_ROOMS,
        campaignId,
        data
      })

      if (typeof cb === 'function') {
        cb(data.results)
      }

      return data.results
    }

    return null
  }

export const getCampaignLeads =
  ({ campaignId }) =>
  async (dispatch) => {
    const { data, error } = await campaignApi.fetchCampaignLeads({ campaignId })

    if (!error) {
      dispatch({
        type: SET_CAMPAIGN_LEADS,
        campaignId,
        data
      })

      return data
    }

    return null
  }

export const saveSessionRoom =
  ({ form, rooms, formatted, cb }) =>
  async (dispatch, getState) => {
    const validatedForm = form.validate()

    if (!validatedForm.valid()) {
      if (cb) {
        cb({ error: false }, validatedForm)
      }
      return null
    }

    /**
     * This callback can be used to update or change values before
     * the form is submitted to the server.
     */
    const updatedForm = validatedForm.runCallbacks('before', { rooms })

    if (!(updatedForm instanceof FormManager)) {
      throw new Error(
        'Form before and after submit callbacks must return an instance of FormManager.'
      )
    }

    const { id, ...values } = updatedForm.value()
    Object.keys(values).forEach((key) => {
      if (values[key] === null) delete values[key] // removing keys with value null
    })

    const request = id
      ? updateCampaignRooms({ room: { id, ...values }, formatted, rooms, cb })
      : addCampaignRooms({ room: values, formatted, rooms, cb })

    return request(dispatch, getState)
  }

export const addCampaignRooms =
  ({ room, formatted, cb }) =>
  async (dispatch, getState) => {
    const campaign = getCampaignInstance(getState())
    const organization = getOrganizationId(getState())
    const rooms = [
      {
        ...room,
        organization,
        campaign: campaign.id(),
        role: 'temp'
      }
    ]

    const result = await api.addCampaignRooms({ rooms, formatted })
    const { error, data } = result

    if (!error) {
      dispatch({
        type: SET_CAMPAIGN_ROOMS,
        data: {
          results: [...campaign.getRooms(), ...data]
        }
      })
    }

    if (cb) cb(result)
  }

export const updateCampaignRooms =
  ({ room, rooms, formatted, cb }) =>
  async (dispatch, getState) => {
    const campaign = getCampaignInstance(getState())
    const index = rooms.findIndex((rm) => rm.id === room.id)
    const oldRoom = rooms[index]
    const payload = [{ ...oldRoom, ...room }]
    const result = await api.updateCampaignRooms({ rooms: payload, formatted })
    const { error, data } = result

    if (!error) {
      dispatch({
        type: SET_CAMPAIGN_ROOMS,
        data: {
          results: campaign.getRooms().map((rm, i) => (i === index ? data[0] : rm))
        }
      })
    }

    if (cb) cb(result)
  }

export const deleteCampaignRooms =
  ({ roomsToDelete, rooms, cb }) =>
  async (dispatch, getState) => {
    const organization = getOrganizationId(getState())
    const roomIds = roomsToDelete.map((room) => room.id)
    const ids = '["'.concat(roomIds.join('", "'), '"]')
    const { data, error } = await api.deleteCampaignRooms({
      orgId: organization,
      filter: `filter={"id__in":${ids}}`
    })

    if (!error) {
      dispatch({
        type: SET_CAMPAIGN_ROOMS,
        data: {
          results: rooms.filter((room) => roomIds.indexOf(room.id) < 0)
        }
      })
    }

    if (cb) cb(error, data || [])
  }

export const getCampaignSessions =
  ({ cb }) =>
  async (dispatch, getState) => {
    const campaign = getCampaignInstance(getState())
    const { data, error } = await api.getCampaignSessions({ campaignId: campaign.id() })

    if (!error) {
      dispatch({
        type: SET_CAMPAIGN_SESSIONS,
        data
      })
    }

    if (cb) cb(error, data || [])
  }

export const createCampaignSession =
  ({ leader, cb }) =>
  async (dispatch, getState) => {
    const organization = getOrganizationId(getState())
    const campaign = getCampaignInstance(getState())

    const { data, error } = await api.createCampaignSession({
      campaign,
      leader,
      organization
    })

    if (!error) {
      dispatch({
        type: SET_CAMPAIGN_SESSIONS,
        data: {
          results: [data]
        }
      })
    }

    if (cb) cb(error, data || [])
  }

export const searchEmployees =
  ({ text, cb }) =>
  async () => {
    const {
      data: { results },
      error
    } = await api.fetchEmployees({ filter: `search=${text}&is_active=True` })

    if (!error) cb(results)
  }

/**
 * Submits the current wizard form and navigates to the next step.
 *
 * If a step has no FormManager under the `wizardForm` store property, than it will simply navigate to the next step
 *
 * If there is a form:
 * - Validates the form and dispatches a invalid form action in case it fails
 * - If form is valid, dispatch
 *
 * @param history The history object from the router, needed so we can navigate forward.
 * @param mode Mode tells what to do after a successful submission, it could be 'previous',
 * 'next', 'final' which terminates the modal and 'exact' which implies you will provide
 * the next step.
 * @param {string|Null} [nextStep] An optional step, must be used with mode='exact'
 * @returns {function(...[*]=)}
 */
export function submit(history, mode = null, nextStep = null) {
  return async (dispatch, getState) => {
    const campaign = getCampaignInstance(getState())
    const campaignId = campaign.id()
    const form = getWizardForm(getState())
    const wizardStep = getWizardCurrentStep(getState())
    const wizardStepIndex = getStepIndex(wizardStep)

    /**
     * If there is no form, means this step does not need to be posted or saved
     * so we just move forward.
     */
    if (canSkipWizardStep(getState(), mode)) {
      if (mode !== 'final') {
        const url = mode === 'exact' ? nextStep : getNextStep(getState(), mode)
        return Promise.resolve().then(() => history.push(getUrl(url, { id: campaignId })))
      }

      /**
       * Terminate will redirect the user back to the campaigns list
       * closing the wizard.
       */
      history.push(HM_CAMPAIGNS_BASE_PATH)

      dispatch({
        type: UNLOAD_WIZARD
      })

      return true
    }

    /**
     * Validate the form and report back if the form is not
     * yet valid.
     */
    const validatedForm = form.validate()
    if (!validatedForm.valid()) {
      dispatch({
        type: WIZARD_FORM_INVALID,
        data: validatedForm
      })
      return null
    }

    const CACHE_KEY = 'hm-form-submission'
    validatedForm.cache(CACHE_KEY)

    /**
     * Before Hook
     * This hook can be used to update or change values before
     * the form is submitted to the server.
     */
    const updatedForm = validatedForm.runCallbacks('before', {
      id: campaignId,
      mode,
      wizardStep,
      dispatch
    })

    if (!(updatedForm instanceof FormManager)) {
      throw new Error(
        'Form before and after submit callbacks must return an instance of FormManager.'
      )
    }

    if (!updatedForm.valid()) {
      /**
       * We return the validated form here because form may have
       * changed during the before hook and the last untouched
       * state is the validated state.
       */
      dispatch({
        type: WIZARD_FORM_INVALID,
        data: validatedForm
      })
      return null
    }

    /**
     * Dispatch a help event here to allow interactions as the wizard
     * change steps.
     */
    dispatch({
      type: BEFORE_SUBMIT_WIZARD,
      data: updatedForm
    })

    const { id, ...values } = updatedForm.value()

    if (id !== campaignId) {
      throw new Error("Something wen't wrong, the campaign id and the form id differ.")
    }

    try {
      /**
       * We only update the step index IF the step index is higher
       * than the step the user is currently on the wizard, this way
       * we can track up to where the user completed the wizard as well
       * as the step he is currently viewing in the wizard.
       *
       * @type {number}
       */

      const currentCampaignStep = campaign.getCurrentStep()
      const payload = {
        ...values,
        current_wizard_step:
          currentCampaignStep < wizardStepIndex ? wizardStepIndex : currentCampaignStep
      }

      /**
       * Save or update the form based on the presence
       * of the campaign id.
       */
      const { data, status, error } = campaignId
        ? await api.saveCampaign(campaignId, payload)
        : await api.createCampaign(payload)

      /**
       * 400 in most cases means something is wrong with validation
       * so instead of raising an error, we return the form with the
       * server errors as a invalid form error instead.
       */
      if (status === 400) {
        /**
         * Here we also use the validatedForm because of possible
         * mutations during the before hook
         */
        dispatch({
          type: WIZARD_FORM_INVALID,
          data: validatedForm.mapErrors(data)
        })

        return false
      }

      /**
       * If there is an error during the api call other than 400, the error
       * handler have already handled and dispatched a wizard error action,
       * so we only need to stop the submission here.
       */
      if (error) {
        return false
      }

      /**
       * Update the store and allow other components to react to the successful
       * wizard submission.
       * We also dispatch the validated form here because of possible mutations
       * during the before hook.
       */
      dispatch({
        type: AFTER_SUBMIT_WIZARD,
        data: {
          result: data,
          form: validatedForm
        }
      })

      /**
       * The default behavior takes the user to the next Wizard
       * step after successful submission.
       */
      if (mode !== 'final') {
        validatedForm.runCallbacks('after', { id: data.id, mode, wizardStep, dispatch })
        const url = mode === 'exact' ? nextStep : getNextStep(getState(), mode)
        history.push(getUrl(url, { id: data.id }))
      } else {
        /**
         * Terminate will redirect the user back to the campaigns list
         * closing the wizard.
         */
        history.push(HM_CAMPAIGNS_BASE_PATH)

        dispatch({
          type: UNLOAD_WIZARD
        })

        /**
         * Create the toast for the campaign being updated
         */
        const campaignMessage = getCampaignMessage(campaign.properties.name, 'updated')
        dispatch(createCampaignMessage(campaignMessage))
      }

      return true
    } catch (error) {
      dispatch({
        type: SUBMIT_WIZARD_ERROR,
        data: {
          error: {
            message: error.message,
            info: error
          },
          form: validatedForm
        }
      })
      return error
    }
  }
}

export const getEmailTemplates = () => async (dispatch, getState) => {
  const orgId = getOrganizationId(getState())
  const { data, error } = await api.fetchEmailTemplates({ orgId })

  if (!error) {
    dispatch({ type: SET_EMAIL_TEMPLATES, data })
  }
}

export const getStaticEmailTemplates = (templateName, campaign_id, cb) => async () => {
  const { data, error } = await api.fetchStaticEmailTemplates({ templateName, campaign_id })
  if (!error) {
    if (cb) {
      cb(data)
    }
  }
}

/**
 *
 * @param {id, name, subject, body, key } payload
 */
export const createEmailTemplate =
  ({ id, name, subject, body, key }) =>
  async (dispatch, getState) => {
    const state = getState()
    const orgId = getOrganizationId(state)
    const { shortname } = getCompany(state)
    const email = {
      id,
      name,
      subject,
      body,
      key: shortname.concat('_', key),
      organization: orgId
    }
    const { error: createError } = await api.createEmailTemplate({ orgId, email })

    if (!createError) {
      const { data, error: fetchError } = await api.fetchEmailTemplates({ orgId })

      if (!fetchError) {
        dispatch({ type: SET_EMAIL_TEMPLATES, data })
      }
    }
  }

/**
 *
 * @param {id, name, subject, content} payload
 */
export const editEmailTemplate = (payload, history) => async (dispatch, getState) => {
  const { id, name, subject, content } = payload
  const template = getEmailTemplate(id)(getState())
  const organization = getOrganizationId(getState())
  const campaignId = getCampaignId(getState())

  if (template) {
    const isDefault = template.tags.find((tag) => tag === 'default') !== undefined
    const noCustomizedLabel = isDefault && !name.includes('(customized)')
    const email = {
      ...template,
      name,
      subject,
      content,
      isDefault,
      body: content.replace(/(\r\n\t|\n|\r\t)/gm, ''),
      organization
    }

    if (noCustomizedLabel) {
      email.name = name.concat(' (customized)')
    }

    const save = async () => {
      if (isDefault) {
        return api.createEmailTemplate(email)
      }
      return api.editEmailTemplate(email)
    }

    const { error } = await save()

    if (!error) {
      const { data } = await api.fetchEmailTemplates({ orgId: organization })
      dispatch({ type: SET_EMAIL_TEMPLATES, data })

      history.push(getUrl(STEP.SCHEDULING_AND_EMAILS, { id: campaignId }))
    }
  }
}

export const sendPreviewEmail =
  ({ subject, body }) =>
  async (dispatch, getState) => {
    const state = getState()
    const orgId = getOrganizationId(state)
    const company = getCompany(state)
    const me = getUser(state)
    const email = body
      .replace('{{ session_url }}', "<a href='/hackersmind/'>Hacker's Mind session</a>")
      .replace(
        '{{ scheduled_session_url }}',
        "<a href='/hackersmind/'>prescheduled sessions signup</a>"
      )

    return api.sendPreviewEmail({
      subject,
      body: email,
      organization: orgId,
      campaign: null,
      consumable: null,
      template: null,
      context: {
        ...me,
        ...company
      }
    })
  }

export const getCampaignTargets =
  ({ campaignId }) =>
  async (dispatch, getState) => {
    const state = getState()
    const orgId = getOrganizationId(state)
    const { data, error } = await api.fetchCampaignTargets({ campaignId, orgId })
    if (!error) dispatch({ type: SET_CAMPAIGN_TARGETS, data })
    return null
  }

export const setDynamicallyTargets =
  ({ campaignId, update_targets }) =>
  async (dispatch) => {
    const { error } = await api.fetchCampaignToDynamicallyTargets({
      campaignId,
      update_targets
    })
    if (!error) dispatch({ type: SET_DYNAMICALLY_TARGETS, data: update_targets })
    return null
  }

export const updateRolloutType =
  ({ campaignId, rollout_type }) =>
  async (dispatch) => {
    const { error } = await api.updateRolloutType({
      campaignId,
      rollout_type
    })
    if (!error) dispatch({ type: SET_ROLLOUT_TYPE, data: rollout_type })
    return null
  }
