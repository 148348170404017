import React from 'react'
import invert from 'lodash/invert'
import { TIME_ZONES } from '@elevate_security/elevate-component-library'
import { EmployeesMenu } from '../../../../../../components'
import { LANGUAGES } from '../../../../../../constants'
import { parseDateForPicker } from '../../../../../../services/utils'

export const invertedTimezones = invert(TIME_ZONES)

export const getFormattedRoomsForTable = (rooms) => {
  return rooms.map((room) => {
    const { start_time, end_time, timezone, language, leader, ...others } = {
      ...room
    }

    return {
      ...others,
      timezone: invertedTimezones[timezone],
      start_time: parseDateForPicker({ date: start_time, timezone }),
      end_time: parseDateForPicker({ date: end_time, timezone }),
      language: LANGUAGES[language],
      leader: leader ? [leader] : []
    }
  })
}

export const getFormattedRoomForForm = (room) => {
  return {
    ...room,
    timezone: TIME_ZONES[room.timezone]
  }
}

export const columnDefs = [
  {
    Header: 'Location',
    accessor: 'location',
    fieldProps: {
      type: 'text',
      cellWidth: 130
    }
  },
  {
    Header: 'Room',
    accessor: 'room',
    fieldProps: {
      type: 'text',
      cellWidth: 130
    }
  },
  {
    Header: 'Size',
    accessor: 'room_size',
    fieldProps: {
      type: 'text',
      cellWidth: 70
    }
  },
  {
    Header: 'Session Start',
    accessor: 'start_time',
    fieldProps: {
      type: 'datetime',
      cellWidth: 145
    }
  },
  {
    Header: 'Session End',
    accessor: 'end_time',
    fieldProps: {
      type: 'datetime',
      cellWidth: 145
    }
  },
  {
    Header: 'Time Zone',
    accessor: 'timezone',
    fieldProps: {
      type: 'timezone'
    }
  },
  {
    Header: 'Session Leader',
    accessor: 'leader',
    fieldProps: {
      type: 'async-typeahead',
      options: [],
      clearButton: true,
      placeholder: 'Search...',
      maxResults: 5,
      selectHintOnEnter: true,
      labelKey: 'email',
      renderMenu: (results, menuProps) => (
        <EmployeesMenu
          results={results}
          menuProps={menuProps}
          width="400px"
          labels={['first_name', 'last_name']}
        />
      ),
      filterBy: () => true /* Let backend filter options */
    }
  },
  {
    Header: 'Dial-in',
    accessor: 'dial_in',
    fieldProps: {
      type: 'text',
      cellWidth: 130
    }
  },
  {
    Header: 'Language',
    accessor: 'language',
    fieldProps: {
      type: 'dropdown',
      options: Object.values(LANGUAGES).sort(),
      cellWidth: 100
    }
  }
]
