import { API_ERROR_MESSAGE, createRequest, METHOD } from '../../api'
import {
  parseCampaignRoomPayload,
  parseCampaignRoomsResponse,
  parseCampaignPayload
} from '../../utils'
import axios from '../axios-config'

/**
 * @type {function(...[*]=)}
 */
export const fetchCampaign = createRequest(
  ({ id }) =>
    axios({
      method: METHOD.GET,
      url: `hackersmindcampaigns/${id}/`
    }),
  {
    400: `<p>Error while fetching a campaign information.<p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
    404: `<p>Sorry, the requested campaign was not found in our database.</p>
          <p>Please verify that the campaign data is correct and try again, if the problem persists contact our support team.</p>`
  }
)

/**
 * @type {function(...[*]=)}
 */
export const fetchTags = createRequest(
  () =>
    axios({
      method: METHOD.GET,
      url: '/hackersmindcampaigns/behaviors/'
    }),
  {
    400: `<p>Sorry, there was an error loading tags while starting the wizard.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
    404: `<p>Could not find tags in our database.</p>
         ${API_ERROR_MESSAGE.CONTACT_SUPPORT}`
  }
)

/**
 * @type {function(...[*]=)}
 */
export const fetchCampaignRooms = createRequest(
  ({ campaignId, filter }) =>
    axios({
      method: METHOD.GET,
      url: `/hackersmindscheduledsessions/?campaign=${campaignId}&limit=500&${filter || ''}`
    }),
  {
    400: `<p>Sorry, there was an error loading rooms while starting the wizard.</p>
         ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
    404: '<p>There are no rooms for this campaign yet.</p>'
  },
  {
    onFetch: ({ results }, args) => {
      if (args[0].formatted) {
        return {
          results: parseCampaignRoomsResponse(results)
        }
      }
      return { results }
    }
  }
)

/**
 * @type {function(...[*]=)}
 */
export const fetchGroups = createRequest(
  () =>
    axios({
      method: METHOD.GET,
      url: '/groups/'
    }),
  {
    400: `<p>Sorry, there was an error loading employee groups while starting the wizard.</p>
         ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
    404: '<p>Could not find employee groups in our database.</p>'
  }
)

/**
 * @type {function(...[*]=)}
 */
export const fetchEmployees = createRequest(
  ({ filter } = {}) =>
    axios({
      method: METHOD.GET,
      url: `/employees/?${filter || ''}`
    }),
  {
    400: `<p>Sorry, there was an error loading employee count while starting the wizard.</p>
         ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
    404: '<p>No employee count found for this organization.</p>'
  }
)

/**
 * @type {function(...[*]=)}
 */
export const saveCampaign = createRequest(
  (campaignId, payload) =>
    axios({
      method: METHOD.PATCH,
      url: `/hackersmindcampaigns/${campaignId}/`,
      data: payload
    }),
  {
    400: `<p>Sorry, we found errors while validating your submission.</p>
          <p>Please verify the submitted information and try again.</p>`,
    500: `<p>Sorry, there was an unexpected error while saving your campaign.</p>
         ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  },
  {
    beforeDispatch: (campaignId, payload) => [campaignId, parseCampaignPayload(payload)]
  }
)

/**
 * @type {function(...[*]=)}
 */
export const createCampaign = createRequest(
  (campaign) =>
    axios({
      method: METHOD.POST,
      url: '/hackersmindcampaigns/',
      data: campaign
    }),
  null,
  {
    beforeDispatch: (campaign) => [parseCampaignPayload(campaign)]
  }
)

/**
 * @type {function(...[*]=)}
 */
export const updateCampaignRooms = createRequest(
  ({ rooms }) =>
    axios({
      method: METHOD.PATCH,
      url: '/hackersmindscheduledsessions/',
      data: rooms
    }),
  {
    400: `<p>Sorry, there was an unexpected error while updating your campaign rooms.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  },
  {
    beforeDispatch: ({ rooms }) => [{ rooms: parseCampaignRoomPayload(rooms) }]
  }
)

/**
 * @type {function(...[*]=)}
 */
export const addCampaignRooms = createRequest(
  ({ rooms }) =>
    axios({
      method: METHOD.POST,
      url: '/hackersmindscheduledsessions/',
      data: rooms
    }),
  {
    400: `<p>Sorry, there was an unexpected error while adding rooms to your campaign.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  },
  {
    beforeDispatch: ({ rooms }) => [{ rooms: parseCampaignRoomPayload(rooms) }]
  }
)

export const createCampaignSession = createRequest(({ campaign, leader, organization }) =>
  axios({
    method: 'post',
    url: '/hackersmindsessions/',
    data: {
      campaign: campaign.id(),
      organization,
      tags: campaign.getTags(),
      standalone: true,
      leader: leader || null
    }
  })
)

export const getCampaignSessions = createRequest(({ campaignId }) =>
  axios({
    method: 'get',
    url: `/hackersmindsessions/?campaign=${campaignId}`
  })
)

/**
 * @type {function(...[*]=)}
 */
export const deleteCampaignRooms = createRequest(
  ({ orgId, filter }) =>
    axios({
      method: METHOD.DELETE,
      url: `/hackersmindscheduledsessions/?organization=${orgId}&${filter || ''}`
    }),
  {
    400: `<p>Sorry, there was an unexpected error while deleting campaign rooms.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  }
)

/**
 * @type {function(...[*]=)}
 */
export const uploadRooms = createRequest((file, campaignId) => {
  const formData = new FormData()
  formData.append('file', file)

  return axios({
    method: METHOD.POST,
    url: `/hackersmindcampaigns/${campaignId}/upload_scheduled_sessions/`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
})

/**
 * @type {function(...[*]=)}
 */
export const fetchEmailTemplates = createRequest(({ orgId }) =>
  axios({
    method: METHOD.GET,
    url: `/templates/?organization=${orgId}`
  })
)

/**
 * @type {function(...[*]=)}
 */
export const fetchStaticEmailTemplates = createRequest(({ templateName, campaign_id }) =>
  axios({
    method: METHOD.GET,
    url: `/hackersmindcampaigns/templates/?template=${templateName}&campaign_id=${campaign_id}`
  })
)

/**
 * @type {function(...[*]=)}
 */
export const editEmailTemplate = createRequest(({ id, name, subject, body }) =>
  axios({
    method: METHOD.PATCH,
    url: `/templates/${id}/`,
    data: { name, subject, body }
  })
)

/**
 * @type {function(...[*]=)}
 */
export const sendPreviewEmail = createRequest((email) =>
  axios({
    method: METHOD.POST,
    url: '/mails/',
    data: email
  })
)

/**
 * @type {function(...[*]=)}
 */
export const createEmailTemplate = createRequest((email) =>
  axios({
    method: METHOD.POST,
    url: '/templates/',
    data: email
  })
)

/**
 * @type {function(...[*]=)}
 */
export const fetchCampaignTargets = ({ campaignId, orgId }) =>
  axios({
    method: METHOD.GET,
    url: `/hackersmindcampaigns/${campaignId}/targets/?organization=${orgId}`
  })

/**
 * @type {function(...[*]=)}
 */
export const fetchCampaignToDynamicallyTargets = createRequest(
  ({ campaignId, update_targets }) =>
    axios({
      method: METHOD.PATCH,
      url: `/hackersmindcampaigns/${campaignId}/`,
      data: { update_targets }
    }),
  {
    400: `<p>Sorry, there was an unexpected error while updating your campaign rooms.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  }
)
/**
 * @type {function(...[*]=)}
 */
export const updateRolloutType = createRequest(
  ({ campaignId, rollout_type }) =>
    axios({
      method: METHOD.PATCH,
      url: `/hackersmindcampaigns/${campaignId}/`,
      data: { rollout_type }
    }),
  {
    400: `<p>Sorry, there was an unexpected error while updating your campaign rooms.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  }
)
