// libraries
import React, { useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'

function GaugeGraph(props) {
  const { value, text, bgColor, color, width, height } = props
  const canvas = useRef(null)
  let degrees = 0

  let ctx = null
  let W = 0
  let H = 0
  let animation_loop

  useEffect(() => {
    ctx = canvas.current ? canvas.current.getContext('2d') : null
    if (!ctx) return
    degrees = 0

    W = canvas.width
    H = canvas.height
    if (ctx) draw()
  }, [canvas])

  function init() {
    ctx.clearRect(0, 0, W, H)
    ctx.beginPath()
    ctx.strokeStyle = bgColor
    ctx.lineWidth = 30
    ctx.arc(W / 2, H / 2, 100, 0, Math.PI * 2, false)
    ctx.stroke()

    const radians = (degrees * Math.PI) / 180
    ctx.beginPath()
    ctx.strokeStyle = color
    ctx.lineWidth = 30
    ctx.arc(W / 2, H / 2, 100, 0 - (90 * Math.PI) / 180, radians - (90 * Math.PI) / 180, false)
    ctx.stroke()

    ctx.fillStyle = color
    if (text) {
      ctx.font = '50px helvetica'
      const display_text = `${Math.floor((degrees / 360) * 100)} %`
      const text_width = ctx.measureText(display_text).width
      ctx.fillText(display_text, W / 2 - text_width / 2, H / 2 + 15)
    }
  }

  function draw() {
    if (typeof animation_loop !== 'undefined') clearInterval(animation_loop)
    animation_loop = setInterval(animateTo, 1000 / (value - degrees))
  }

  function animateTo() {
    if (degrees === value) clearInterval(animation_loop)

    if (degrees < value) degrees += 1
    else if (degrees > value) degrees -= 1
    init()
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <canvas id="Canvas" ref={canvas} width={width} height={height} />
    </div>
  )
}

GaugeGraph.propTypes = {
  text: PropTypes.bool,
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

GaugeGraph.defaultProps = {
  text: false,
  color: 'lightgreen',
  bgColor: 'white',
  width: '100%',
  height: 'auto'
}

export default memo(GaugeGraph)
