import PropTypes from 'prop-types'
import React, { useEffect, Fragment, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import {
  CustomSelect,
  Layout,
  Typography,
  Toggle,
  ButtonIcon,
  TimezonePicker
} from '@elevate_security/elevate-component-library'
import { useDispatch, useSelector } from 'react-redux'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { STEP, WIZARD_MODAL_SIZE, ROLLOUT_TYPE } from '../../../../constants'
import { wizardSelector } from '../../../../services/redux/selectors'
import { ActivityIndicator, DatePicker, InputErrors } from '../../../../components'
import TimelineSelectEmailTemplate from './components/TimelineSelectEmailTemplate'
import DateTooltip from './components/DateTooltip'
import { ButtonRow } from './components/TimelineSelectEmailTemplate/styles'
import {
  HM_SCHEDULING_AND_EMAILS_FORM_NAME,
  getSchedulingAndEmailsForm,
  getDatesValidations
} from './form'
import { withForm } from '../../with-form'
import { withStepManager } from '../../with-step'
import { RouteContainer, Label, FieldSet, Form } from '../../styles'
import { useActions, formOutOfSync, getUrl } from '../../utils'
import { FlexRow } from '../../../../common.styles'
import { getMinMaxSessionDates, getDatesRange, getFormattedData } from './utils'

const { H2, Text } = Typography

function SchedulingStep(props) {
  const { entering, ready } = props
  const history = useHistory()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { setWizardForm, getEmailTemplates, setWizardModalProps, getCampaignRooms } =
    useActions(wizardActions)
  const { campaign, wizardForm, emailTemplates, wizardProps } = useSelector(wizardSelector)
  const [sessionMinDate, setSessionMinDate] = useState(null)
  const [sessionMaxDate, setSessionMaxDate] = useState(null)
  const data = campaign && campaign.data() && getFormattedData(campaign.data())
  const rooms = campaign.getRooms()
  const isPredefinedLeads = data && data.rollout_type === ROLLOUT_TYPE.PREDEFINED_LEADS
  const isPeerScheduling = data && data.rollout_type === ROLLOUT_TYPE.PEER_SCHEDULING
  const emailTemplatesLoaded = emailTemplates && emailTemplates.results.length

  useEffect(() => {
    if (wizardProps.size !== WIZARD_MODAL_SIZE.MEDIUM) {
      dispatch(setWizardModalProps({ size: WIZARD_MODAL_SIZE.MEDIUM }))
    }
  }, [wizardProps])

  /**
   * Keep the form in sync by checking if the current form name and id
   * matches this step and the campaign.
   */
  useEffect(() => {
    if (
      formOutOfSync(
        id,
        HM_SCHEDULING_AND_EMAILS_FORM_NAME,
        campaign && campaign.id(),
        wizardForm
      ) &&
      emailTemplates
    ) {
      setWizardForm(getSchedulingAndEmailsForm({ data, emailTemplates }))
    }
  })

  /**
   * Load available templates if they have not yet been loaded
   */
  useEffect(() => {
    if (!emailTemplatesLoaded) {
      getEmailTemplates()
    }
  }, [])

  /**
   * Load campaign rooms if they have not yet been loaded and campaign type is peer scheduling
   */
  useEffect(() => {
    if (!rooms || !rooms.length) {
      getCampaignRooms({ campaignId: id })
    }
  }, [])

  /**
   * Update the form whenever the email templates change so the form
   * reflects the latest state.
   */
  useEffect(() => {
    if (wizardForm) {
      const pTemplate = wizardForm.get('participant_template')

      if (pTemplate && pTemplate.options.length) {
        setWizardForm(getSchedulingAndEmailsForm({ data, emailTemplates }))
      }
    }
  }, [emailTemplates])

  /**
   * Set earliest start and latest end dates for sessions in case of Peer Scheduling
   */
  useEffect(() => {
    if (isPeerScheduling && rooms && rooms.length) {
      const [min, max] = getMinMaxSessionDates(rooms)
      setSessionMinDate(min)
      setSessionMaxDate(max)
    }
  }, [rooms])

  /**
   * Update validations if validation is not added for sessions start and end date
   * in case of Peer Scheduling
   */
  useEffect(() => {
    if (isPeerScheduling && wizardForm && sessionMinDate && sessionMaxDate) {
      const { start_date } = wizardForm.get()
      if (start_date && start_date.validations.length === 2)
        setWizardForm(updateValidations(wizardForm, true))
    }
  }, [sessionMinDate, sessionMaxDate, wizardForm])

  /**
   * Update the validations for start_date and end_date and returns the updated form
   */
  const updateValidations = (form, skipValidation = false) => {
    const { start_date, end_date, timezone } = form.get()

    const validations = getDatesValidations(
      {
        start_date: start_date.value,
        timezone: timezone.value,
        end_date: end_date.value
      },
      sessionMinDate,
      sessionMaxDate
    )
    let updatedForm = form
    if (start_date.value && start_date.value.length) {
      updatedForm = updatedForm.update(
        'start_date',
        {
          ...start_date,
          validations: validations.start_date
        },
        skipValidation
      )
    }
    if (end_date.value && end_date.value.length) {
      updatedForm = updatedForm.update(
        'end_date',
        {
          ...end_date,
          validations: validations.end_date
        },
        skipValidation
      )
    }
    return updatedForm
  }

  const onChange = (name, value) => {
    const cacheKey = `${HM_SCHEDULING_AND_EMAILS_FORM_NAME}-${data.id}`
    const updatedForm = wizardForm.updateValue(name, value).cache(cacheKey, name)
    if (name === 'start_date' || name === 'timezone' || name === 'end_date') {
      const updatedValidations = updateValidations(updatedForm)
      setWizardForm(updatedValidations)
    } else {
      setWizardForm(updatedForm)
    }
  }

  /**
   * Deffer if not ready
   */
  if (!ready || !wizardForm || !emailTemplatesLoaded) {
    return <ActivityIndicator active name="emails not ready" />
  }

  const { start_date, end_date, timezone, do_not_send_emails, participant_template } =
    wizardForm.get()

  const { value: val, defaultValue } = participant_template
  const templateId = val || (defaultValue && defaultValue.value)

  if (!templateId) {
    return <ActivityIndicator active name="emails not ready" />
  }

  /**
   * Get formatted date ranges for disabing date/time in react-datepicker
   */
  const [startAfter, startBefore, endAfter] = getDatesRange({
    startDate: start_date.value,
    endDate: end_date.value,
    timezone: timezone.value,
    sessionMinDate,
    sessionMaxDate
  })

  return (
    <CSSTransition in={entering} classNames="scale" timeout={500}>
      <RouteContainer>
        <Form data-id="hm-schedule-form" onSubmit={() => false}>
          <Layout
            container
            style={{ borderBottom: '1px solid #EEF0F3', paddingBottom: '24px', margin: '0 -12px' }}
          >
            <FieldSet
              data-id="hm-schedule-form-start"
              width="calc((100% / 3) - 24px)"
              style={{ margin: '0 12px' }}
            >
              <Label>
                Campaign Start
                <DateTooltip text="This is the date on which we will begin sending out emails related to the campaign. It’s also the beginning date for campaign reports." />
              </Label>
              <DatePicker
                value={start_date.value}
                onChange={({ value }) => onChange('start_date', { value })}
                invalid={!start_date.valid}
                minDate={startAfter}
                minTime={startAfter}
                maxDate={startBefore}
                maxTime={startBefore}
              />
              {!start_date.valid && <InputErrors errors={start_date.errors} />}
            </FieldSet>
            <FieldSet
              data-id="hm-schedule-form-end"
              width="calc((100% / 3) - 24px)"
              style={{ margin: '0 12px' }}
            >
              <Label>
                Campaign End{' '}
                <DateTooltip text="This is the date by which you’d like your participants to complete a session in this campaign." />
              </Label>
              <DatePicker
                value={end_date.value}
                onChange={({ value }) => onChange('end_date', { value })}
                invalid={!end_date.valid}
                minDate={endAfter}
                minTime={endAfter}
              />
              {!end_date.valid && <InputErrors errors={end_date.errors} />}
            </FieldSet>
            <FieldSet
              data-id="hm-schedule-form-timezone"
              width="calc((100% / 3) - 24px)"
              style={{ margin: '0 12px' }}
            >
              <Label>Time Zone</Label>
              <TimezonePicker
                placeholder="Select a timezone"
                defaultValue={timezone.value || timezone.defaultValue}
                onChange={(value) => onChange('timezone', { value })}
                dropdownWidth="auto"
                invalid={!timezone.valid}
              />
              {!timezone.valid && <InputErrors errors={timezone.errors} />}
            </FieldSet>
          </Layout>

          <Layout style={{ paddingTop: '24px' }}>
            <H2 fontWeight="bold" fontSize="xlg" color="900">
              Campaign Emails
            </H2>
            {isPredefinedLeads && (
              <>
                <Text>Sent to predefined leads immediately upon rollout</Text>
                <br />
                <br />
              </>
            )}
            {isPredefinedLeads && (
              <FlexRow data-id="hm-schedule-form-predefined-leaders-templates" alignItems="center">
                <CustomSelect
                  placeholder="Select a template"
                  style={{ width: 'calc(100% - 70px)' }}
                  defaultValue={templateId}
                  options={participant_template.options}
                  onChange={({ value }) => onChange('participant_template', { value })}
                />
                <ButtonRow>
                  <ButtonIcon
                    theme="ghost"
                    icon="Pencil"
                    fill="#959DA8"
                    className="edit-action"
                    onClick={() =>
                      history.push(getUrl(STEP.EMAIL_TEMPLATE_EDITOR, { id, templateId }))
                    }
                  />
                  <ButtonIcon
                    theme="ghost"
                    icon="Eye"
                    fill="#959DA8"
                    className="view-action"
                    onClick={() =>
                      history.push(
                        getUrl(STEP.EMAIL_TEMPLATE_PREVIEW, {
                          id,
                          templateId
                        })
                      )
                    }
                  />
                </ButtonRow>
              </FlexRow>
            )}
            {!isPredefinedLeads && (
              <>
                <FieldSet data-id="hm-schedule-form-no-emails" style={{ marginLeft: '5px' }}>
                  <Toggle
                    label="Do not send emails associated with this campaign"
                    onChange={({ checked }) => onChange('do_not_send_emails', { value: checked })}
                    checked={do_not_send_emails.value}
                  />
                </FieldSet>
                <FieldSet>
                  {!do_not_send_emails.value && (
                    <TimelineSelectEmailTemplate
                      campaignId={id}
                      wizardForm={wizardForm}
                      onChange={({ name, value }) => onChange(name, { value })}
                    />
                  )}
                </FieldSet>
              </>
            )}
          </Layout>
        </Form>
      </RouteContainer>
    </CSSTransition>
  )
}

SchedulingStep.propTypes = {
  ready: PropTypes.bool.isRequired,
  entering: PropTypes.bool.isRequired
}

export default withStepManager(
  withForm(SchedulingStep),
  STEP.SCHEDULING_AND_EMAILS,
  HM_SCHEDULING_AND_EMAILS_FORM_NAME
)
