import {
  BEFORE_GET_CAMPAIGNS_LIST,
  SET_CAMPAIGNS_LIST,
  SET_CURRENT_TAB,
  SET_CAMPAIGN_STATUSES,
  CREATE_CAMPAIGN_MESSAGE,
  CLOSE_CAMPAIGN_MESSAGE,
  COMPLETE_YEARS
} from './types'

export const INITIAL_STATE = {
  campaignList: null,
  campaignListFiltered: null,
  currentTab: '',
  campaignStatuses: null,
  campaignMessages: [],
  completionYears: []
}

export default function campaignsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BEFORE_GET_CAMPAIGNS_LIST:
      return {
        ...state
      }
    case SET_CAMPAIGNS_LIST:
      return {
        ...state,
        campaignList: action.data ? action.data.results : [],
        campaignListFiltered: action.key
          ? {
              ...state.campaignListFiltered,
              [action.key]: action.data.results
            }
          : {}
      }
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.data
      }
    case SET_CAMPAIGN_STATUSES:
      return { ...state, campaignStatuses: action.data }
    case CREATE_CAMPAIGN_MESSAGE:
      return { ...state, campaignMessages: state.campaignMessages.concat(action.message) }
    case CLOSE_CAMPAIGN_MESSAGE:
      return {
        ...state,
        campaignMessages: state.campaignMessages.filter(
          (message) => message.id !== action.messageId
        )
      }
    case COMPLETE_YEARS:
      return { ...state, completionYears: action.data }
    default:
      return state
  }
}
