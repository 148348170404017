import styled from 'styled-components'

export const TabsContainer = styled.div`
  [class^='Tabsstyles__TabsHeaderContainer'] {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #dde1e7;
    a[role='tab'] {
      opacity: ${(props) => (props.active ? 0.6 : 1)};
    }
    a[role='tab'][aria-selected='true'] {
      color: #0c7d84;
      font-weight: bold;
      font-size: 15px;
      line-height: 32px;
      opacity: 1;
    }
  }
`
