import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonLink } from '@elevate_security/elevate-component-library'
import { FlexColumn, FlexItem, FlexRow } from '../../../../../common.styles'
import {
  ErrorMessage,
  ErrorRow,
  RowErrors,
  RowErrorsContainer,
  RowHeaders,
  RowIndex
} from './styles'

function UploadErrors({ message, errors }) {
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const { uploadErrors, validationErrors } = {
    uploadErrors: [],
    validationErrors: [],
    ...(errors || {})
  }

  const handleShowInfo = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowMoreInfo(!showMoreInfo)
  }

  return (
    <FlexColumn width="100%">
      <ErrorMessage>
        <p>{message}</p>
      </ErrorMessage>
      {showMoreInfo && (
        <FlexColumn width="100%">
          {uploadErrors.length > 0 && (
            <FlexColumn width="100%">
              <ul>
                {uploadErrors.map((error) => (
                  <li>{error}</li>
                ))}
              </ul>
              {validationErrors.length > 0 && <br />}
            </FlexColumn>
          )}
          {validationErrors.length > 0 && (
            <RowErrorsContainer width="100%" gap="12px">
              <RowErrors>
                <RowHeaders grow={1} stretch gap="12px">
                  <RowIndex width="50px">
                    <strong>Line</strong>
                  </RowIndex>
                  <FlexItem>
                    <strong>Errors</strong>
                  </FlexItem>
                </RowHeaders>
                {validationErrors.map(({ row, error }) => (
                  <ErrorRow grow={1} stretch gap="12px">
                    <RowIndex width="50px">{row}</RowIndex>
                    <FlexItem>
                      {error.length > 1 ? (
                        <ul>
                          {error.map((e) => (
                            <li>{e}</li>
                          ))}
                        </ul>
                      ) : (
                        error[0]
                      )}
                    </FlexItem>
                  </ErrorRow>
                ))}
              </RowErrors>
            </RowErrorsContainer>
          )}
        </FlexColumn>
      )}
      <FlexRow style={{ marginTop: showMoreInfo ? '12px' : 0 }}>
        <ButtonLink to="/#" onClick={handleShowInfo} theme="ghost" color="error">
          {showMoreInfo ? 'Less info' : 'More info'}
        </ButtonLink>
      </FlexRow>
    </FlexColumn>
  )
}

UploadErrors.propTypes = {
  message: PropTypes.string,
  errors: PropTypes.shape({
    uploadErrors: PropTypes.arrayOf(PropTypes.string),
    validationErrors: PropTypes.arrayOf(
      PropTypes.shape({
        row: PropTypes.number,
        error: PropTypes.arrayOf(PropTypes.string)
      })
    )
  })
}

UploadErrors.defaultProps = {
  message: null,
  errors: {
    uploadErrors: [],
    validationErrors: []
  }
}

export default UploadErrors
