import styled from 'styled-components'

const Fill = styled.div`
  background-color: #60c5b7;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
`
const Progress = styled.div`
  position: relative;
  height: 5px;
  width: 100%;
  border-radius: 50px;
  border-radius: 8px;
  background-color: #eef0f3;
`

const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: stretch;
`

export { Fill, Progress, CenterContent }
