import styled from 'styled-components'

const opacityTime = 500
const transformTime = 500

export const InputErrorsContainer = styled.div`
  position: ${(props) => (props.relative ? 'relative' : 'absolute')};
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity ${opacityTime}, bottom ${transformTime};

  &.error-enter {
    opacity: 0;
    bottom: 0;
  }
  &.error-enter-active {
    opacity: 1;
    bottom: -18px;
  }
  &.error-enter-done {
    opacity: 1;
    bottom: -18px;
  }
  /* &.error-exit {
    opacity: 1;
    bottom: -18px;
  }
  &.error-exit-active {
    opacity: 0;
    bottom: 0;
  } */
`

export const FieldSetError = styled.div`
  width: 100%;
  font-weight: 500;
  line-height: 1.33;
  color: rgb(199, 52, 52);
  font-size: 11px;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
`
