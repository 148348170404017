import styled from 'styled-components'
import { Layout, Link } from '@elevate_security/elevate-component-library'

export const Container = styled(Layout)`
  padding: 20px 0;
  display: flex;
`
export const Row = styled(Layout).attrs({ container: true })`
  padding: 0 8px;
  flex-wrap: ${(props) => (props.noWrap ? 'nowrap' : 'wrap')} !important;
`
export const Column = styled(Layout).attrs({ item: true })`
  min-width: 140px;
  padding: 8px 0;
`

export const ClipboardLink = styled(Link)`
  display: inline-block;
  vertical-align: top;
  animation: back ease 2.5s;
  &.copied {
    text-decoration: none;
    cursor: default;
    color: #444d58;
    animation: copied ease 2.5s;
  }
  @keyframes copied {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes back {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }
`

export default {
  ClipboardLink
}
