/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { useTable } from 'react-table'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import ActivityIndicator from '../../../../../../components/ActivityIndicator'
import SelectionManager from '../../../../../../services/selection-manager'
import { withSelectionManager } from '../../../../with-selection-manager'
import { ActionCell, CheckboxCell, ReadOnlyCell } from './Cell'
import {
  Wrapper,
  TableBody,
  TableContainer,
  TableData,
  RowContainer,
  TableRow,
  TableHeader,
  HeadTextWrapper,
  FixedRightSection
} from './styles'

const actionCellWidth = 100
const checkboxCellWidth = 40
const cellMarginX = 10
const minDefaultWidth = 100

function Table({
  columns,
  data,
  idKey: id_key,
  editable,
  bulkAction,
  actions,
  selected,
  loading,
  handleSelect,
  handleSelectAll,
  onEdit,
  onDelete,
  onCopy,
  onSelectedChanged
}) {
  const [defaultWidth, setDefaultWidth] = useState('')

  const setDefaultWidthForCols = () => {
    let customWidth = 0
    const customColsCount = columns.reduce((count, col) => {
      const { cellWidth } = col.fieldProps || {}
      if (cellWidth) {
        customWidth += cellWidth
        customWidth += cellMarginX
        return count + 1
      }
      return count
    }, 0)

    if (bulkAction) {
      customWidth += checkboxCellWidth
      customWidth += cellMarginX
    }

    if (editable) {
      customWidth += actionCellWidth
      customWidth += cellMarginX
    }

    setDefaultWidth(
      `calc(((100% - ${customWidth}px) / ${columns.length - customColsCount}) - ${cellMarginX}px)`
    )
  }

  const handleCallback = (callback, payload) =>
    callback && typeof callback === 'function' && callback(payload) !== false

  useEffect(() => {
    setDefaultWidthForCols()
  }, [columns])

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    onSelectedChanged(selected.get().map((id) => data.find((row) => row[id_key] === id)))
  }, [selected])

  const getRowId = (row) => {
    return row.original[id_key]
  }

  const handleEditRow = (row) => handleCallback(onEdit, row)

  const handleDelete = (row) => handleCallback(onDelete, row)

  const handleDeleteRow = (row) => handleDelete([row])

  const handleCopyRow = (row) => handleCallback(onCopy, row)

  const handleBulkAction = (action) => {
    if (action === 'Remove') {
      handleDelete(selected.get().map((row) => row.original))
    }
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: cloneDeep(data),
    defaultColumn: {
      Cell: ({ cell }) => {
        return <ReadOnlyCell cell={cell} />
      }
    }
  })

  return (
    <Wrapper data-id="hm-sessions-table">
      <TableContainer {...getTableProps()}>
        <TableHeader>
          <RowContainer>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {bulkAction && (
                  <TableData
                    role="columnheader"
                    defaultWidth={`${checkboxCellWidth}px`}
                    className="checkbox-cell"
                  >
                    <CheckboxCell
                      isHeaderCell
                      checked={selected.count() > 0}
                      indeterminate={data.length !== selected.count()}
                      onChange={(value) =>
                        handleSelectAll(
                          rows.map((row) => [getRowId(row), row]),
                          value
                        )
                      }
                      onAction={(action) => handleBulkAction(action)}
                    />
                  </TableData>
                )}
                {headerGroup.headers.map((column) => {
                  const { cellWidth } = column.fieldProps || {}
                  return (
                    <TableData
                      {...column.getHeaderProps()}
                      defaultWidth={cellWidth ? `${cellWidth}px` : defaultWidth}
                      minWidth={`${cellWidth || minDefaultWidth}px`}
                      className={`header-${column.id}`}
                    >
                      <HeadTextWrapper>{column.render('Header')}</HeadTextWrapper>
                    </TableData>
                  )
                })}
                {editable && (
                  <FixedRightSection style={{ height: '40px' }}>
                    {/* Empty TableData to adjust width for action column that is positioned absolute */}
                    <TableData defaultWidth={`${actionCellWidth}px`} />
                  </FixedRightSection>
                )}
              </TableRow>
            ))}
          </RowContainer>
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          <RowContainer>
            {rows.map((row, index) => {
              prepareRow(row)
              const rowId = getRowId(row)
              const isNewRow = rowId === undefined && data.length === index
              return (
                <TableRow data-id="hm-sessions-entry" {...row.getRowProps()}>
                  {bulkAction && (
                    <TableData
                      role="cell"
                      defaultWidth={`${checkboxCellWidth}px`}
                      className="checkbox-cell"
                    >
                      {!isNewRow && (
                        <CheckboxCell
                          onChange={(value) => handleSelect(rowId, row, value)}
                          checked={selected.has(rowId)}
                        />
                      )}
                    </TableData>
                  )}
                  {row.cells.map((cell) => {
                    const { cellWidth } = cell.column.fieldProps || {}
                    return (
                      <TableData
                        {...cell.getCellProps()}
                        defaultWidth={cellWidth ? `${cellWidth}px` : defaultWidth}
                        minWidth={`${cellWidth || minDefaultWidth}px`}
                        className={`cell-${cell.column.id} data-cell`}
                      >
                        {cell.render('Cell')}
                      </TableData>
                    )
                  })}
                  {/* Empty TableData to adjust width for action column that is positioned absolute */}
                  {editable && <TableData role="cell" defaultWidth={`${actionCellWidth}px`} />}
                </TableRow>
              )
            })}
          </RowContainer>
          {/* Action column with fixed position on the right side of the table */}
          {editable && (
            <FixedRightSection>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    <TableData defaultWidth={`${actionCellWidth}px`} role="cell" isActionCell>
                      <ActionCell
                        index={index}
                        actions={actions}
                        onEdit={() => handleEditRow(row.original)}
                        onDelete={() => handleDeleteRow(row.original)}
                        onCopy={() => handleCopyRow(row.original)}
                      />
                    </TableData>
                  </TableRow>
                )
              })}
            </FixedRightSection>
          )}
        </TableBody>
      </TableContainer>
      {loading && <ActivityIndicator active name="CampaignSessionsForm" />}
    </Wrapper>
  )
}

/**
 * Callabcks
 *
 * onEdit({ id }) - Callback to be used when user clicks on the edit icon
 *                  If false is returned it will terminate the operation
 *
 * onDelete({ id }) - Callback to be used when user clicks on the delete icon
 *
 */

/**
 * Data Props
 *
 * idKey - represents a key in data for each row which can serve as unique to identify row *
 *
 */

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  idKey: PropTypes.string,
  editable: PropTypes.bool,
  bulkAction: PropTypes.bool,
  loading: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.oneOf(['copy', 'edit', 'delete'])),
  selected: PropTypes.instanceOf(SelectionManager).isRequired,
  // helping callbacks
  handleSelect: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
  onSelectedChanged: PropTypes.func
}

Table.defaultProps = {
  data: [],
  idKey: 'id',
  editable: false,
  loading: false,
  bulkAction: false,
  actions: ['copy', 'edit', 'delete'],
  onEdit: () => null,
  onDelete: () => null,
  onCopy: () => null,
  onSelectedChanged: () => null
}

export default withSelectionManager(Table)
