import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { Typography } from '@elevate_security/elevate-component-library'
import { Row, Column } from './styles'

const { Text } = Typography
function ScoreSummary({ formattedSurveyScores = [] }) {
  return (
    Array.isArray(formattedSurveyScores) &&
    formattedSurveyScores.map(({ value, title, color }) => {
      return (
        value !== 0 && (
          <Row>
            <Column>
              <Row style={{ maxWidth: '30px', backgroundColor: color }} />
              <Text>{title}</Text>
            </Column>
          </Row>
        )
      )
    })
  )
}

ScoreSummary.propTypes = {
  formattedSurveyScores: PropTypes.shape([]).isRequired
}

export default memo(ScoreSummary)
