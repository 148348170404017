import Moment from 'moment'
import {
  parseDateForPicker,
  getDateWithTimezone,
  isSameDate,
  startOf,
  endOf
} from '../../../../services/utils'
import { EMAIL_TEMPLATE_TYPES } from '../../../../constants'

export const getFormattedData = (data) => {
  return {
    ...data,
    start_date: parseDateForPicker({ date: data.start_date, timezone: data.timezone }),
    end_date: parseDateForPicker({ date: data.end_date, timezone: data.timezone })
  }
}

export function getEmailTemplateOptions(templates, data) {
  if (!templates || !templates.results || !Array.isArray(templates.results)) return []
  const { results } = templates
  return results
    .filter((template) => template.key === EMAIL_TEMPLATE_TYPES[data.rollout_type])
    .map(({ id, name }) => ({
      value: id,
      label: name
    }))
}

export const getMinMaxSessionDates = (rooms) => {
  const min = Moment.min(rooms.map((room) => Moment(room.start_time)))
  const max = Moment.max(rooms.map((room) => Moment(room.end_time)))
  return [min, max]
}

export const getDatesRange = ({ startDate, endDate, timezone, sessionMinDate, sessionMaxDate }) => {
  const selectedStartDate = getDateWithTimezone(startDate, timezone)
  const selectedEndDate = getDateWithTimezone(endDate, timezone)
  const minDate = getDateWithTimezone(sessionMinDate, timezone)
  const maxDate = getDateWithTimezone(sessionMaxDate, timezone)
  const currentTime = getDateWithTimezone(Moment(), timezone)

  const startAfter = isSameDate(selectedStartDate, currentTime)
    ? currentTime
    : startOf(currentTime, 'day')
  const startBefore = !sessionMinDate
    ? null
    : startDate && isSameDate(selectedStartDate, minDate)
    ? minDate
    : endOf(minDate, 'day')
  const endAfter = !sessionMaxDate
    ? null
    : endDate && isSameDate(selectedEndDate, maxDate)
    ? maxDate
    : startOf(maxDate, 'day')

  return [startAfter, startBefore, endAfter].map((date) =>
    date ? Moment(date.format('M/DD/YYYY, hh:mm A')) : null
  )
}
