import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { wizardSelector } from '../../services/redux/selectors'
import { isStepReady, stepOutOfSync } from './utils'

export function withStepManager(WrappedComponent, currentStepName, formName) {
  return function wrapper(props) {
    const { id } = useParams()
    const { campaign, wizardForm, wizardStep } = useSelector(wizardSelector)
    const [ready, setReady] = useState(false)
    const [entering, setEntering] = useState(false)

    useEffect(() => {
      if (!entering && ready && !campaign.busy) {
        setEntering(ready)
      }
    }, [ready])

    /**
     * Covers steps with forms
     */
    if (formName && isStepReady(wizardForm, formName, id, campaign) && !ready) {
      setReady(true)
    }

    /**
     * Covers steps without forms
     */
    if (!formName && !stepOutOfSync(wizardStep, currentStepName) && !ready) {
      setReady(true)
    }

    return <WrappedComponent {...{ ready, entering, ...props }} />
  }
}
