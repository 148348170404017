import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Tooltip } from '@elevate_security/elevate-component-library'
import { WrapperIcon } from './styles'

function DateTooltip({ text }) {
  return (
    <WrapperIcon>
      <Tooltip
        body={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div>
            <div>{text}</div>
            <br />
            <div>
              Start and end dates are limited by any previously-created sessions. To enable more
              timeslots, update date ranges of other sessions.
            </div>
          </div>
        }
        placement="top"
        readOnly
        size="sm"
      >
        <Icon name="CircleExclamation" size="xsm" fill="#959DA8" />
      </Tooltip>
    </WrapperIcon>
  )
}

DateTooltip.propTypes = {
  text: PropTypes.string.isRequired
}

export default DateTooltip
