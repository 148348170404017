import PropTypes from 'prop-types'
import React, { useEffect, useRef, memo } from 'react'

const { Chart } = window

function PercentageTooltip(tooltipItem, data) {
  const { index } = tooltipItem
  const label = data.labels[index]
  const number = data.datasets[0].data[index].toFixed(0)
  return `${label}: ${number}%`
}

function PolarChart(props) {
  const { canvasName, data, type } = props
  const canvasRef = useRef(null)
  let canvasChart = null
  let canvas = null
  useEffect(() => {
    // eslint-disable-next-line react/no-string-refs
    canvas = canvasRef.current ? canvasRef.current.getContext('2d') : null
    renderChart()
  })

  function renderChart() {
    if (canvasChart) canvasChart.destroy()
    canvas = !canvas ? canvasRef.current.getContext('2d') : canvas
    canvasChart = new Chart(canvas, {
      type: 'polarArea',
      data: getData(),
      options: getOptions()
    })
  }

  function convertToPercentages(arr) {
    const total = arr.reduce((a, b) => a + b)
    return arr.map((val) => (val / total.toFixed(2)) * 100)
  }

  function getData() {
    const labels = Object.keys(data).length ? Object.keys(data) : ['No Responses Yet']
    const counts = Object.values(data).length ? convertToPercentages(Object.values(data)) : [0]

    return {
      labels,
      datasets: [
        {
          label: type,
          fill: true,
          backgroundColor: [
            'rgba(239,83,80, 0.5)',
            'rgba(255,167,38, 0.5)',
            'rgba(212,225,87,0.5)',
            'rgba(0,150,136, 0.5)',
            'rgba(66,165,245, 0.5)',
            'rgba(29, 25, 147, 0.5)',
            'rgba(171,71,188, 0.5)',
            'rgba(160, 8, 8, 0.5)'
          ],
          borderColor: 'rgba(47,216,188, 0)',
          tension: 0.000001,
          data: counts
        }
      ]
    }
  }

  function getOptions() {
    /* If there is data, set the outer ring of the chart to the max in the set if there are no responses yet, make the outer ring = 100% */
    const chartMax = Object.values(data).length
      ? Math.max(...convertToPercentages(Object.values(data)))
      : 100
    return {
      title: {
        display: true,
        text: type,
        fontSize: 25,
        fontStyle: 'normal',
        fontColor: '#444D58'
      },
      position: 'custom',
      scale: {
        ticks: {
          fontColor: 'black',
          autoSkip: true,
          maxTicksLimit: 5,
          suggestedMax: chartMax,
          showLabelBackdrop: false,
          callback: (value) => `${value.toFixed(1)} %`
        },
        pointLabels: {
          fontColor: '#444D58',
          fontSize: 20
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0.2)'
        },
        angleLines: {
          color: 'rgba(0, 0, 0, 0)'
        }
      },
      tooltips: {
        displayColors: false,
        footerFontSize: 5,
        callbacks: {
          label: PercentageTooltip
        },
        x: 100,
        y: 100,
        width: 100,
        height: 100,
        caretX: 100,
        caretY: 100
      },
      legend: {
        labels: {
          fontColor: '#444D58',
          fontSize: 15
        }
      }
    }
  }

  return <canvas id={canvasName} ref={canvasRef} width="400px" height="400px" />
}

PolarChart.propTypes = {
  canvasName: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object])
}

PolarChart.defaultProps = {
  data: {},
  canvasName: '',
  type: ''
}

export default memo(PolarChart)
