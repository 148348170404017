import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@elevate_security/elevate-component-library'
import { FlexItem } from '../../../../../../common.styles'
import { NoSessionsFoundContainer } from './styles'

const { Body } = Typography

function NoSessionsFound({ children }) {
  return (
    <NoSessionsFoundContainer
      data-id="hm-sessions-not-found"
      justify="center"
      align="center"
      self="stretch"
    >
      <FlexItem>
        <Body>
          <strong>{children}</strong>
        </Body>
      </FlexItem>
    </NoSessionsFoundContainer>
  )
}

NoSessionsFound.propTypes = {
  children: PropTypes.node.isRequired
}

export default NoSessionsFound
