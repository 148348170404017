import cloneDeep from 'lodash/cloneDeep'
import invert from 'lodash/invert'
import Moment from 'moment'
import Timezone from 'moment-timezone'
import dompurify from 'dompurify'
import { LANGUAGES } from '../constants'
import { ControlType } from './form.types'

export const isDefined = (val) => val !== undefined && val !== null

export const mapToId = (array) => array.map(({ id }) => id)

export const userQuery = (search) => {
  const params = new URLSearchParams(search)

  return Array.from(params.keys()).reduce(
    (query, key) => ({
      ...query,
      [key]: params.get(key)
    }),
    {}
  )
}

export const getResultValue = (name, definition, values) => {
  if (values && isDefined(values[name])) {
    return values[name]
  }

  if (name in definition && isDefined(definition[name].value)) {
    return definition[name].value
  }

  return null
}

export const getToggleValue = (field, value) => {
  const {
    value: { checked },
    key
  } = value
  if (checked) {
    const exists = (field.value || []).find((vl) => vl === key)
    return !exists ? [...(field.value || []), key] : field.value
  }
  return (field.value || []).filter((val) => key !== val)
}

export const getValueByFieldType = (field, value) => {
  switch (field.type) {
    case ControlType.TOGGLE:
      return getToggleValue(field, value)
    default:
      return value.value
  }
}

// comapares two dates without time
export const isSameDate = (date1, date2) =>
  date1.clone().startOf('day').isSame(date2.clone().startOf('day'))

// startOf and endOf are moment methods, key can be day, month or year
export const startOf = (date, key) => date.clone().startOf(key)

export const endOf = (date, key) => date.clone().endOf(key)

// converts date according to specified timezone
export const getDateWithTimezone = (date, timezone) => Moment(Timezone.tz(date, timezone))

// converts time from UTC into timezone for UI
export const parseDateForUI = ({ date, timezone, time }) =>
  date && timezone
    ? time
      ? Timezone.tz(date, timezone).format('MMMM Do, YYYY, LT')
      : Timezone.tz(date, timezone).format('MMMM Do, YYYY')
    : null

// converts time to local timezone
export const parseCreatedForUI = ({ date, time }) =>
  date
    ? time
      ? Moment(date).format('MMMM Do, YYYY, LT')
      : Moment(date).format('MMMM Do, YYYY')
    : null

// format dates in campaign properties
export const formatDatesToUI = (campaign) => ({
  ...campaign,
  ui_created: parseCreatedForUI({
    date: new Date(campaign.created),
    time: true
  }),
  ui_start_date: parseDateForUI({
    date: new Date(campaign.start_date),
    timezone: campaign.timezone,
    time: true
  }),
  ui_end_date: campaign.end_date
    ? parseDateForUI({
        date: new Date(campaign.end_date),
        timezone: campaign.timezone,
        time: true
      })
    : 'N/A'
})

export const parseDateForPicker = ({ date, timezone }) =>
  date
    ? (timezone ? Timezone.tz(date, timezone) : Timezone.tz(date, Moment.tz.guess()))
        .format()
        .slice(0, 16)
    : null

// converts time and timezone to UTC
export const timeAndTzToUTC = ({ date, timezone }) =>
  date && timezone ? Timezone.tz(date, timezone).utc().format() : Moment(date).utc().format()

export const pluralize = (word, plural, suffix = 's') => (plural ? `${word}${suffix}` : word)

export const titleCase = (string) =>
  string
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ')

export const exportCSVFromApi = ({ data, filename }) => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const exportToCsv = ({ filename, rows }) => {
  const processRow = (row) => {
    let finalVal = ''
    for (let j = 0; j < row.length; j += 1) {
      let innerValue = row[j] ? row[j].toString() : ''
      if (row[j] instanceof Date) innerValue = row[j].toLocaleString()

      let result = innerValue.replace(/"/g, '""')
      if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`
      if (j > 0) finalVal += ','
      finalVal += result
    }
    return `${finalVal}\n`
  }

  let csvFile = ''
  for (let i = 0; i < rows.length; i += 1) {
    if (rows[i]) csvFile += processRow(rows[i])
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const downloadCSV = ({ rows, type, name, csvProperties }) => {
  if (!rows || !rows.length) return

  const columnHeaders = csvProperties[type].map((key) => titleCase(key.replace(/_/g, ' ')))
  rows.unshift(columnHeaders)
  exportToCsv({ filename: `${name.split(' ').join('_')}_${type}.csv`, rows })
}

export const copyToClipboard = (text, cb) => {
  if (!navigator.clipboard) {
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      if (cb) cb(successful)
    } catch (err) {
      if (cb) cb(false)
    }

    document.body.removeChild(textArea)
    return
  }

  navigator.clipboard.writeText(text, cb)
}

/**
 * Parses data session rooms coming and convert specific values
 * to allow them to be used by the form and list.
 *
 * @param data
 * @returns {*}
 */
export const parseCampaignRoomsResponse = (data) => {
  return data.map((room) => {
    const { timezone, language, leader, ...others } = cloneDeep(room)
    return {
      ...others,
      timezone,
      language: LANGUAGES[language],
      leader: leader ? [leader] : [],
      raw: room
    }
  })
}

export const parseCampaignRoomPayload = (data) => {
  return data.map((room) => {
    const { start_time, end_time, timezone, language, leader } = room

    return {
      ...room,
      language: invert(LANGUAGES)[language],
      timezone,
      leader: Array.isArray(leader) && leader.length ? leader[0] : null,
      start_time: timeAndTzToUTC({ date: start_time, timezone }),
      end_time: timeAndTzToUTC({ date: end_time, timezone })
    }
  })
}

export const parseCampaignPayload = (campaign) => {
  const { start_date, end_date, timezone } = cloneDeep(campaign)
  return {
    ...campaign,
    ...(start_date
      ? {
          start_date: timeAndTzToUTC({ date: start_date, timezone })
        }
      : {}),
    ...(end_date
      ? {
          end_date: timeAndTzToUTC({ date: end_date, timezone })
        }
      : {})
  }
}

export const getGroupListAsString = (campaignGroups) => {
  const groupNameList = campaignGroups.map((group) => group.name)
  const groupNameListAsString = groupNameList.join(', ')
  return groupNameListAsString
}

/**
 * It takes a dirty HTML as input and return a clean string
 * free of any svg, svg filters, mathML and HTML
 *
 * @param {string} dirty
 */
export const sanitizeContent = (dirty) =>
  dompurify.sanitize(dirty, {
    USE_PROFILES: { svg: false, svgFilters: false, mathMl: false, html: false }
  })
