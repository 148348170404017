import React from 'react'
import PropTypes from 'prop-types'
import { Container, Content, InputBox, Label } from './styles'

function Timeline({ settings }) {
  return (
    <Container>
      {settings.map(({ label, input }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Content key={index.toString()} isLastBullet={settings.length === index + 1}>
          <Label>{label}</Label>
          <InputBox>{input}</InputBox>
        </Content>
      ))}
    </Container>
  )
}

Timeline.propTypes = {
  settings: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.node.isRequired, input: PropTypes.node.isRequired })
  ).isRequired
}
export default Timeline
