import React from 'react'
import PropTypes from 'prop-types'
import dompurify from 'dompurify'
import { Icon, Button, Typography } from '@elevate_security/elevate-component-library'
import { useDispatch } from 'react-redux'
import { CLEAR_WIZARD_ERROR } from '../../services/redux/wizard/types'
import { ErrorMessageContainer, MessageBody, MessageDismissButton, ReloadLink } from './styles'

const { Body, Small } = Typography

function ErrorMessage({ error, style }) {
  const dispatch = useDispatch()
  const { message } = error || {}
  const sanitizer = dompurify.sanitize

  const handleClose = () => {
    dispatch({
      type: CLEAR_WIZARD_ERROR
    })
  }

  if (!error) {
    return null
  }

  return (
    <ErrorMessageContainer hasMessage={!!message} style={{ ...style }}>
      <div style={{ flex: '1 1 15%', maxWidth: '15%', minWidth: '15%' }} />
      <div style={{ flex: '1 1 40px', maxWidth: '40px', minWidth: '40px' }}>
        <Icon name="CircleExclamation" fill="#fff" />
      </div>
      <MessageBody>
        <Body>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(message)
            }}
          />
        </Body>
        <Small>
          Please <ReloadLink onClick={() => window.location.reload()}>refresh the page</ReloadLink>{' '}
          and try again, if the problem persists contact our support team.
        </Small>
      </MessageBody>
      <MessageDismissButton>
        <Button theme="ghost" onClick={handleClose}>
          dismiss
        </Button>
      </MessageDismissButton>
      <div style={{ flex: '1 1 15%', maxWidth: '25px', minWidth: '25px' }} />
    </ErrorMessageContainer>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    info: PropTypes.object.isRequired
  }),
  style: PropTypes.shape({})
}

ErrorMessage.defaultProps = {
  error: {},
  style: {}
}

export default ErrorMessage
