/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Icon, RadioButton, Typography, Tooltip } from '@elevate_security/elevate-component-library'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { ActivityIndicator, InputErrors, EmployeesMenu } from '../../../../components'
import { STEP, WIZARD_MODAL_SIZE, ROLLOUT_TYPE, ICON_COLOR } from '../../../../constants'
import { wizardSelector } from '../../../../services/redux/selectors'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { mapToId } from '../../../../services/utils'
import { RouteContainer, Label } from '../../styles'
import { formOutOfSync, getUrl, useActions } from '../../utils'
import { withForm } from '../../with-form'
import { withStepManager } from '../../with-step'
import { getRollOutForm, HM_ROLL_OUT_TYPE_FORM_NAME } from './form'
import { ActionButton, WrapperSelectionLeads, WrapperAddedSessions, ErrorWrapper } from './styles'

const { Text } = Typography

function RollOutType(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const { handleChange, ready, entering } = props
  const { wizardForm, wizardProps, campaign } = useSelector(wizardSelector)
  const {
    setWizardForm,
    setWizardModalProps,
    searchEmployees,
    getCampaignLeads,
    getCampaignRooms,
    updateRolloutType
  } = useActions(wizardActions)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [predefinedLeads, setPredefinedLeads] = useState(null)
  const [employeeResults, setEmployeeResults] = useState([])
  const [pencilColor, setPencilColor] = useState(ICON_COLOR.SIMPLE)

  useEffect(() => {
    if (!loaded && !loading) {
      setLoading(true)

      Promise.all([
        getCampaignLeads({
          campaignId: id
        }),
        getCampaignRooms({
          campaignId: id
        })
      ]).then(() => {
        setLoaded(true)
        setLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (
      campaign.loaded() &&
      formOutOfSync(id, HM_ROLL_OUT_TYPE_FORM_NAME, campaign && campaign.id(), wizardForm) &&
      loaded &&
      !loading
    ) {
      setWizardForm(getRollOutForm(campaign.data(), campaign.getLeads(), campaign.getRooms()))
    }
  }, [campaign, loading, loaded])

  useEffect(() => {
    if (wizardProps.size !== WIZARD_MODAL_SIZE.MEDIUM) {
      dispatch(setWizardModalProps({ size: WIZARD_MODAL_SIZE.MEDIUM }))
      // if coming from sessions screen then re-validate
      setWizardForm(getRollOutForm(campaign.data(), campaign.getLeads(), campaign.getRooms()))
    }
  }, [wizardProps])

  const getDefaultType = () => {
    const type = campaign.getType()
    const hasType = [ROLLOUT_TYPE.PEER_SCHEDULING, ROLLOUT_TYPE.PREDEFINED_LEADS].includes(type)
    return hasType ? type : ROLLOUT_TYPE.PEER_SCHEDULING
  }

  const handleTypeChange = (updatedType) => {
    updateRolloutType({ campaignId: id, rollout_type: updatedType })
    setWizardForm(
      getRollOutForm(
        {
          ...campaign.data(),
          rollout_type: updatedType || getDefaultType()
        },
        campaign.getLeads(),
        campaign.getRooms()
      )
    )
  }

  const handleSearchEmployees = (text) => {
    searchEmployees({
      text,
      cb: (data) => {
        const leadIds = mapToId(predefinedLeads || [])
        setEmployeeResults(
          data.filter(
            // do not show employees in dropdown list that are already selected
            ({ id: leadId }) => !leadIds.includes(leadId)
          )
        )
      }
    })
  }

  const handleSelectEmployee = (employees) => {
    setPredefinedLeads(employees)
    handleChange('predefined_leaders', { value: mapToId(employees) })
  }

  if (!ready || loading) {
    return <ActivityIndicator active />
  }

  const { rollout_type, predefined_leaders, rooms } = wizardForm.get()
  const sessions = campaign.getRooms()
  const leads = campaign.getLeads()

  if (!predefinedLeads && leads && leads.length) {
    setPredefinedLeads(leads)
  }

  const renderPeerScheduling = () => (
    <div data-id="hm-roll-out-peer-scheduling">
      <ActionButton
        className="ripple-out"
        data-id="hm-roll-out-manage-sessions"
        to={`${getUrl(STEP.ROLL_OUT_UPDATE_SESSIONS, { id })}`}
      >
        <Text fontWeight="bold">
          <Icon name="Plus" stroke="#0c7d84" />I want to add sessions manually
        </Text>
      </ActionButton>
      <ActionButton
        className="ripple-out"
        data-id="hm-roll-out-upload-sessions"
        to={`${getUrl(STEP.ROLL_OUT_SESSIONS_UPLOAD, { id })}`}
      >
        <Text fontWeight="bold">
          <Icon name="Upload" fill="#0c7d84" />I want to bulk upload sessions
        </Text>
      </ActionButton>
      {rooms && !rooms.valid && (
        <ErrorWrapper>
          <InputErrors errors={rooms.errors} relative />
        </ErrorWrapper>
      )}
    </div>
  )

  const renderPredefinedLead = () => (
    <WrapperSelectionLeads
      data-id="hm-roll-out-predefined-leads"
      invalid={!predefined_leaders.valid}
    >
      <Label>Specify individuals to run your training sessions:</Label>
      <AsyncTypeahead
        multiple
        selectHintOnEnter
        placeholder="Search..."
        labelKey="email"
        defaultSelected={leads || []}
        options={employeeResults}
        maxResults={5}
        onSearch={handleSearchEmployees}
        onChange={handleSelectEmployee}
        renderMenu={(results, menuProps) => (
          <EmployeesMenu
            results={results}
            menuProps={menuProps}
            width="100%"
            labels={['first_name', 'last_name']}
          />
        )}
        filterBy={() => true} /* Let backend filter options */
      />
      {predefined_leaders && !predefined_leaders.valid && (
        <InputErrors errors={predefined_leaders.errors} relative />
      )}
    </WrapperSelectionLeads>
  )

  const renderAddedSessionsBlock = () => (
    <WrapperAddedSessions data-id="hm-roll-out-peer-scheduling">
      <Icon name="CheckCircle" stroke="#178574" size="lg" />
      <Text className="text">
        <strong>
          {sessions.length} session{sessions.length > 1 ? 's' : ''}
        </strong>{' '}
        added
      </Text>
      <span
        data-id="hm-roll-out-manage-sessions"
        className="pencil"
        onMouseEnter={() => setPencilColor(ICON_COLOR.HOVER)}
        onMouseLeave={() => setPencilColor(ICON_COLOR.SIMPLE)}
        onClick={() => history.push(getUrl(STEP.ROLL_OUT_UPDATE_SESSIONS, { id }))}
      >
        <Tooltip body="Manage sessions" placement="top" readOnly size="sm">
          <Icon name="Pencil" fill={pencilColor} size="sm" />
        </Tooltip>
      </span>
    </WrapperAddedSessions>
  )

  return (
    <CSSTransition in={entering} classNames="scale" timeout={300}>
      <RouteContainer>
        <form data-id="hm-roll-out-form">
          <div data-id="hm-roll-out-type">
            <Label>Roll out via:</Label>
            <RadioButton
              options={rollout_type.options}
              radioSelected={rollout_type.value || rollout_type.defaultValue}
              onChange={handleTypeChange}
            />
          </div>
          {rollout_type.value === ROLLOUT_TYPE.PREDEFINED_LEADS && renderPredefinedLead()}
          {rollout_type.value === ROLLOUT_TYPE.PEER_SCHEDULING &&
            !sessions.length &&
            renderPeerScheduling()}
          {rollout_type.value === ROLLOUT_TYPE.PEER_SCHEDULING &&
            sessions.length > 0 &&
            renderAddedSessionsBlock()}
          <br />
        </form>
      </RouteContainer>
    </CSSTransition>
  )
}

RollOutType.propTypes = {
  handleChange: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  entering: PropTypes.bool.isRequired
}

export default withStepManager(
  withForm(RollOutType),
  STEP.ROLL_OUT_TYPE,
  HM_ROLL_OUT_TYPE_FORM_NAME
)
