import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { Typography } from '@elevate_security/elevate-component-library'
import { PolarChart, LineGraph } from '../Charts'
import { Container, Row, Column } from './styles'

const { H3, H4, Text } = Typography
function CampaignMetrics({
  campaignProps,
  completed,
  surveyResponse,
  lineGRaphOptions,
  attackVectors,
  bugs,
  surveyAvg
}) {
  return (
    <Container container>
      <Row>
        <Column lg={3} md={3} sm={12}>
          <H3 color="900">Campaign Metrics</H3>
        </Column>
        <Column lg={3} md={3} sm={12}>
          <Row style={{ maxWidth: '30px', backgroundColor: '#1ea8dd' }} />
          <Text>Campaign Completion</Text>
          <H4 color="900">{`${completed || 0}%`}</H4>
        </Column>
        <Column lg={3} md={3} sm={12}>
          <Row style={{ maxWidth: '30px', backgroundColor: '#FFC049' }} />
          <Text>Survey Responses</Text>
          <H4 color="900">{`${surveyResponse || 0}%`}</H4>
        </Column>
        <Column lg={3} md={3} sm={12}>
          <Row style={{ maxWidth: '30px', backgroundColor: '#2EC6D3' }} />
          <Text>Survey Average</Text>
          <H4 color="900">{`${surveyAvg}`}</H4>
        </Column>
      </Row>
      <Row>
        <Column lg={12} md={12} sm={12}>
          <LineGraph data={campaignProps} options={lineGRaphOptions} />
        </Column>
      </Row>
      <Row>
        <Column lg={12} md={12} sm={12}>
          <hr />
        </Column>
      </Row>
      <Row>
        <Column lg={6} md={6} sm={12}>
          <PolarChart type="Bugs in Human Hardware" data={bugs} canvasName="campaign-bugs" />
        </Column>
        <Column lg={6} md={6} sm={12}>
          <PolarChart
            type="Attack Vectors"
            data={attackVectors}
            canvasName="campaign-attack-vectors"
          />
        </Column>
      </Row>
    </Container>
  )
}

CampaignMetrics.propTypes = {
  campaignProps: PropTypes.shape({}).isRequired,
  completed: PropTypes.number.isRequired,
  surveyResponse: PropTypes.number.isRequired,
  lineGRaphOptions: PropTypes.shape({}).isRequired,
  attackVectors: PropTypes.shape([]).isRequired,
  bugs: PropTypes.shape([]).isRequired,
  surveyAvg: PropTypes.number.isRequired
}

export default memo(CampaignMetrics)
