/* eslint-disable no-param-reassign */
/* eslint-disable-next-line no-unused-vars */
import queryString from 'query-string'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import { createCampaign } from '../wizard/api'
import { CAMPAIGN_STATUS_NAME, CAMPAIGN_STATUS } from '../../../constants'
import { campaignsSelector, getListCampaign, getOrganizationId } from '../selectors'
import * as api from './api'
import {
  BEFORE_GET_CAMPAIGNS_LIST,
  SET_CAMPAIGNS_LIST,
  SET_CAMPAIGN_STATUSES,
  SET_CURRENT_TAB,
  CREATE_CAMPAIGN_MESSAGE,
  CLOSE_CAMPAIGN_MESSAGE,
  COMPLETE_YEARS
} from './types'
import { getCampaignMessage } from '../../../scenes/Campaigns/utils'

const getNewCopiedCampaign = (campaign, leads) => {
  const campaignCloned = omit(cloneDeep(campaign), ['id', 'targets', 'leads'])
  return {
    ...campaignCloned,
    predefined_leaders: leads.map((lead) => lead.id),
    name: 'Copy of '.concat(campaign.name),
    status: CAMPAIGN_STATUS.DRAFT,
    groups: campaign.groups.map((group) => group.id)
  }
}

export const setCurrentTab = (tab) => ({ type: SET_CURRENT_TAB, data: tab })

export const getCampaignList =
  (args = {}) =>
  async (dispatch, getState) => {
    const { filter } = args
    const orgId = getOrganizationId(getState())

    dispatch({
      type: BEFORE_GET_CAMPAIGNS_LIST
    })

    const { data, error } = await api.fetchCampaignList({ orgId, filter })

    if (!error) {
      const params = queryString.parse(filter)

      dispatch({
        type: SET_CAMPAIGNS_LIST,
        key: CAMPAIGN_STATUS_NAME[params.status ? params.status : CAMPAIGN_STATUS.ALL],
        data
      })
    }
  }

export const getCompletionYears = () => async (dispatch, getState) => {
  const { data } = await api.fetchCompletionYears({ orgId: getOrganizationId(getState()) })

  const allYears =
    data?.years?.map((item) => {
      return { label: item, value: item }
    }) || []
  if (allYears?.length) {
    allYears.unshift({ label: 'All Years', value: 'all_years' })
  }
  dispatch({ type: COMPLETE_YEARS, data: allYears })
}

export const downloadCompletionReport =
  (year = '', cancelHandler = () => null) =>
  async (dispatch, getState) => {
    const { data, error } = await api.fetchCompletionReport({
      orgId: getOrganizationId(getState()),
      ...(year ? { year } : {})
    })

    if (!error) {
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'completion_report.csv')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
    cancelHandler()
  }

export const setCampaignStatuses = () => async (dispatch) => {
  const { data, error, status } = await api.fetchCampaignStatuses()
  if (!error) {
    dispatch({ type: SET_CAMPAIGN_STATUSES, data: status === 404 ? [] : data })
  }
}

export const deleteCampaign =
  ({ id, name }) =>
  async (dispatch, getState) => {
    const { error } = await api.deleteCampaign({ id })
    if (!error) {
      const { currentTab } = campaignsSelector(getState())
      dispatch(getCampaignList({ filter: `status=${currentTab}` }))
      dispatch(setCampaignStatuses())
      /**
       * Create the toast for the campaign duplication
       */
      const campaignMessage = getCampaignMessage(name, 'successfully removed')
      dispatch(createCampaignMessage(campaignMessage))
    }
  }

export const copyCampaign =
  ({ id }) =>
  async (dispatch, getState) => {
    const campaign = getListCampaign(getState(), id)

    if (!campaign) return

    const { data: leads, error: leadsError } = await api.fetchCampaignLeads({ campaignId: id })

    if (!leadsError) {
      const campaignCloned = getNewCopiedCampaign(campaign, leads)
      const { error } = await createCampaign(campaignCloned)

      if (!error) {
        const { currentTab } = campaignsSelector(getState())
        dispatch(getCampaignList({ filter: `status=${currentTab}` }))
        dispatch(setCampaignStatuses())
        /**
         * Create the toast for the campaign duplication
         */
        const campaignMessage = getCampaignMessage(campaignCloned.name, 'sucessfully created')
        dispatch(createCampaignMessage(campaignMessage))
      }
    }
  }

export const cancelCampaign =
  ({ id }) =>
  async (dispatch, getState) => {
    const { error } = await api.cancelCampaign({ id })

    if (!error) {
      const { currentTab } = campaignsSelector(getState())
      dispatch(getCampaignList({ filter: `status=${currentTab}` }))
    }
  }

export const archiveCampaign = (campaignId) => async (dispatch, getState) => {
  const { error } = await api.archiveCampaign(campaignId)

  if (!error) {
    const { currentTab } = campaignsSelector(getState())
    dispatch(getCampaignList({ filter: `status=${currentTab}` }))
  }
}

export const createCampaignMessage =
  (message, level = 'success') =>
  (dispatch) => {
    const toast = {
      id: Math.random(),
      message,
      level
    }
    dispatch({ type: CREATE_CAMPAIGN_MESSAGE, message: toast })
  }

export const closeCampaignMessage = (messageId) => (dispatch) => {
  dispatch({ type: CLOSE_CAMPAIGN_MESSAGE, messageId })
}
