import styled from 'styled-components'
import { theme } from '@elevate_security/elevate-component-library'
import { FlexColumn, FlexItem, FlexRow } from '../../../../../common.styles'

export const ErrorMessage = styled(FlexRow)`
  margin-bottom: 12px;
  color: #9a3c3c;
`

export const RowIndex = styled(FlexItem)`
  text-align: center;
  border-right: 1px solid ${theme.colors.border.default};
  margin-right: 12px;
`

export const RowErrorsContainer = styled(FlexColumn)`
  background: #fcf8f8;
  margin-bottom: 16px;
  max-height: 200px;
  overflow: auto;
`

export const RowErrors = styled(FlexColumn)`
  width: 100%;
`

export const RowHeaders = styled(FlexRow)`
  position: sticky;
  top: 0;
  padding: 12px;
  background: #fcf8f8;
  border-bottom: 1px solid ${theme.colors.border.default};
`

export const ErrorRow = styled(FlexRow)`
  padding: 6px 12px;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.border.default};
  }
`
