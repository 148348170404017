import { Toggle } from '@elevate_security/elevate-component-library'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ToggleGroupContainer = styled.div`
  padding-left: 10px;
`

function ToggleGroup({ radio: { options }, disabled, onChange }) {
  const keys = Object.keys(options || [])

  if (!keys.length) {
    return null
  }

  return (
    <ToggleGroupContainer>
      {Object.keys(options).map((key) => {
        const { label, name, checked } = options[key]
        return (
          <Toggle
            label={label}
            name={name}
            disabled={disabled}
            checked={checked}
            onChange={(val) => onChange(key, val)}
          />
        )
      })}
    </ToggleGroupContainer>
  )
}

ToggleGroup.propTypes = {
  radio: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired
      })
    ).isRequired
  }).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

ToggleGroup.defaultProps = {
  disabled: false
}

export default ToggleGroup
