import React, { useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'

const { Chart } = window

function PieGraph({ height, width, options, data }) {
  const lineGraph = useRef(null)
  let canvas = null
  let canvasChart = null
  useEffect(() => {
    canvas = lineGraph.current ? lineGraph.current.getContext('2d') : null
    renderChart()
  })

  function renderChart() {
    if (canvasChart) canvasChart.destroy()
    canvas = !canvas ? lineGraph.current.getContext('2d') : canvas
    canvasChart = new Chart(canvas, {
      type: 'doughnut',
      data,
      options
    })
  }

  return <canvas ref={lineGraph} height={height} width={width} />
}

PieGraph.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.object]),
  height: PropTypes.string,
  width: PropTypes.string
}

PieGraph.defaultProps = {
  options: {},
  data: {},
  height: '100%',
  width: '100%'
}

export default memo(PieGraph)
