import styled from 'styled-components'
import { Layout } from '@elevate_security/elevate-component-library'

export const Container = styled(Layout)`
  padding: 20px;
`
export const Row = styled(Layout).attrs({ container: true })`
  padding: 2px 8px;
`
export const Column = styled(Layout).attrs({ item: true })`
  padding: 4px 0;
`
export const ScoreBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 150px;
  margin: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  justify-content: center;
  @media (max-width: 1280px) {
    width: 120px;
    height: 120px;
    margin: 5px;
  }
`

export const Value = styled.div`
  font-size: 1.5rem;
`
export const Hr = styled.div`
  display: block;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.125);
  margin: 10px 5px;
`
