import styled from 'styled-components'
import { Typography } from '@elevate_security/elevate-component-library'

const { Small } = Typography

export const Hint = styled(Small)`
  ${(props) => (props.highlighted ? 'color: #c73434' : '')};
  display: block;
  margin-bottom: 12px;
`
