import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Button, Typography } from '@elevate_security/elevate-component-library'
import { ActivityIndicator } from '../../../../components'
import IconLink from '../../../../components/IconLink'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { wizardSelector } from '../../../../services/redux/selectors'
import { STEP, WIZARD_MODAL_SIZE } from '../../../../constants'
import { RouteContainer } from '../../styles'
import { getUrl, useActions } from '../../utils'
import { withSelectionManager } from '../../with-selection-manager'
import { withStepManager } from '../../with-step'
import { ModalHeader, Toast, OkWrapper, GoToSessionsUpload } from './styles'
import Sessions from './components/Sessions'

const { H2 } = Typography

function CampaignSessions(props) {
  const history = useHistory()
  const { id } = useParams()
  const { ready, entering } = props
  const { wizardProps } = useSelector(wizardSelector)
  const { setWizardModalProps } = useActions(wizardActions)
  const [showMessage, setShowMessage] = useState(history.location.search.includes('uploadSuccess'))

  useEffect(() => {
    if (wizardProps.size !== WIZARD_MODAL_SIZE.FULL_SCREEN) {
      setWizardModalProps({ size: WIZARD_MODAL_SIZE.FULL_SCREEN })
    }
  }, [wizardProps])

  const handleOnSubmit = () => {
    history.push(getUrl(STEP.ROLL_OUT_TYPE, { id }))
  }

  /**
   * Deffer if not ready
   */
  const showLoadingIndicator = wizardProps.size !== WIZARD_MODAL_SIZE.FULL_SCREEN || !ready
  const campaignSessionsUploadUrl = getUrl(STEP.ROLL_OUT_SESSIONS_UPLOAD, { id })
  return (
    <CSSTransition in={entering} classNames="scale" timeout={500}>
      <RouteContainer padding="0 20px" margin="0 -20px" size={WIZARD_MODAL_SIZE.FULL_SCREEN}>
        <ModalHeader>
          <H2 color="900" fontWeight="bold">
            Add campaign sessions
          </H2>
          <Button onClick={() => handleOnSubmit()}>Continue</Button>
        </ModalHeader>
        {showMessage && (
          <Toast>
            <strong>We&apos;ve received your CSV file!</strong> It may take a few minutes to
            process. We&apos;ll send you a status email as soon as we&apos;re done. Feel free to
            continue creating your campaign.
            <OkWrapper className="ok" onClick={() => setShowMessage(false)} role="button">
              OK, got it
            </OkWrapper>
          </Toast>
        )}
        {showLoadingIndicator && <ActivityIndicator active name="CampaignSessionsWrapper" />}
        {!showLoadingIndicator && (
          <>
            <GoToSessionsUpload>
              <IconLink
                text="Add sessions via CSV upload"
                iconName="Upload"
                fontWeight="bold"
                onClick={() => history.push(campaignSessionsUploadUrl)}
              />
            </GoToSessionsUpload>
            <Sessions actions={['edit', 'delete']} modal />
          </>
        )}
      </RouteContainer>
    </CSSTransition>
  )
}

CampaignSessions.propTypes = {
  ready: PropTypes.bool.isRequired,
  entering: PropTypes.bool.isRequired
}

export default withStepManager(
  withSelectionManager(CampaignSessions),
  STEP.ROLL_OUT_UPDATE_SESSIONS
)
