import styled from 'styled-components'

export const WrapperIcon = styled.span`
  margin-left: 5px;
  &:hover {
    svg line {
      stroke: #0c7d84;
    }
    svg path {
      fill: #0c7d84;
    }
  }
`
