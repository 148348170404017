import styled from 'styled-components'
import { theme, Typography } from '@elevate_security/elevate-component-library'
import { FlexColumn, FlexItem } from '../../../../../common.styles'

const { Text } = Typography

export const SessionContainer = styled(FlexColumn)`
  padding: 18px 0;
  border-bottom: 1px solid ${theme.colors.border.default};
`

export const RowNumber = styled(Text)`
  color: #d2d2d2;
`

export const ActionsContainer = styled(FlexItem)`
  button {
    padding: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    svg path {
      fill: #959da8;
    }
    svg line {
      stroke: #959da8;
    }
    &:hover {
      background-color: transparent;
      svg path {
        fill: #039ba1;
      }
      svg line {
        stroke: #039ba1;
      }
    }
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
    &:disabled,
    .disabled {
      background-color: transparent !important;
    }

    &[data-icon-name='Database'] {
      padding: 3px 5px;
      span {
        display: block;
        width: 28px;
        height: auto;
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
`
