/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { CustomSelect } from '@elevate_security/elevate-component-library'
import Checkbox from '../../../../../../../components/Checkbox'
import { CheckboxHeader } from './styles'

function CheckboxCell({ checked, isHeaderCell, disabled, indeterminate, onChange, onAction }) {
  return isHeaderCell ? (
    <CheckboxHeader disabled={disabled}>
      <Checkbox
        checked={checked}
        onChange={(value, event) => onChange(value, event)}
        disabled={disabled}
        indeterminate={indeterminate}
      />
      <CustomSelect options={['Remove']} onChange={(e) => onAction(e.value)} disabled={disabled} />
    </CheckboxHeader>
  ) : (
    <Checkbox
      checked={checked}
      onChange={(value, event) => onChange(value, event)}
      disabled={disabled}
    />
  )
}

CheckboxCell.propTypes = {
  cell: PropTypes.shape({}).isRequired,
  checked: PropTypes.bool.isRequired,
  isHeaderCell: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  onAction: PropTypes.func
}

CheckboxCell.defaultProps = {
  isHeaderCell: false,
  indeterminate: false,
  disabled: false,
  onChange: () => null,
  onAction: () => null
}

export default CheckboxCell
