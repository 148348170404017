import { FormManager } from '../../../../services/form'
import { ControlType } from '../../../../services/form.types'
import { mapToId } from '../../../../services/utils'
import { Constrain } from '../../../../services/validator'
import { ROLLOUT_TYPE } from '../../../../constants'

export const HM_ROLL_OUT_TYPE_FORM_NAME = 'roll-out-type-form'

const baseForm = {
  id: {
    type: ControlType.HIDDEN,
    dirty: true
  },
  rollout_type: {
    type: ControlType.RADIO,
    validations: [],
    options: [
      {
        label: 'Peer Scheduling',
        value: ROLLOUT_TYPE.PEER_SCHEDULING,
        helpText: 'Participants sign themselves up for available session time slots that you set up'
      },
      {
        label: 'Predefined Leads',
        value: ROLLOUT_TYPE.PREDEFINED_LEADS,
        helpText: 'Specified individuals will share, run, & manage their own training sessions'
      }
    ],
    defaultValue: ROLLOUT_TYPE.PEER_SCHEDULING
  }
}

export const getBaseForm = (data) =>
  new FormManager(
    HM_ROLL_OUT_TYPE_FORM_NAME,
    {
      ...baseForm
    },
    data
  )

export const getPeerSchedulingForm = (data) =>
  new FormManager(
    HM_ROLL_OUT_TYPE_FORM_NAME,
    {
      ...baseForm,
      rooms: {
        type: ControlType.MULTI_SELECT,
        validations: [
          {
            test: Constrain.REQUIRED,
            value: true,
            message: 'Must add at least 1 session'
          }
        ]
      }
    },
    data
  )
    /**
     * remove rooms since it is not needed in the final payload.
     */
    .addBeforeSubmitCallBack((form) => {
      const definition = form.get()
      // eslint-disable-next-line no-unused-vars
      const { rooms, ...otherFields } = definition

      return form.new(
        {
          ...otherFields
        },
        true
      )
    })

export const getPredefinedLeadForm = (data) =>
  new FormManager(
    HM_ROLL_OUT_TYPE_FORM_NAME,
    {
      ...baseForm,
      predefined_leaders: {
        type: ControlType.MULTI_SELECT,
        validations: [
          {
            test: Constrain.REQUIRED,
            value: true,
            message: 'Select at least one lead for this rollout type'
          }
        ]
      }
    },
    data
  )

const getAudienceType = (type) => {
  if (type === ROLLOUT_TYPE.MANAGER_LED) {
    return ROLLOUT_TYPE.PEER_SCHEDULING
  }
  return type || ROLLOUT_TYPE.PEER_SCHEDULING
}

export const getRollOutForm = (data, leads, rooms) => {
  const rollout_type = getAudienceType(data.rollout_type)

  if (rollout_type === ROLLOUT_TYPE.PREDEFINED_LEADS) {
    return getPredefinedLeadForm({
      ...data,
      rollout_type,
      predefined_leaders: mapToId(leads)
    }).validate()
  }

  return getPeerSchedulingForm({
    ...data,
    rollout_type,
    rooms
  }).validate()
}
