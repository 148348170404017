import React, { useState } from 'react'
import SelectionManager from '../../services/selection-manager'

/**
 *
 * @param WrappedComponent
 * @returns {function(*): *}
 */
export function withSelectionManager(WrappedComponent) {
  return function wrapper(props) {
    const [selected, setSelected] = useState(new SelectionManager())

    const handleSelectAll = (members, checked) => {
      if (checked) {
        const values = Array.isArray(members[0]) ? members : members.map((obj) => [obj, obj])
        setSelected(new SelectionManager(values))
      } else {
        setSelected(selected.reset())
      }
    }

    const handleSelect = (id, row, checked) => {
      if (checked) {
        setSelected(selected.select(id, row))
      } else {
        setSelected(selected.deselect(id))
      }
    }

    const updatedProps = {
      ...props,
      selected,
      setSelected,
      handleSelect,
      handleSelectAll
    }

    return <WrappedComponent {...updatedProps} />
  }
}
