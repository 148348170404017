import Moment from 'moment'
import { getDateWithTimezone, isSameDate, startOf, endOf } from '../../../../../../services/utils'

export const getFieldSetWidth = (modal) =>
  modal
    ? {
        room: '25%',
        room_size: '15%',
        start_time: '28%',
        end_time: '28%',
        leader: '40%',
        dial_in: '20%',
        language: '32%'
      }
    : {}

export const getDatesRange = ({ startDate, endDate, timezone, campaignStart, campaignEnd }) => {
  const selectedStartDate = getDateWithTimezone(startDate, timezone)
  const selectedEndDate = getDateWithTimezone(endDate, timezone)
  const campaignStartDate = getDateWithTimezone(campaignStart, timezone)
  const campaignEndDate = getDateWithTimezone(campaignEnd, timezone)
  const currentTime = getDateWithTimezone(Moment(), timezone)

  const minDate =
    !campaignStart || currentTime.isAfter(campaignStartDate) ? currentTime : campaignStartDate

  const startAfter =
    startDate && isSameDate(selectedStartDate, minDate) ? minDate : startOf(minDate, 'day')
  const endAfter =
    endDate && isSameDate(selectedEndDate, minDate) ? minDate : startOf(minDate, 'day')

  let startBefore = null
  let endBefore = null

  if (campaignEnd) {
    startBefore =
      startDate && isSameDate(selectedStartDate, campaignEndDate)
        ? campaignEndDate
        : endOf(campaignEndDate, 'day')
    endBefore =
      endDate && isSameDate(selectedEndDate, campaignEndDate)
        ? campaignEndDate
        : endOf(campaignEndDate, 'day')
  }

  return [startAfter, startBefore, endAfter, endBefore].map((date) =>
    date ? Moment(date.format('M/DD/YYYY, hh:mm A')) : null
  )
}

export const getMinMaxDateTime = (min, max) => ({
  ...(min && {
    minDate: min,
    minTime: min
  }),
  ...(max && {
    maxDate: max,
    maxTime: max
  })
})
