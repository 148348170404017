import PropTypes from 'prop-types'

export const RowPropTypes = PropTypes.shape({
  id: PropTypes.string,
  participants: PropTypes.arrayOf(PropTypes.shape({})),
  created: PropTypes.string,
  updated: PropTypes.string,
  location: PropTypes.string,
  role: PropTypes.string,
  room: PropTypes.string,
  room_size: PropTypes.number,
  dial_in: PropTypes.string,
  first_reminder_sent: false,
  second_reminder_sent: true,
  organization: PropTypes.string,
  campaign: PropTypes.string,
  session: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  timezone: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  language: PropTypes.string,
  leader: PropTypes.arrayOf(PropTypes.string),
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.any,
      column: PropTypes.shape({
        id: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        fieldProps: PropTypes.object
      })
    })
  )
})

export const HeadersPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    Header: PropTypes.string,
    fieldProps: PropTypes.shape({
      cellWidth: PropTypes.string
    })
  })
)
