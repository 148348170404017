import { HM_WIZARD_EVENT_API_ERROR } from '../constants'

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
}

export const API_ERROR_MESSAGE = {
  SESSION_EXPIRED_ERROR: `<p>Sorry, seems your session has expired.</p>
                         <p>Your are being redirected so you can re-authenticate and continue</p>`,
  NOT_FOUND_ERROR: '<p>Not found</p>',
  UNEXPECTED_ERROR: '<p>Sorry, an unexpected error has occurred while processing your request.</p>',
  CONTACT_SUPPORT: '<p>Please contact our support team.</p>',
  REFRESH_TRY_AGAIN_CONTACT_SUPPORT:
    '<p>Please verify that the campaign url is correct and this campaign exists and try again, if the problem persists contact our support team.</p>'
}

export const DEFAULT_API_MESSAGES = {
  400: API_ERROR_MESSAGE.UNEXPECTED_ERROR,
  403: API_ERROR_MESSAGE.SESSION_EXPIRED_ERROR,
  404: API_ERROR_MESSAGE.NOT_FOUND_ERROR,
  500: API_ERROR_MESSAGE.UNEXPECTED_ERROR
}

export const HM_CUSTOM_ERROR_MESSAGE_FETCH_CAMPAIGN = {
  400: `<p>Error while fetching a campaign information.<p>
        ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
  404: `<p>Sorry, the requested campaign was not found in our database.</p>
        <small>Please verify that the campaign data is correct and try again, if the problem persists contact our support team.</small>`
}

/**
 * Simple text interpolation to format
 * api error strings.
 *
 * @param message
 * @param args
 * @returns {*}
 */
const interpolate = (message, args) => {
  const regex = /\{([^}]+)\}/
  let match
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(message)) !== null) {
    // eslint-disable-next-line no-param-reassign
    message = message.replace(match[0], args[match[1]])
  }

  return message
}

const resolveMessage = (message, args) => {
  const type = typeof message
  if (type === 'string') {
    return interpolate(message, args)
  }

  if (type === 'function') {
    return message(message, args)
  }

  return null
}

/**
 * This is a special helper function that creates and manages
 * errors during api calls.
 *
 * When creating apis, use this function to wrap your `axios` call
 * in it like so:
 * @example
 * export const saveCampaign = createRequest((args) =>
 *    axios({
 *       method: METHOD.PATCH,
 *       url: `/hackersmindcampaigns/my-url/`,
 *       data: payload
 *     })
 *)
 *
 * This will make so whenever this request is executed, if an error occurs
 * it will dispatch a special window event which will be caught by the wizard
 * that will display one of the mapped error messages to the user in a stylized
 * form.
 *
 *
 * MAPPED ERROR RESPONSES
 * To provide api calls with custom error messages, pass the error message map
 * as the second parameter to createRequest like so:
 * @example
 * export const saveCampaign = createRequest((args) =>
 *    axios({
 *       method: METHOD.PATCH,
 *       url: `/hackersmindcampaigns/my-url/`,
 *       data: payload
 *    }),
 *    {
 *       400: `<p>Sorry, there was an error loading tags while starting the wizard.</p>
 *             ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
 *       404: `<p>Could not find tags in our database.</p>
 *             ${API_ERROR_MESSAGE.CONTACT_SUPPORT}`
 *    }
 *)
 *
 *
 * HOOKS
 * There is also a couple of hooks that can be used to interact with the data
 * sent or fetched from the server.
 *
 * The beforeDispatch hook runs right before the data is posted to the server,
 * so you can mutate, filter and change the data as you need before sending it
 * to the server.
 * @example
 * export const saveCampaign = createRequest((args) =>
 *    axios({
 *       method: METHOD.PATCH,
 *       url: `/hackersmindcampaigns/my-url/`,
 *       data: payload
 *    }),
 *    {
 *       400: `<p>Sorry, there was an error loading tags while starting the wizard.</p>
 *             ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
 *       404: `<p>Could not find tags in our database.</p>
 *             ${API_ERROR_MESSAGE.CONTACT_SUPPORT}`
 *    },
 *    {
 *      beforeDispatch: (data) => ({
 *           // ...Manipulate data here and return the new data
 *           return data
 *      })
 *    }
 *)
 *
 * The onFetch hook runs right after the data has been fetched from the server
 * and before being sent to the store, so it's a good place to filter or mutate
 * the data.
 * @example
 * export const saveCampaign = createRequest((args) =>
 *    axios({
 *       method: METHOD.PATCH,
 *       url: `/hackersmindcampaigns/my-url/`,
 *       data: payload
 *    }),
 *    {
 *       400: `<p>Sorry, there was an error loading tags while starting the wizard.</p>
 *             ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
 *       404: `<p>Could not find tags in our database.</p>
 *             ${API_ERROR_MESSAGE.CONTACT_SUPPORT}`
 *    },
 *    {
 *      onFetch: ({ results }) => ({
 *           // ...Manipulate results here and return the new data
 *           return { results }
 *      })
 *    }
 *)
 *
 * @param request
 * @param {string} [message]
 * @param {{ onFetch, beforeDispatch}} [callbacks]
 * @param {function(...[*]=)} [callbacks.onFetch]
 * @param {function(...[*]=)} [callbacks.beforeDispatch]
 * @param {function(...[*]=)} [callbacks.onResponse]
 * @param {function(...[*]=)} [callbacks.onError]
 * @returns {function(...[*]=)}
 */
export const createRequest = (request, message, callbacks) => {
  const { onFetch, beforeDispatch, onResponse } = callbacks || {}
  return async (...args) => {
    try {
      if (typeof beforeDispatch === 'function') {
        // eslint-disable-next-line no-param-reassign
        args = beforeDispatch(...args)
      }

      const result = await request(...args)
      const {
        data,
        status,
        config: { method }
      } = result

      if (method === 'get' && typeof onFetch === 'function') {
        return { data: onFetch(data, args), status }
      }

      if (typeof onResponse === 'function') {
        return { data: onResponse(result, args), status }
      }

      return { data, status }
    } catch (error) {
      const { data, status } = error.response

      /**
       * Interpolates the arguments to the message string
       */
      const interpolated =
        (message && message[status] && resolveMessage(message[status], args)) ||
        DEFAULT_API_MESSAGES[status] ||
        error.message

      const detail = { data, status, message: interpolated, error, info: error.response }

      document.dispatchEvent(new CustomEvent(HM_WIZARD_EVENT_API_ERROR, { detail }))

      return detail
    }
  }
}
