import styled from 'styled-components'
import Layout from '@elevate_security/elevate-component-library/dist/Layout'

export const Row = styled(Layout).attrs({ container: true })`
  padding: 0;
  margin: 0;
`
export const Column = styled(Layout).attrs({ item: true })`
  padding: 0;
  margin: 0;
`
export const PageHeaderWrapper = styled.div`
  h1 {
    margin: 16px 0 24px 0;
    line-height: normal;
  }
  a span {
    font-size: 13px;
  }
  div[class^='Breadcrumb__BreadcrumbContainer'] {
    svg {
      margin: 2px 3px 0 5px;
      width: 9px;
      height: 9px;
      path {
        fill: #7b828a;
      }
    }
  }
`
