import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  Typography,
  Layout,
  Button,
  Icon,
  Tooltip
} from '@elevate_security/elevate-component-library'
import { ConfirmationModal, Badge } from '../../../../components'
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_NAME,
  CAMPAIGN_BUTTONS,
  STEP,
  HM_OVERVIEW_BASE_PATH
} from '../../../../constants'
import * as campaignsActions from '../../../../services/redux/campaigns/actions'
import { getStepByIndex, getUrl, useActions } from '../../../CampaignWizard/utils'
import { ListItemContainer, ItemListButtons, FlexRow, Title, TitleLink, Link } from './styles'

const { H4, Text } = Typography
const { EDIT, COPY, REMOVE, CANCEL, ARCHIVE } = CAMPAIGN_BUTTONS

const buttons = {
  [CAMPAIGN_STATUS.DRAFT]: [EDIT, COPY, REMOVE],
  [CAMPAIGN_STATUS.IN_PROGRESS]: [COPY, CANCEL],
  [CAMPAIGN_STATUS.CANCELLED]: [COPY, REMOVE],
  [CAMPAIGN_STATUS.COMPLETED]: [COPY, ARCHIVE],
  [CAMPAIGN_STATUS.SCHEDULED]: [EDIT, COPY, CANCEL],
  [CAMPAIGN_STATUS.ARCHIVED]: []
}

function CampaignRow({ campaign }) {
  const history = useHistory()
  const {
    id,
    name,
    participants_count = 0,
    status,
    ui_start_date,
    ui_end_date,
    current_wizard_step
  } = campaign
  const { copyCampaign, deleteCampaign, cancelCampaign, archiveCampaign } =
    useActions(campaignsActions)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [actionOnCampaign, setActionOnCampaign] = useState(null)
  const step = getStepByIndex(current_wizard_step)

  const onToggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const onEdit = () => {
    history.push(getUrl(step, { id }))
  }

  const onCopy = () => copyCampaign({ id })

  const onRemove = () => {
    setActionOnCampaign('Delete')
    onToggleConfirmationModal()
  }

  const onCancel = () => {
    setActionOnCampaign('Cancel')
    onToggleConfirmationModal()
  }

  const onArchive = () => {
    setActionOnCampaign('Archive')
    onToggleConfirmationModal()
  }

  const executeActionOnCampaign = () => {
    switch (actionOnCampaign) {
      case 'Delete':
        deleteCampaign({ id, name })
        break
      case 'Archive':
        archiveCampaign(id)
        break
      case 'Cancel':
        cancelCampaign({ id })
        break
      default:
    }
  }

  const onConfirmUpdateCampaign = () => {
    executeActionOnCampaign()
    onToggleConfirmationModal()
  }

  const btnOptions = {
    [EDIT]: {
      icon: 'Pencil',
      onClick: onEdit,
      tooltipText: 'Edit campaign'
    },
    [COPY]: {
      icon: 'Copy',
      onClick: onCopy,
      tooltipText: 'Make a draft copy'
    },
    [REMOVE]: {
      icon: 'Trash',
      onClick: onRemove,
      tooltipText: 'Remove campaign'
    },
    [CANCEL]: {
      icon: 'Cancel',
      onClick: onCancel,
      tooltipText: 'Cancel campaign'
    },
    [ARCHIVE]: {
      icon: 'Database',
      onClick: onArchive,
      tooltipText: 'Archive campaign'
    }
  }

  const getSubText = () => {
    if (status === CAMPAIGN_STATUS.DRAFT)
      return (
        <>
          <Text color="300" fontWeight="normal">
            Completed {current_wizard_step + 1} of 5 steps.{' '}
          </Text>
          <Link to={`${getUrl(STEP.ROLL_OUT_UPDATE_SESSIONS, { id })}`}>
            <Text color="300" fontWeight="normal">
              Continue where you left off
            </Text>
          </Link>
        </>
      )
    if (status === CAMPAIGN_STATUS.SCHEDULED)
      return (
        <Text color="300" fontWeight="normal">
          Starting on {ui_start_date}
        </Text>
      )
    if (status === CAMPAIGN_STATUS.IN_PROGRESS)
      return (
        <Text color="300" fontWeight="normal">
          Started on {ui_start_date}
        </Text>
      )
    if (status === CAMPAIGN_STATUS.COMPLETED)
      return (
        <Text color="300" fontWeight="normal">
          Started from {ui_start_date} - {ui_end_date}
        </Text>
      )
    return null
  }

  const isCampaignForOverview = status !== null && status !== CAMPAIGN_STATUS.DRAFT

  const linkForTitle = !isCampaignForOverview
    ? getUrl(step, { id })
    : `${HM_OVERVIEW_BASE_PATH}/${id}`

  const ConfirmationText = (
    <div>
      You&apos;re about to {actionOnCampaign} <b>{name}</b> from your Campaigns list.
    </div>
  )

  return (
    <>
      <ListItemContainer data-id="hm-campaigns-row">
        <Layout item md={6}>
          <FlexRow>
            <Title>
              <TitleLink to={linkForTitle}>
                <H4 fontWeight="bold" color="link">
                  {name}
                </H4>
              </TitleLink>
            </Title>
            <Badge badgeColor={status} data-id={`hm-campaigns-row-status-${status}`}>
              {CAMPAIGN_STATUS_NAME[status]}
            </Badge>
          </FlexRow>
          {getSubText()}
        </Layout>
        <Layout item md={2}>
          <Layout container direction="row" justify="center" alignItems="center">
            <Layout>
              <H4 color="500" fontWeight="bold" style={{ marginBottom: '8px' }}>
                {participants_count.toLocaleString()}
              </H4>
              <Text color="300" fontWeight="normal">
                {participants_count !== 1 ? 'Participants' : 'Participant'}
              </Text>
            </Layout>
          </Layout>
        </Layout>
        <Layout item md={4}>
          <ItemListButtons container>
            {buttons[status] &&
              buttons[status].map((btn) => (
                <Button
                  theme="ghost"
                  size="sm"
                  type="buton"
                  onClick={btnOptions[btn].onClick}
                  data-icon-name={btnOptions[btn].icon}
                >
                  <Tooltip body={btnOptions[btn].tooltipText} placement="top" readOnly size="sm">
                    <Icon name={btnOptions[btn].icon} size="sm" />
                  </Tooltip>
                </Button>
              ))}
          </ItemListButtons>
        </Layout>
      </ListItemContainer>
      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          headerText={`${actionOnCampaign} Campaign`}
          bodyText={ConfirmationText}
          helpText="Are you sure you want to perform this action?"
          confirmText={`Yes, ${actionOnCampaign} Campaign`}
          dismissText={`No, Don't ${actionOnCampaign}`}
          onClose={onToggleConfirmationModal}
          onConfirm={onConfirmUpdateCampaign}
        />
      )}
    </>
  )
}

CampaignRow.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    ui_start_date: PropTypes.string,
    ui_end_date: PropTypes.string,
    participants_count: PropTypes.number,
    current_wizard_step: PropTypes.number
  }).isRequired
}

export default CampaignRow
