import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  // This is temp style for ButtonLink since original styling
  // is overriden due to bootstarp overlapping css
  a[class^='ButtonLink__'] {
    color: rgb(12, 125, 132) !important;
    :hover {
      text-decoration: underline !important;
      color: rgb(3, 155, 161) !important;
    }
  }
`
