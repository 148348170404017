import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ActionButton = styled(Link)`
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 10px;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  transform: scale(1);
  transition: transform 300ms;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: #0c7d84;
    border-color: #0c7d84;
    color: white;
    transform: scale(1.02) translateX(0);

    span {
      color: white;
    }
    svg {
      stroke: white;
      path {
        fill: white;
      }
    }
  }
`

export const ErrorWrapper = styled.div`
  margin-left: 12px;
`

export const WrapperSelectionLeads = styled.div`
  padding-top: 30px;
  .form-control {
    border-radius: 4px;
    input {
      height: 24px;
    }
  }
  .rbt-token {
    background-color: #b8e5e3;
    color: #34383c;
    border-radius: 2px;
    padding: 6px 25px 6px 12px;
    .rbt-token-remove-button {
      transform: scale(1.5);
      right: 4px;
      top: -4px;
      color: #035257;
    }
  }
  .rbt-input {
    border: 1px solid
      ${(props) => (props.invalid ? 'rgb(199, 52, 52)' : 'rgba(189, 197, 209, 0.5)')};
    &:hover {
      border: 1px solid rgba(58, 70, 85, 0.3);
    }
    &.focus {
      border: 1px solid rgb(12, 125, 132);
      box-shadow: rgb(12, 125, 132) 0px 0px 3px;
    }
  }
  .rbt-token-removeable {
    font-size: 13px;
  }
  .dropdown-menu {
    border: 0px solid #fff;
    border-radius: 4px;
    transition: border-color 250ms ease;
    margin: 0;
  }
  .dropdown-menu.show {
    border: 1px solid #dde1e7;
    box-shadow: 0 2px 8px 0 #dde1e7;
  }
`

export const WrapperAddedSessions = styled.div`
  margin-top: 30px;
  background: #f5f6f8;
  padding: 24px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  .text {
    margin-left: 10px;
    margin-right: auto;
  }
  .pencil {
    cursor: pointer;
  }
`
