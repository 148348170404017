import styled from 'styled-components'
import { FlexColumn } from '../../../../common.styles'

export const Content = styled(FlexColumn)`
  padding-bottom: 30px;

  > img {
    margin-bottom: 16px;
  }
  h3 {
    margin: auto;
    margin-bottom: 16px;
    border-bottom: 1px dotted #565d66;
  }
  > span {
    padding-top: 8px;
  }

  label {
    line-height: 24px;
    margin-bottom: 0;
  }

  [class^='Typography__Text'] {
    font-size: 16px;
  }
`
export const TextWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  padding-top: 4px;
  [class^='Typography__Text'] {
    font-size: 20px;
  }
`
export const WordSpacing = styled.span`
  padding: 0 4px;
`
