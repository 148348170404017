import React from 'react'
import { PropTypes } from 'prop-types'
import { Typography } from '@elevate_security/elevate-component-library'
import { Fill, Progress, CenterContent } from './styles'

const { Body } = Typography

function UploadProgressBar({ percentage }) {
  return (
    <CenterContent>
      <Progress>
        <Fill style={{ width: `${percentage}%` }} />
      </Progress>
      <Body color="300" margin="16px 0 0 0">{`${percentage}%`}</Body>
    </CenterContent>
  )
}

UploadProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired
}

export default UploadProgressBar
