import React from 'react'
import { CustomOptionWrapper } from './styles'
import { TARGETING_TYPES_STR, TARGETING_TYPES } from '../../../../constants'

export const helpText = {
  General: "We'll offer session scenarios geared toward employees in non-technical roles",
  Technical: "We'll offer session scenarios geared toward employees primarily in technical roles"
}

export const mapAudienceToOptions = (audiences) => {
  return audiences.map((tag) => ({
    ...tag,
    value: tag.name,
    helpText: helpText[tag.label] || ''
  }))
}

/**
 * The audience tags are kept in the same campaign property `tags`
 * so we have to do some magic here to properly load the selected
 * option by:
 * - finding the audience tag within the campaign tags property (there should be only one audience tag)
 * - Look into the mapped tags list to find the option by the tag name
 *
 * and set that as the value of the field.
 */
export const getSelectedAudienceFromTags = (tags, audienceOptions) => {
  const audienceTag = tags ? tags.filter((tag) => tag.includes('audience')).pop() : []
  return audienceOptions.find((tag) => tag.name === audienceTag)
}

/**
 * This function receives the list group from the global state
 * and return an object with the categories of groups and the groups
 * @param {array} groups - The groupList from the global store
 */
export const categorizeGroups = (groups) => {
  const categories = { 'Saved Groups': [] }

  groups.forEach((group) => {
    const { id, group_category, name, members_count } = group

    if (group_category) {
      if (!Object.keys(categories).includes(group_category)) {
        categories[group_category] = []
      }
      categories[group_category].push({ id, name, membersCount: members_count })
    } else {
      categories['Saved Groups'].push({ id, name, membersCount: members_count })
    }
  })
  return categories
}

export function RenderGroup(props) {
  // eslint-disable-next-line react/prop-types
  const { groupName, groupMembersCount } = props
  const pluralPipe = groupMembersCount === 0 || groupMembersCount > 1 ? 's' : ''
  return (
    <CustomOptionWrapper>
      <span>{groupName}</span>
      <span>{`${groupMembersCount} participant${pluralPipe}`}</span>
    </CustomOptionWrapper>
  )
}

export const getGroupCategories = (participants, employeeCount) => {
  const groupOptions = []
  const categories = categorizeGroups(participants)

  Object.keys(categories).forEach((key) => {
    if (key !== 'New Hires') {
      groupOptions.push({ group: key, render: key })
    }
    categories[key].forEach((group) => {
      if (key === 'New Hires') {
        groupOptions.unshift({
          value: group.id,
          label: 'New Hires',
          id: group.id,
          render: <RenderGroup groupName={group.name} groupMembersCount={group.membersCount} />
        })
      } else {
        groupOptions.push({
          value: group.id,
          label: group.name,
          id: group.id,
          render: <RenderGroup groupName={group.name} groupMembersCount={group.membersCount} />
        })
      }
    })
  })
  // Append the option Everyone and New Hire at the Beginning
  groupOptions.unshift({
    id: null,
    value: 'Everyone',
    label: 'Everyone',
    render: <RenderGroup groupName="Everyone" groupMembersCount={employeeCount} />
  })
  return groupOptions
}

export const renderTargetingTypeOptions = () => {
  return Object.keys(TARGETING_TYPES_STR).map((type) => ({
    label: TARGETING_TYPES_STR[type],
    value: TARGETING_TYPES[type]
  }))
}
