import React from 'react'
import { Icon, Tooltip } from '@elevate_security/elevate-component-library'
import PropTypes from 'prop-types'
import { IconsRow, IconWrapper } from './styles'

function ActionCell({ index, actions, onEdit, onDelete, onCopy, disabled }) {
  return (
    <IconsRow>
      {actions.includes('copy') && (
        <IconWrapper
          data-id={`hm-sessions-row-${index}-copy-action`}
          onClick={() => (onCopy && !disabled ? onCopy() : null)}
          style={{ marginRight: '15px' }}
          disabled={disabled}
        >
          <Tooltip body="Copy session link" placement="left" readOnly size="sm">
            <Icon name="Copy" size="xsm" />
          </Tooltip>
        </IconWrapper>
      )}
      {actions.includes('edit') && (
        <IconWrapper
          data-id={`hm-sessions-row-${index}-edit-action`}
          onClick={() => (onEdit && !disabled ? onEdit() : null)}
          style={{ marginRight: '15px' }}
          disabled={disabled}
        >
          <Tooltip body="Edit session" placement="left" readOnly size="sm">
            <Icon name="Pencil" size="xsm" />
          </Tooltip>
        </IconWrapper>
      )}
      {actions.includes('delete') && (
        <IconWrapper
          data-id={`hm-sessions-row-${index}-delete-action`}
          onClick={() => (onDelete && !disabled ? onDelete() : null)}
          disabled={disabled}
        >
          <Tooltip body="Delete session" placement="left" readOnly size="sm">
            <Icon name="Trash" size="xsm" />
          </Tooltip>
        </IconWrapper>
      )}
    </IconsRow>
  )
}

ActionCell.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.oneOf(['copy', 'edit', 'delete'])).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func
}

ActionCell.defaultProps = {
  disabled: false,
  onEdit: null,
  onDelete: null,
  onCopy: null
}

export default ActionCell
