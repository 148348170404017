import { STEP, WIZARD_MODAL_SIZE } from '../../../constants'
import { CampaignManager } from '../../campaign-manager'
import {
  SET_WIZARD_FORM,
  SET_WIZARD_STEP,
  SET_STEPS_SUBMITTED,
  AFTER_SUBMIT_WIZARD,
  WIZARD_FORM_INVALID,
  CAMPAIGN_LOADING,
  SET_WIZARD_CAMPAIGN,
  SET_CAMPAIGN_ROOMS,
  SET_CAMPAIGN_SESSIONS,
  SET_WIZARD_PROPS,
  SET_PARTICIPANT_GROUPS,
  SUBMIT_WIZARD_ERROR,
  SET_TAGS,
  UNLOAD_WIZARD,
  BEFORE_SUBMIT_WIZARD,
  SET_WIZARD_EMPLOYEE_COUNT,
  SET_WIZARD_ERROR,
  CLEAR_WIZARD_ERROR,
  SET_EMAIL_TEMPLATES,
  SET_CAMPAIGN_LEADS,
  SET_CAMPAIGN_TARGETS,
  SET_DYNAMICALLY_TARGETS,
  SET_ROLLOUT_TYPE
} from './types'

export const INITIAL_STATE = {
  isWizardOpen: false,
  /**
   * The step in which the wizard is currently in
   */
  wizardStep: STEP.CAMPAIGN_INFO,
  /**
   * THis property will be removed, don't use it.
   * @deprecated
   */
  stepsSubmitted: 0,
  campaign: new CampaignManager({}),
  participants: [],
  behaviors: [],
  audiences: [],
  employeeCount: 0,
  shortTagOrg: null,
  wizardForm: null,
  error: null,
  wizardProps: {
    size: WIZARD_MODAL_SIZE.MEDIUM
  },
  emailTemplates: null
}

export default function wizardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_WIZARD_PROPS:
      return {
        ...state,
        wizardProps: {
          ...state.wizardProps,
          ...action.data
        }
      }
    case SET_WIZARD_STEP:
      return {
        ...state,
        wizardStep: action.data
      }
    case SET_WIZARD_FORM:
    case WIZARD_FORM_INVALID:
      return {
        ...state,
        wizardForm: action.data,
        campaign: state.campaign.setBusy(false)
      }
    case SET_WIZARD_ERROR:
      return {
        ...state,
        error: action.data,
        campaign: state.campaign.setBusy(false)
      }
    case BEFORE_SUBMIT_WIZARD:
      return {
        ...state,
        campaign: state.campaign.new(state.campaign.data()).setBusy(true)
      }
    case SUBMIT_WIZARD_ERROR:
      return {
        ...state,
        error: action.data.error,
        wizardForm: action.data.form,
        campaign: state.campaign.setBusy(false)
      }
    case CLEAR_WIZARD_ERROR:
      return {
        ...state,
        error: null
      }
    case AFTER_SUBMIT_WIZARD:
      return {
        ...state,
        campaign: state.campaign.update(action.data.result).setBusy(false),
        wizardForm: action.data.form,
        error: null
      }
    case SET_STEPS_SUBMITTED:
      if (action.data > state.stepsSubmitted) {
        return {
          ...state,
          stepsSubmitted: action.data
        }
      }
      return state
    case CAMPAIGN_LOADING:
      return {
        ...state,
        campaign: state.campaign.setBusy(true)
      }
    case SET_WIZARD_CAMPAIGN:
      return {
        ...state,
        campaign: state.campaign.update(action.data).setBusy(false),
        error: null
      }
    case SET_CAMPAIGN_ROOMS:
      return {
        ...state,
        campaign: state.campaign.updateRooms(action.data.results)
      }
    case SET_CAMPAIGN_SESSIONS:
      return {
        ...state,
        campaign: state.campaign.updateSessions([
          ...state.campaign.getSessions(),
          ...action.data.results
        ])
      }
    case SET_CAMPAIGN_LEADS:
      return {
        ...state,
        campaign: state.campaign.updateLeads(action.data)
      }
    case SET_CAMPAIGN_TARGETS:
      return {
        ...state,
        campaign: state.campaign.updateTargets(action.data)
      }
    case SET_PARTICIPANT_GROUPS:
      return {
        ...state,
        participants: action.data
      }
    case SET_WIZARD_EMPLOYEE_COUNT:
      return {
        ...state,
        employeeCount: action.data
      }
    case SET_TAGS:
      return {
        ...state,
        behaviors: action.data.behaviors,
        audiences: action.data.audiences,
        shortTagOrg: action.data.shortTagOrg
      }
    case UNLOAD_WIZARD:
      return {
        ...state,
        wizardStep: STEP.CAMPAIGN_INFO,
        campaign: new CampaignManager({}),
        wizardForm: null,
        wizardProps: {
          size: WIZARD_MODAL_SIZE.MEDIUM
        },
        error: null
      }
    case SET_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.data
      }

    case SET_DYNAMICALLY_TARGETS:
      return {
        ...state,
        campaign: state.campaign.setDynamicallyTargets(action.data)
      }
    case SET_ROLLOUT_TYPE:
      return {
        ...state,
        campaign: state.campaign.setRollOutType(action.data)
      }
    default:
      return state
  }
}
