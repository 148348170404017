import { Link } from '@elevate_security/elevate-component-library'
import React from 'react'
import { customerSuccessLink } from '../../../../../constants'
import { WrapperCard, InstructionsContainer } from '../styles'
import { downloadSampleCsv, goToCustomerSuccessPage } from '../utils'

function UploadInstructions() {
  return (
    <InstructionsContainer data-id="hm-sessions-upload-instructions">
      <WrapperCard header="Need help uploading sessions?" headerWeight="bold">
        Start by&nbsp;
        <Link href="./#" onClick={downloadSampleCsv}>
          downloading our demo CSV file
        </Link>
        . Make sure all the column headers remain the <br />
        same! If you feel lost, don&apos;t hesitate to contact our{' '}
        <Link to={customerSuccessLink} onClick={goToCustomerSuccessPage}>
          Customer Success
        </Link>{' '}
        team.
      </WrapperCard>
    </InstructionsContainer>
  )
}

export default UploadInstructions
