import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  Link,
  Icon,
  Tooltip
} from '@elevate_security/elevate-component-library'
import { copyToClipboard, parseCreatedForUI } from '../../../../../services/utils'
import { FlexItem, FlexRow } from '../../../../../common.styles'
import { ActionsContainer, RowNumber, SessionContainer } from './styles'

const { Text } = Typography

function Session({ index, id, created, leader, total_played, total_completed }) {
  const [copied, setCopied] = useState(false)

  const copySession = () => {
    if (!copied) {
      copyToClipboard(`${window.location.origin}/hackersmind/${id}`)
      setCopied(true)
      setTimeout(() => setCopied(false), 4000)
    }
  }

  return (
    <SessionContainer key={id} width="100%">
      <FlexRow stretch alignItems="center">
        <FlexItem width="30px;">
          <RowNumber># {index + 1}</RowNumber>
        </FlexItem>
        <FlexItem width="420px;" grow={1}>
          <Link href={`/hackersmind/${id}`}>Hacker’s Mind Session Link</Link>
          <br />
          <Text>{`Leader: ${leader || 'N/A'}`}</Text>
          <br />
          <small>
            {`This session was completed by ${total_completed}/${total_played} players`}
          </small>
        </FlexItem>
        <FlexItem width="180px;" align="center" justify="center" height="100%">
          <Text>{`Created: ${parseCreatedForUI({ date: created })}`}</Text>
        </FlexItem>
        <ActionsContainer shrink={1}>
          <Button theme="ghost" disabled={copied} onClick={copySession}>
            <Tooltip body="Copy session link" placement="top" readOnly size="sm">
              {!copied && <Icon name="Copy" size="sm" />}
              {copied && <Icon name="Check" size="sm" />}
            </Tooltip>
          </Button>
        </ActionsContainer>
      </FlexRow>
    </SessionContainer>
  )
}

Session.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  leader: PropTypes.string.isRequired,
  total_played: PropTypes.string.isRequired,
  total_completed: PropTypes.string.isRequired
}

export default Session
