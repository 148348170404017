import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { RadioButton } from '@elevate_security/elevate-component-library'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { ActivityIndicator, EmployeesMenu, InputErrors } from '../../../../components'
import { STEP } from '../../../../constants'
import { getOrganizationId, wizardSelector } from '../../../../services/redux/selectors'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { StyledAsyncTypeAhead } from '../../../../components/StyledAsyncTypeAhead'
import { mapToId } from '../../../../services/utils'
import { RouteContainer, Form, FieldSet, Label } from '../../styles'
import { useActions } from '../../utils'
import { withForm } from '../../with-form'
import { withStepManager } from '../../with-step'
import { getParticipantsForm, HM_PARTICIPANTS_FORM_NAME } from './form'

function Participants(props) {
  const { ready, entering, handleChange } = props
  const organizationId = useSelector(getOrganizationId)
  const { wizardForm, campaign, participants, audiences, employeeCount } =
    useSelector(wizardSelector)
  const { getGroups, getTags, setWizardForm, getEmployeeCount } = useActions(wizardActions)
  const [loading, setLoading] = useState({ groups: false, tags: false })
  const [filteredGroups, setFilteredGroups] = useState(participants)
  const [selectedGroups, setSelectedGroups] = useState(null)

  /**
   * Loaded groups so we can have the participants list if it hasn't been
   * loaded yet.
   */
  useEffect(() => {
    if ((!participants || !participants.length) && !loading.groups) {
      getGroups()
      getEmployeeCount()
      setLoading({ ...loading, groups: true })
    }

    if ((!audiences || !audiences.length) && !loading.tags) {
      getTags()
      setLoading({ ...loading, tags: true })
    }
  }, [])

  /**
   * If we have the participants list, instantiate the form, otherwise
   * keep waiting until we do.
   */
  useEffect(() => {
    if (
      (participants || []).length > 0 &&
      (audiences || []).length > 0 &&
      campaign.id() &&
      !ready
    ) {
      setLoading({ tags: false, groups: false })
      setWizardForm(
        getParticipantsForm(campaign, audiences, participants, employeeCount, organizationId)
      )
    }
  }, [participants, audiences])

  /**
   * Deffer if not ready
   */
  if (!ready || loading.tags || loading.groups || campaign.busy) {
    return <ActivityIndicator active />
  }

  /**
   * Once the form has been loaded and it's ready, grab the
   * fields here so you can render your inputs based on
   * their properties, so information is always in one place.
   */
  const { tags, groups } = wizardForm.get()

  if (!selectedGroups && groups && groups.value && groups.value.length) {
    setSelectedGroups(groups.value)
  }

  const searchGroups = (query) => {
    if (query) {
      const selectedGroupsIds = mapToId(selectedGroups || [])
      const searchedGroups = participants.filter(
        (participant) =>
          participant.name.toLowerCase().startsWith(query.toLowerCase()) &&
          !selectedGroupsIds.includes(participant.id)
      )
      setFilteredGroups(searchedGroups)
    } else {
      setFilteredGroups(participants)
    }
  }

  /**
   * Here we just send the array of groups being selected
   * The callback in the form before send the patch is responsible
   * by mapping it to a new array of groupIds
   */
  const handleSelectGroups = (groupsSelected) => {
    setSelectedGroups(groupsSelected)
    handleChange('groups', { value: groupsSelected })
  }

  return (
    <CSSTransition in={entering} classNames="scale" timeout={500}>
      <RouteContainer>
        <Form data-id="hm-participants-form">
          <div data-id="hm-participants-audience">
            <Label>Primary Audience</Label>
            {tags.options.length > 0 && (
              <RadioButton
                radioSelected={tags.value || tags.defaultValue}
                options={tags.options}
                onChange={(value) => handleChange('tags', { value })}
              />
            )}
          </div>
          <br />
          <br />
          <FieldSet data-id="hm-participants-groups">
            <Label>Participating Groups</Label>
            <StyledAsyncTypeAhead
              multiple
              selectHintOnEnter
              placeholder="Search..."
              labelKey="name"
              selected={groups.value || []}
              options={filteredGroups}
              onSearch={searchGroups}
              onChange={handleSelectGroups}
              labels={['name']}
              ItemComponent={EmployeesMenu}
            />
            {groups && <InputErrors errors={groups.errors} relative />}
          </FieldSet>
        </Form>
      </RouteContainer>
    </CSSTransition>
  )
}

Participants.propTypes = {
  handleChange: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  entering: PropTypes.bool.isRequired
}

export default withStepManager(withForm(Participants), STEP.PARTICIPANTS, HM_PARTICIPANTS_FORM_NAME)
