import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography } from '@elevate_security/elevate-component-library'
import { useHistory, useParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { wizardSelector } from '../../../../services/redux/selectors'
import { STEP, WIZARD_MODAL_SIZE } from '../../../../constants'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import * as api from '../../../../services/redux/wizard/api'
import ToastManager from '../../../../services/toast-manager'
import { FieldSet, Label, RouteContainer } from '../../styles'
import { withStepManager } from '../../with-step'
import { ModalHeader } from '../CampaignSessions/styles'
import { getUrl, useActions } from '../../utils'
import { ButtonsWrapper, Container, GoToCampaignSessions, FileUploadContainer } from './styles'
import FileUpload from './FileUpload'
import { ActivityIndicator, CampaignToasts, IconLink } from '../../../../components'
import UploadErrors from './UploadErrors'
import UploadInstructions from './UploadInstructions'
import { extractErrorsUploadingRooms, getErrorMessage } from './utils'

const { H2 } = Typography

function CampaignSessionsUpload(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const { ready, entering, wizardView, onGoToCampaignSessions } = props
  const { wizardProps, campaign } = useSelector(wizardSelector)
  const { setWizardModalProps } = useActions(wizardActions)
  const [toastManager, setToastManager] = useState(new ToastManager([]))
  const [isProcessing, setIsProcessing] = useState(false)
  const [progress, setProgress] = useState(10)
  const [acceptedFile, setAcceptedFile] = useState(null)
  const [rejectedFile, setRejectedFile] = useState(null)
  const [uploadFail, setUploadFail] = useState(false)
  const [errorsUploading, setErrorsUploading] = useState(null)
  const campaignSessionsUrl = getUrl(STEP.ROLL_OUT_UPDATE_SESSIONS, { id })
  const rollOutTypeUrl = getUrl(STEP.ROLL_OUT_TYPE, { id })

  useEffect(() => {
    if (wizardView && wizardProps.size !== WIZARD_MODAL_SIZE.FULL_SCREEN) {
      dispatch(setWizardModalProps({ size: WIZARD_MODAL_SIZE.FULL_SCREEN }))
    }
  }, [])

  useEffect(() => {
    if (uploadFail) {
      setToastManager(
        toastManager.reset().add({
          id: 'upload-validation-errors',
          children: (
            <UploadErrors
              errors={errorsUploading}
              message={getErrorMessage(rejectedFile, uploadFail)}
            />
          ),
          level: 'error'
        })
      )
    }
  }, [uploadFail])

  const processFiles = async () => {
    setIsProcessing(true)
    setErrorsUploading(null)
    setUploadFail(false)
    setRejectedFile(null)

    const { data, status, message } = await api.uploadRooms(acceptedFile, campaign.properties.id)

    setIsProcessing(false)
    setProgress(0)

    if (status === 200) {
      setProgress(100)
      setAcceptedFile(null)
      return true
    }

    if (status === 400) {
      setErrorsUploading(extractErrorsUploadingRooms(data.Errors))
      setUploadFail(true)
      return false
    }

    setToastManager(
      toastManager.reset().add({
        id: `upload-${status}-error`,
        message: uploadFail || rejectedFile ? getErrorMessage(rejectedFile, uploadFail) : message,
        level: 'error'
      })
    )

    return false
  }

  const handleUpload = async (e) => {
    e.preventDefault()
    e.preventDefault()
    if (acceptedFile !== null) {
      const success = await processFiles()

      if (success) {
        if (!wizardView && onGoToCampaignSessions) onGoToCampaignSessions()
        else history.push(`${campaignSessionsUrl}?uploadSucess`)
      }
    }
  }

  if (wizardView && !ready) {
    return <ActivityIndicator active />
  }

  const isInProgress = isProcessing && progress < 100

  // eslint-disable-next-line react/no-unstable-nested-components
  function UploadButton() {
    return (
      <Button onClick={handleUpload} disabled={!acceptedFile || isInProgress}>
        {isProcessing ? 'Uploading...' : 'Upload CSV File'}
      </Button>
    )
  }

  return (
    <CSSTransition in={entering} classNames="scale" timeout={500}>
      <RouteContainer
        data-id="hm-sessions-upload"
        padding="0%"
        align="center"
        size={WIZARD_MODAL_SIZE.FULL_SCREEN}
      >
        {wizardView && (
          <ModalHeader>
            <H2 color="900" fontWeight="bold">
              Add campaign sessions
            </H2>
            <ButtonsWrapper data-id="hm-sessions-upload-buttons">
              <Button
                to={rollOutTypeUrl}
                theme="ghost"
                style={{ marginRight: '5px' }}
                disabled={isInProgress}
              >
                Cancel
              </Button>
              <UploadButton />
            </ButtonsWrapper>
          </ModalHeader>
        )}
        <CampaignToasts
          onRemove={(toast) => setToastManager(toastManager.remove(toast))}
          messages={toastManager.get()}
        />
        <Container>
          <GoToCampaignSessions>
            <IconLink
              onClick={() =>
                wizardView ? history.push(campaignSessionsUrl) : onGoToCampaignSessions()
              }
              text="Go to list of campaign sessions"
              fontWeight="bold"
              disabled={isProcessing}
              iconName="List"
              iconSize="sm"
            />
            {!wizardView && <UploadButton />}
          </GoToCampaignSessions>
          <UploadInstructions />
          <FileUploadContainer>
            <FieldSet>
              <Label>Upload Sessions (.csv only)</Label>
              <FileUpload
                isProcessing={isProcessing}
                progress={progress}
                updateFile={(fileList) => setAcceptedFile((fileList && fileList[0]) || null)}
                updateRejectedFile={(fileList) =>
                  setRejectedFile((fileList && fileList[0]) || null)
                }
                rejectedFile={rejectedFile}
                acceptedFile={acceptedFile}
                uploadFail={uploadFail}
              />
            </FieldSet>
          </FileUploadContainer>
        </Container>
      </RouteContainer>
    </CSSTransition>
  )
}

CampaignSessionsUpload.propTypes = {
  ready: PropTypes.bool.isRequired,
  entering: PropTypes.bool.isRequired,
  wizardView: PropTypes.bool,
  onGoToCampaignSessions: PropTypes.func
}

CampaignSessionsUpload.defaultProps = {
  wizardView: true,
  onGoToCampaignSessions: () => false
}

export default withStepManager(CampaignSessionsUpload, STEP.ROLL_OUT_SESSIONS_UPLOAD)
