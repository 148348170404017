import axios from '../axios-config'
import { METHOD } from '../../api'

export const fetchCampaignReport = ({ campaignId }) =>
  axios({
    method: METHOD.GET,
    url: `/hackersmindcampaigns/${campaignId}/campaign_report/`
  })

export const fetchCampaignScheduleLinks = ({ campaignId }) =>
  axios({
    method: METHOD.GET,
    url: `/hackersmindcampaigns/${campaignId}/schedule/`
  })

export const fetchCampaignStatistics = ({ campaignId }) =>
  axios({
    method: METHOD.GET,
    url: `/hackersmindcampaigns/${campaignId}/statistics/`
  })
