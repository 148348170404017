import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import {
  CustomSelect,
  Input,
  Typography,
  Button,
  TimezonePicker
} from '@elevate_security/elevate-component-library'
import { useSelector } from 'react-redux'
import { ActivityIndicator, DatePicker, InputErrors } from '../../../../../../components'
import { FlexRow } from '../../../../../../common.styles'
import { FormManager } from '../../../../../../services/form'
import { CAMPAIGN_STATUS } from '../../../../../../constants'
import * as wizardActions from '../../../../../../services/redux/wizard/actions'
import { getDefaultTimezoneOption } from '../../../../../../services/redux/selectors'
import { FieldSet } from '../../../../styles'
import { formOutOfSync, useActions } from '../../../../utils'
import { RowPropTypes } from '../../types'
import { columnDefs } from '../Sessions/utils'
import { getSessionForm, getDatesValidations, HM_SESSION_FORM_NAME } from './form'
import {
  RowFormBackdrop,
  RowFormContainer,
  SessionFormRow,
  TypeAheadInput,
  CustomLabel,
  SessionFormHeader,
  SessionFooterDivider,
  ColumnFormContainer
} from './styles'
import { getDatesRange, getFieldSetWidth, getMinMaxDateTime } from './utils'

const { H2 } = Typography

function SessionForm({ form, row, loading, onSave, onCancel, onForm, modal, campaign, status }) {
  const { searchEmployees } = useActions(wizardActions)
  const [ready, setReady] = useState(false)
  const [currentForm, setCurrentForm] = useState(form)
  const companyDefaultTimezone = useSelector(getDefaultTimezoneOption)

  const isNotDraft = status && status.length && status !== CAMPAIGN_STATUS.DRAFT

  useEffect(() => {
    if (formOutOfSync(row && row.id, HM_SESSION_FORM_NAME, form && form.value('id'), form)) {
      setReady(false)
      onForm(getSessionForm(row, columnDefs, companyDefaultTimezone))
    } else if (!ready) {
      setCurrentForm(form)
      setReady(true)
    } else if (form !== currentForm) {
      setCurrentForm(form)
    }
  }, [form])

  useEffect(() => {
    if (currentForm && isNotDraft && campaign && campaign.start_date) {
      const { start_time } = currentForm.get()
      if (start_time && start_time.validations.length === 2)
        setCurrentForm(updateValidations(currentForm, true))
    }
  }, [campaign, currentForm])

  const updateValidations = (sessionForm, skipValidation = false) => {
    const { start_time, end_time, timezone } = sessionForm.get()

    const validations = getDatesValidations(
      {
        start_time: start_time.value,
        timezone: timezone.value
      },
      isNotDraft ? campaign.start_date : null,
      isNotDraft ? campaign.end_date : null
    )

    return sessionForm
      .update(
        'start_time',
        {
          ...start_time,
          validations: validations.start_time
        },
        skipValidation || (!start_time.value && !start_time.dirty)
      )
      .update(
        'end_time',
        {
          ...end_time,
          validations: validations.end_time
        },
        skipValidation
      )
  }

  const handleChange = (name, value) => {
    if (name === 'timezone') {
      setCurrentForm(updateValidations(currentForm.updateValue(name, value)))
    } else if (name === 'start_time') {
      const updatedForm = updateValidations(
        currentForm
          .updateValue(name, value)
          // set end_time value 1 hour ahead
          .updateValue('end_time', {
            value: Moment(value.value).add(1, 'hour').format().slice(0, 16)
          })
      )
      setCurrentForm(updatedForm)
    } else if (name === 'room_size' && value.value > 0) {
      setCurrentForm(currentForm.updateValue(name, value))
    } else if (name !== 'room_size') {
      setCurrentForm(currentForm.updateValue(name, value))
    }
  }

  const onLeaderSearch = (text) => {
    searchEmployees({
      text,
      cb: (data) => setCurrentForm(currentForm.update('leader', { options: data }))
    })
  }

  if (!ready) {
    return <ActivityIndicator active />
  }

  const { location, room, room_size, start_time, end_time, timezone, leader, dial_in, language } =
    currentForm.get()
  const values = currentForm.value()
  const FieldsWrapper = modal ? SessionFormRow : Fragment

  const fieldSetWidth = getFieldSetWidth(modal)

  const [startAfter, startBefore, endAfter, endBefore] = getDatesRange({
    startDate: start_time.value,
    endDate: end_time.value,
    timezone: timezone.value,
    campaignStart: isNotDraft && campaign.start_date ? Moment(campaign.start_date) : null,
    campaignEnd: isNotDraft && campaign.end_date ? Moment(campaign.end_date) : null
  })

  const startDateRange = getMinMaxDateTime(startAfter, startBefore)
  const endDateRange = getMinMaxDateTime(endAfter, endBefore)
  const identifier = (name) => `hm-sessions-${modal ? 'modal-' : ''}form-${name}`

  const renderForm = () => (
    <>
      {modal && (
        <SessionFormHeader data-id={identifier('header')}>
          <H2 color="900" fontWeight="bold">
            {values.id ? 'Edit Session Details' : 'Create Session'}
          </H2>
        </SessionFormHeader>
      )}
      <FieldsWrapper data-id="hm-sessions-form">
        <FieldSet
          width={fieldSetWidth.location}
          data-id={identifier('location')}
          className="hm-session-fieldset"
        >
          <CustomLabel>{location.label}</CustomLabel>
          <Input
            autoFocus
            width="full"
            value={values.location}
            required={location.dirty && currentForm.isRequired('location')}
            invalid={!location.valid}
            onChange={(value) => handleChange('location', { value })}
          />
          {!location.valid && <InputErrors errors={location.errors} />}
        </FieldSet>
        <FieldSet
          width={fieldSetWidth.room}
          data-id={identifier('room')}
          className="hm-session-fieldset"
        >
          <CustomLabel>{room.label}</CustomLabel>
          <Input
            width="full"
            value={values.room}
            required={room.dirty && currentForm.isRequired('room')}
            invalid={!room.valid}
            onChange={(value) => handleChange('room', { value })}
          />
          {!room.valid && <InputErrors errors={room.errors} />}
        </FieldSet>
        <FieldSet
          width={fieldSetWidth.room_size}
          data-id={identifier('room-size')}
          className="hm-session-fieldset"
        >
          <CustomLabel>{room_size.label}</CustomLabel>
          <Input
            width="full"
            type={room_size.type}
            value={values.room_size}
            required
            invalid={!room_size.valid}
            onChange={(value) => handleChange('room_size', { value })}
          />
          {!room_size.valid && <InputErrors errors={room_size.errors} />}
        </FieldSet>
      </FieldsWrapper>
      <FieldsWrapper>
        <FieldSet
          width={fieldSetWidth.start_time}
          data-id={identifier('start-time')}
          className="hm-session-fieldset"
        >
          <CustomLabel>{start_time.label}</CustomLabel>
          <DatePicker
            value={values.start_time}
            onChange={(value) => handleChange('start_time', value)}
            invalid={!start_time.valid}
            {...startDateRange}
          />
          {!start_time.valid && <InputErrors errors={start_time.errors} />}
        </FieldSet>
        <FieldSet
          width={fieldSetWidth.end_time}
          data-id={identifier('end-time')}
          className="hm-session-fieldset"
        >
          <CustomLabel>{end_time.label}</CustomLabel>
          <DatePicker
            value={values.end_time}
            onChange={(value) => handleChange('end_time', value)}
            invalid={!end_time.valid}
            {...endDateRange}
          />
          {!end_time.valid && <InputErrors errors={end_time.errors} />}
        </FieldSet>
        <FieldSet
          width={fieldSetWidth.timezone}
          data-id={identifier('timezone')}
          className="hm-session-fieldset"
        >
          <CustomLabel>{timezone.label}</CustomLabel>
          <TimezonePicker
            placeholder="Select a timezone"
            isSearch={timezone.isSearch}
            defaultValue={values.timezone}
            onChange={(value) => handleChange('timezone', { value })}
            dropdownWidth="auto"
            width="100%"
            invalid={!timezone.valid}
          />
          {!timezone.valid && <InputErrors errors={timezone.errors} />}
        </FieldSet>
      </FieldsWrapper>
      <FieldsWrapper>
        <FieldSet
          width={fieldSetWidth.leader}
          data-id={identifier('leader')}
          className="hm-session-fieldset"
        >
          <CustomLabel>{leader.label} (Optional)</CustomLabel>
          <TypeAheadInput
            {...leader.props}
            defaultSelected={values.leader}
            options={leader.options}
            onSearch={onLeaderSearch}
            dropdownPosition={modal ? 'top' : 'bottom'}
            onChange={(value) =>
              handleChange('leader', { value: value.map((employee) => employee.email) })
            }
          />
          {!leader.valid && <InputErrors errors={leader.errors} />}
        </FieldSet>
        <FieldSet
          width={fieldSetWidth.dial_in}
          data-id={identifier('dial-in')}
          className="hm-session-fieldset"
        >
          <CustomLabel>{dial_in.label} (Optional)</CustomLabel>
          <Input
            width="full"
            value={values.dial_in}
            required={dial_in.dirty && currentForm.isRequired('dial_in')}
            invalid={!dial_in.valid}
            onChange={(value) => handleChange('dial_in', { value })}
          />
          {!dial_in.valid && <InputErrors errors={dial_in.errors} />}
        </FieldSet>
        <FieldSet
          width={fieldSetWidth.language}
          data-id={identifier('language')}
          className="hm-session-fieldset"
        >
          <CustomLabel>{language.label}</CustomLabel>
          <CustomSelect
            style={{ width: '100%' }}
            options={language.options}
            defaultValue={values.language || language.options[0]}
            onChange={({ value }) => handleChange('language', { value })}
          />
          {!language.valid && <InputErrors errors={language.errors} />}
        </FieldSet>
      </FieldsWrapper>
      {!modal && (
        <>
          <FieldSet
            width={fieldSetWidth.button}
            style={{ marginRight: '24px' }}
            className="hm-session-fieldset"
          >
            <CustomLabel>&nbsp;</CustomLabel>
            <Button
              data-id={identifier('submit')}
              disabled={loading || !currentForm.valid() || !currentForm.isDirty()}
              onClick={() => onSave(currentForm)}
            >
              Add Session
            </Button>
          </FieldSet>
          {/* hidden fieldset placeholders to make fields expand equally inside flex */}
          {Array(8)
            .fill('')
            .map(() => (
              <div className="hm-session-fieldset hidden-fieldset" />
            ))}
        </>
      )}
      {modal && <SessionFooterDivider />}
      {modal && (
        <FlexRow stretch grow={0} gap="10px" justify="flex-end">
          <Button
            data-id={identifier('cancel')}
            disabled={loading}
            theme="ghost"
            onClick={() => onCancel(currentForm)}
          >
            Cancel
          </Button>
          <Button
            data-id={identifier('submit')}
            disabled={loading}
            onClick={() => onSave(currentForm)}
          >
            Save Changes
          </Button>
        </FlexRow>
      )}
      {loading && <ActivityIndicator active name="CampaignSessionsForm" />}
    </>
  )

  return (
    <RowFormBackdrop
      data-id={`hm-sessions-${modal ? 'modal-' : ''}form`}
      justify="center"
      align="center"
      modal={modal}
    >
      {modal && (
        <ColumnFormContainer grow={0} shrink={1} modal>
          {renderForm()}
        </ColumnFormContainer>
      )}
      {!modal && (
        <RowFormContainer grow={1} shrink={0} gap="24px" wrap="wrap">
          {renderForm()}
        </RowFormContainer>
      )}
    </RowFormBackdrop>
  )
}

SessionForm.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.shape({}),
  row: RowPropTypes,
  form: PropTypes.instanceOf(FormManager),
  modal: PropTypes.bool,
  campaign: PropTypes.shape({
    end_date: PropTypes.string,
    start_date: PropTypes.string,
    timezone: PropTypes.string
  }),
  status: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onForm: PropTypes.func
}

SessionForm.defaultProps = {
  loading: false,
  errors: {},
  modal: false,
  form: null,
  row: null,
  campaign: {},
  status: null,
  onSave: () => null,
  onCancel: () => null,
  onForm: () => null
}

export default SessionForm
