import union from 'lodash/union'
import { CAMPAIGN_STATUS } from '../../../../constants'
import { FormManager } from '../../../../services/form'
import { ControlType } from '../../../../services/form.types'
import { Constrain } from '../../../../services/validator'

export const HM_CAMPAIGN_INFO_FORM_NAME = 'campaign-info-form'

/**
 * Helper function to map bahaviours comming from the api
 * to a format that the toggle button can work with.
 *
 * @example
 * {
 *   [behaviourName]: { ...behaviour }
 *   [otherBehaviourName]: { ...behaviour }
 * }
 *
 * @param behaviors The list of behaviour objects
 * @param value The tags field value
 * @returns {*}
 */
export function mapBehavioursToOptions(behaviors, value) {
  return behaviors.reduce(
    (map, tag) => ({
      ...map,
      [tag.name]: {
        ...tag,
        checked: (value || []).indexOf(tag.name) > -1 || false
      }
    }),
    {}
  )
}

/**
 * Helper function to conditionally add and remove the tags
 * field validation depending if the campaign is in short mode or not.
 *
 * @param definition The tags field definition
 * @param isShort
 * @returns {{validations: []}|{validations: [{test: function(Object, Object): boolean, message: string, value: number}]}}
 */
export function toggleTagsValidation(definition, isShort) {
  if (isShort) {
    return {
      ...definition,
      validations: []
    }
  }
  return {
    ...definition,
    validations: [
      {
        test: Constrain.MIN_SELECTIONS,
        value: 1,
        message: 'Select at least a behavior'
      }
    ]
  }
}

export const LANGUAGES = [
  { value: 'en-US', label: 'English' },
  { value: 'fr-FR', label: 'French' },
  { value: 'de-DE', label: 'German' },
  { value: 'es-ES', label: 'Spanish' }
]

export const getCampaignInfoForm = (organizationId, campaign, behaviors, shortTagOrg) => {
  /**
   * Because behavior and audience tags are kept in the same campaign property, we
   * must filter out the audience tags here so our behavior toggle input functions
   * and validates properly.
   *
   * This values are consolidated using the before submit hook in the
   * CampaignInfo component.
   */

  const selectedBehaviors = campaign.getTags().filter((tag) => tag.includes('behavior'))
  const selectedShortSession = shortTagOrg
    ? campaign.getTags().filter((tag) => tag.includes(shortTagOrg.name))
    : []

  return (
    new FormManager(
      HM_CAMPAIGN_INFO_FORM_NAME,
      {
        id: {
          type: ControlType.HIDDEN,
          dirty: true
        },
        organization: {
          type: ControlType.HIDDEN
        },
        status: {
          type: ControlType.HIDDEN
        },
        name: {
          type: ControlType.TEXT,
          validations: [
            { test: Constrain.REQUIRED, value: true, message: 'Campaign should have a name' }
          ]
        },
        tags: toggleTagsValidation(
          {
            type: ControlType.TOGGLE,
            options: mapBehavioursToOptions(behaviors, selectedBehaviors),
            validations: []
          },
          selectedShortSession
        ),
        language: {
          defaultValue: LANGUAGES[0].value,
          options: LANGUAGES,
          type: ControlType.SELECT,
          validations: [
            { test: Constrain.REQUIRED, value: true, message: 'A language selection is required' }
          ]
        },
        short: {
          type: ControlType.TOGGLE,
          name: shortTagOrg.name
        },
        campaign_type: {
          type: ControlType.TEXT,
          validations: [
            {
              test: Constrain.REQUIRED,
              value: true,
              message: 'Must specify nature of campaign'
            }
          ]
        }
      },
      {
        ...campaign.data(),
        tags: selectedBehaviors,
        short: selectedShortSession,
        organization: organizationId,
        status: campaign.getStatus() || CAMPAIGN_STATUS.DRAFT
      }
    )
      /**
       * Because we have separate inputs to represent different types of tags
       * from the same campaign property, we must consolidate tags from behaviors
       * and short session back into the tags array and remove short since it
       * is not needed in the final payload.
       */
      .addBeforeSubmitCallBack((form) => {
        const definition = form.get()
        const { tags, short, language, ...otherFields } = definition
        const scopeTags = Object.keys(tags.options)
        const otherTags = campaign.getTags().filter((tag) => scopeTags.indexOf(tag) === -1)

        tags.value = union(otherTags, form.value('tags') || [])

        if (short.value && short.value.length) {
          tags.value = short.value
        } else {
          tags.value = tags.value.filter((tag) => tag !== shortTagOrg.name)
        }

        language.value = !language.value ? language.defaultValue : language.value
        return form.new(
          {
            ...otherFields,
            language,
            tags
          },
          true
        )
      })
  )
}
