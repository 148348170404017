/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@elevate_security/elevate-component-library/dist/Button'
import Header from '@elevate_security/elevate-component-library/dist/Header'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import FilterByYear from '@src/scenes/Campaigns/components/FilterByYear'
import { ActivityIndicator, CampaignToasts } from '../../components'
import * as campaignsActions from '../../services/redux/campaigns/actions'
import { campaignsSelector } from '../../services/redux/selectors'
import { CAMPAIGN_STATUS_NAME, HM_CAMPAIGNS_BASE_PATH, STEP } from '../../constants'
import CampaignWizard from '../CampaignWizard'
import { useActions } from '../CampaignWizard/utils'
import { CampaignListContainer, EmptyListMessage, FlexRow, HeaderWrapper } from './styles'
import { CampaignRow, Filter } from './components'
import PageHeader from '../../components/PageHeader'

function Campaigns() {
  const location = useLocation()
  const history = useHistory()
  const { setCurrentTab, getCampaignList, setCampaignStatuses } = useActions(campaignsActions)
  const [campaignList, setCampaignList] = useState([])
  const [loading, setLoading] = useState(false)
  const { campaignListFiltered, currentTab, campaignStatuses, campaignMessages } =
    useSelector(campaignsSelector)

  useEffect(() => {
    setCampaignStatuses()
    getCampaignList({ filter: `status=${currentTab}` })
  }, [])

  useEffect(() => {
    const key = CAMPAIGN_STATUS_NAME[currentTab]

    if (campaignListFiltered && campaignListFiltered[key]) {
      setCampaignList(campaignListFiltered[key])
      setLoading(false)
    }
  }, [campaignListFiltered])

  useEffect(() => {
    if (location.pathname === HM_CAMPAIGNS_BASE_PATH && !loading) {
      getCampaignList({ filter: `status=${currentTab}` })
      setLoading(true)
    }
  }, [location])

  const onTabChange = (tab) => {
    setCurrentTab(tab)
    setCampaignList([])
    setLoading(true)
    getCampaignList({ filter: `status=${tab}` })
  }

  const breadcrumbLinks = [
    {
      label: 'Hacker’s Mind',
      link: HM_CAMPAIGNS_BASE_PATH
    },
    {
      label: 'Campaigns',
      link: HM_CAMPAIGNS_BASE_PATH
    }
  ]

  return (
    <div>
      <CampaignToasts messages={campaignMessages} fixed width="60%" />
      <HeaderWrapper>
        <Header>
          <PageHeader title="Campaigns" breadcrumbLinks={breadcrumbLinks} />
          <FlexRow>
            <Button
              data-id="hm-new-campaign-button"
              onClick={() => history.push(`${HM_CAMPAIGNS_BASE_PATH}/new-campaign`)}
            >
              Create New Campaign
            </Button>
            <FilterByYear />
          </FlexRow>
        </Header>
      </HeaderWrapper>
      <Filter
        active={loading}
        currentTab={currentTab}
        onChange={(tab) => onTabChange(tab)}
        campaignStatuses={campaignStatuses}
      />
      <br />
      <CampaignListContainer data-id="hm-campaigns-list">
        {loading ? (
          <ActivityIndicator active={loading} />
        ) : campaignList && campaignList.length ? (
          campaignList.map((campaign) => <CampaignRow campaign={campaign} />)
        ) : (
          <EmptyListMessage>No campaigns found.</EmptyListMessage>
        )}
      </CampaignListContainer>
      {!loading && (
        <Switch>
          <Route
            path={[`${HM_CAMPAIGNS_BASE_PATH}/new-campaign`, STEP.CAMPAIGN_INFO]}
            component={CampaignWizard}
          />
        </Switch>
      )}
    </div>
  )
}

export default Campaigns
