import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { ActivityIndicator } from '../../../../components'
import WysiwygEditor from '../../../../components/WysiwygEditor'
import { STEP, WIZARD_MODAL_SIZE } from '../../../../constants'
import { getCompany, getEmailTemplate, wizardSelector } from '../../../../services/redux/selectors'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { RouteContainer } from '../../styles'
import { getUrl, useActions } from '../../utils'
import { withStepManager } from '../../with-step'
import EmailSent from './EmailSent'
import { FixedEditor } from './styles'

function EmailTemplateEditor({ ready, entering }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id, templateId } = useParams()
  const { editEmailTemplate, sendPreviewEmail, getEmailTemplates, setWizardModalProps } =
    useActions(wizardActions)
  const { emailTemplates, wizardProps } = useSelector(wizardSelector)
  const template = useSelector(getEmailTemplate(templateId))
  const company = useSelector(getCompany)
  const [changed, setChanged] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (wizardProps.size !== WIZARD_MODAL_SIZE.LARGE) {
      dispatch(setWizardModalProps({ size: WIZARD_MODAL_SIZE.LARGE }))
    }
  }, [wizardProps])

  /**
   * Load available templates if they have not yet been loaded
   */
  useEffect(() => {
    if (!emailTemplates || !emailTemplates.results.length) {
      getEmailTemplates()
    }
  }, [])

  const handleClose = () => {
    setShowPreview(false)
    history.push(getUrl(STEP.SCHEDULING_AND_EMAILS, { id }))
  }

  const handleSendTestEmail = async (subject, body) => {
    setActive(true)
    await sendPreviewEmail({ subject, body })
    setActive(false)
    setEmailSent(true)
    setTimeout(() => setEmailSent(false), 1200)
  }

  const handleSaveTemplate = (name, subject, content, targetTemplateId) => {
    setActive(true)
    editEmailTemplate({ name, subject, content, id: targetTemplateId }, history)
  }

  /**
   * Deffer if not ready
   */
  if (!ready || !template || active) {
    return <ActivityIndicator active />
  }

  if (emailSent) {
    return <EmailSent />
  }

  return (
    <CSSTransition in={entering && !!template} classNames="scale" timeout={500}>
      <RouteContainer padding="0px" className="route-container">
        {template && (
          <FixedEditor>
            <WysiwygEditor
              template={template}
              company={company}
              save={handleSaveTemplate}
              sendEmail={handleSendTestEmail}
              close={handleClose}
              onChange={() => {
                if (!changed) setChanged(true)
              }}
              showPreview={showPreview}
            />
          </FixedEditor>
        )}
      </RouteContainer>
    </CSSTransition>
  )
}

EmailTemplateEditor.propTypes = {
  ready: PropTypes.bool.isRequired,
  entering: PropTypes.bool.isRequired
}

export default withStepManager(EmailTemplateEditor, STEP.EMAIL_TEMPLATE_EDITOR)
