/* eslint-disable import/no-unresolved */
import React, { Fragment, useEffect, useState } from 'react'
import { IconLink } from '@src/components'
import { useActions } from '@src/scenes/CampaignWizard/utils'
import * as campaignsActions from '@src/services/redux/campaigns/actions'
import { CAMPAIGN_STATUS } from '@src/constants'
import { useSelector } from 'react-redux'
import { campaignsSelector } from '@src/services/redux/selectors'
import Header from '@elevate_security/elevate-component-library/dist/Header'
import {
  Button,
  Modal,
  CustomSelect,
  Typography
} from '@elevate_security/elevate-component-library'
import { CustomSelectWrapper, FilterWrapper, HeaderWrapper, ModalWrapper, Wrapper } from './styles'

function FilterByYear() {
  const { H2 } = Typography
  const [isOpen, setIsOpen] = useState(false)
  const [yearFilter, setYearFilter] = useState('')
  const { downloadCompletionReport, getCompletionYears } = useActions(campaignsActions)
  const { campaignStatuses, completionYears } = useSelector(campaignsSelector)
  const hasCsv =
    campaignStatuses &&
    campaignStatuses.filter(
      ({ total, status }) =>
        (status === CAMPAIGN_STATUS.ARCHIVED || status === CAMPAIGN_STATUS.COMPLETED) && total > 0
    ).length > 0

  useEffect(() => {
    if (!completionYears?.length) {
      getCompletionYears()
    }
  }, [])

  if (!hasCsv) {
    return <div />
  }

  const cancelHandler = () => {
    setYearFilter('')
    setIsOpen(false)
  }

  const renderHeader = (
    <Header>
      <H2 color="900">Export Data</H2>
    </Header>
  )

  const getYearValue = (year) => {
    return year === 'all_years' ? '' : year
  }
  const rightButtons = (
    <>
      <Button key="no" theme="ghost" onClick={() => cancelHandler()}>
        Cancel
      </Button>
      <Button
        key="yes"
        onClick={() => downloadCompletionReport(getYearValue(yearFilter), cancelHandler)}
        disabled={!yearFilter.length}
      >
        Export
      </Button>
    </>
  )

  const renderBody = (
    <Wrapper>
      <FilterWrapper>
        <HeaderWrapper>Select year of the data to be exported:</HeaderWrapper>
        <CustomSelectWrapper>
          <CustomSelect
            options={completionYears}
            placeholder="Filter by Year"
            onChange={(selectedYear) => {
              setYearFilter(selectedYear?.value?.toString())
            }}
          />
        </CustomSelectWrapper>
      </FilterWrapper>
    </Wrapper>
  )

  return (
    <ModalWrapper>
      <IconLink
        text="Download Completion Report"
        iconName="Download"
        fontWeight="bold"
        iconSize="xsm"
        onClick={() => setIsOpen(true)}
      />
      <Modal
        data-id="filter-by-year"
        header={renderHeader}
        rightButtons={rightButtons}
        isOpen={isOpen}
        onClose={() => cancelHandler()}
      >
        {renderBody}
      </Modal>
    </ModalWrapper>
  )
}

export default FilterByYear
