import { SET_AUTHENTICATED_USER } from './wizard/types'
import * as api from './api'

export const fetchUser = () => {
  return async (dispatch) => {
    const { data, error } = await api.fetchUser()

    if (!error) {
      dispatch({
        type: SET_AUTHENTICATED_USER,
        data
      })
    }
  }
}
