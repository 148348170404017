import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, memo } from 'react'
import { Button, Icon } from '@elevate_security/elevate-component-library'
import { Container, List, ListItem } from './styles'

function DropdownButton({ title, buttons }) {
  const [toggle, setToggle] = useState(false)
  const dropdownRef = useRef(null)

  const handleOnToggle = () => setToggle(!toggle)
  const handleOnClick = (e, onClick) => {
    e.preventDefault()
    if (typeof onClick === 'function') onClick()
    handleOnToggle()
  }

  useEffect(() => {
    if (dropdownRef.current) {
      const first = dropdownRef.current.querySelector('button')
      first.onblur = () => setTimeout(() => setToggle(false), 250)
    }
  }, [dropdownRef])

  const renderOptions = buttons
    .filter(({ isVisible }) => isVisible)
    .map(({ label, onClick }) => (
      <ListItem key={label}>
        <Button theme="ghost" onClick={(e) => handleOnClick(e, onClick)}>
          {label}
        </Button>
      </ListItem>
    ))

  return (
    <Container hasIcon={renderOptions.length > 1} ref={dropdownRef}>
      {renderOptions.length > 1 && (
        <Button theme="ghost" onClick={handleOnToggle}>
          {title}
          <Icon name="SortTriangle" fill="rgb(12, 125, 132)" />
        </Button>
      )}

      {renderOptions.length === 1 && (
        <Button theme="ghost" onClick={(e) => handleOnClick(e, buttons[0].onClick)}>
          {buttons[0].label}
        </Button>
      )}

      {toggle && renderOptions.length > 1 && <List>{renderOptions}</List>}
    </Container>
  )
}

DropdownButton.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.shape([
    PropTypes.shape({
      isVisible: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ]).isRequired
}

export default memo(DropdownButton)
