/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Typography } from '@elevate_security/elevate-component-library'
import { CenterContent } from '../FileUpload/styles'
import UploadProgressBar from '../UploadProgressBar'
import { formatBytes } from '../utils'
import { StyledLink, StyledText } from './styles'

const { Body, Text } = Typography

function DropZone({ acceptedFile, isValid, progress, isProcessing, isDragAccept }) {
  const hasFile = acceptedFile !== null
  const iconColor = (() => {
    if (hasFile) {
      return '#959da8'
    }
    if (isValid === false || !isDragAccept) {
      return '#c73434'
    }
    return 'gray'
  })()

  if (isProcessing && acceptedFile) {
    return (
      <CenterContent data-id="hm-sessions-upload-queue">
        <Body fontWeight="normal" margin=" 0 0 16px 0">
          Hold tight! We're uploading
          <Text fontWeight="bold" style={{ display: 'inline-flex' }} margin="0 0 0 5px">
            {` ${acceptedFile.name}...`}
          </Text>
        </Body>
        <UploadProgressBar percentage={progress} />
      </CenterContent>
    )
  }

  return (
    <CenterContent data-id="hm-sessions-upload-drop-zone">
      <Icon name={hasFile ? 'File' : 'Upload'} size="lg" fill={iconColor} />
      {hasFile && (
        <StyledText margin="12px 0 0 0" fontWeight="bold" style={{ display: 'inline-flex' }}>
          {acceptedFile.name}&nbsp;
          <Text fontWeight="normal">{formatBytes(acceptedFile.size)}</Text>
        </StyledText>
      )}
      {!hasFile && (
        <StyledText style={{ marginTop: hasFile ? '8px' : '16px' }} fontWeight="normal" color="700">
          Drag & drop CSV file here or
          <StyledLink> browse for file</StyledLink>
        </StyledText>
      )}
      {hasFile && (
        <StyledText style={{ marginTop: hasFile ? '8px' : '16px' }} fontWeight="normal" color="700">
          Drag & drop to replace or
          <StyledLink> choose a different file</StyledLink>
        </StyledText>
      )}
    </CenterContent>
  )
}

DropZone.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
  isDragAccept: PropTypes.bool.isRequired,
  acceptedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired
  }),
  isValid: PropTypes.bool.isRequired
}

DropZone.defaultProps = {
  acceptedFile: null
}

export default DropZone
