import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input, Toggle, RadioButton } from '@elevate_security/elevate-component-library'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { ActivityIndicator, InputErrors, InputHint, ToggleGroup } from '../../../../components'
import { STEP, CAMPAIGN_TYPE } from '../../../../constants'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import {
  getOrganizationId,
  isShortVersionEnabled,
  wizardSelector
} from '../../../../services/redux/selectors'
import {
  RouteContainer,
  Form,
  FieldSet,
  Label,
  Select,
  PadLeft,
  RadioButtonContainer
} from '../../styles'
import { formOutOfSync, useActions } from '../../utils'
import { withForm } from '../../with-form'
import { withStepManager } from '../../with-step'
import { getCampaignInfoForm, HM_CAMPAIGN_INFO_FORM_NAME, toggleTagsValidation } from './form'

function CampaignInfo(props) {
  const { id } = useParams()
  const organizationId = useSelector(getOrganizationId)
  const shortAllowed = useSelector(isShortVersionEnabled)
  const { ready, entering, handleChange, values } = props
  const { campaign, wizardForm, behaviors, shortTagOrg } = useSelector(wizardSelector)
  const { setWizardForm } = useActions(wizardActions)

  /**
   * Keep the form in sync by checking if the current form name and id
   * matches this step and the campaign.
   */
  useEffect(() => {
    if (
      formOutOfSync(id, HM_CAMPAIGN_INFO_FORM_NAME, campaign && campaign.id(), wizardForm) &&
      behaviors.length > 0
    ) {
      setWizardForm(getCampaignInfoForm(organizationId, campaign, behaviors, shortTagOrg))
    }
  })

  /**
   * Disabled behaviors (tags) validation when short session is on.
   */
  useEffect(() => {
    if (wizardForm && wizardForm.get('short')) {
      const { short, tags } = wizardForm.get()
      const isShort = short.value.length === 1

      /**
       * If is short and tags still have validations, remove them
       * if is not short and tags have no validation, add them
       */
      if ((isShort && tags.validations.length > 0) || (!isShort && tags.validations.length === 0)) {
        setWizardForm(wizardForm.update('tags', toggleTagsValidation(tags, isShort)))
      }
    }
  }, [wizardForm])

  /**
   * Deffer if not ready
   */
  if (!ready) {
    return <ActivityIndicator active />
  }

  const { name, tags, language, short, campaign_type } = wizardForm.get()

  return (
    <CSSTransition in={entering} classNames="scale" timeout={500}>
      <RouteContainer>
        <Form data-id="hm-campaign-info-form">
          <FieldSet data-id="hm-campaign-info-name">
            <Label>
              Campaign Name&nbsp;<span>(required)</span>
            </Label>
            <Input
              width="full"
              value={values.name}
              required={name.dirty && wizardForm.isRequired('name')}
              invalid={!name.valid}
              onChange={(value) => handleChange('name', { value })}
            />
            {!name.valid && <InputErrors errors={name.errors} />}
          </FieldSet>
          <FieldSet data-id="hm-campaign-info-topics" direction="row">
            <div>
              <Label>Topics to Cover</Label>
              <InputHint highlight={!tags.valid}>
                Select at least one behaviour for your campaign.
              </InputHint>
              <ToggleGroup
                radio={tags}
                disabled={values.short && values.short.length}
                radioSelected={values.tags}
                onChange={(key, value) => handleChange('tags', { value, key })}
              />
            </div>
            <RadioButtonContainer>
              <RadioButton
                options={CAMPAIGN_TYPE}
                name="CampaignTypes"
                vertical
                invalid={!campaign_type.valid}
                radioSelected={values.campaign_type}
                onChange={(value) => handleChange('campaign_type', { value })}
                tooltip
              />
              {!campaign_type.valid && <InputErrors errors={campaign_type.errors} />}
            </RadioButtonContainer>
          </FieldSet>
          <FieldSet data-id="hm-campaign-info-language">
            <Label>Language</Label>
            <Select
              options={language.options}
              invalid={!name.valid}
              disabled={values.short && values.short.length}
              defaultValue={language.value || language.defaultValue}
              onChange={({ value }) => handleChange('language', { value })}
            />
          </FieldSet>
          {shortAllowed && shortTagOrg && (
            <FieldSet data-id="hm-campaign-info-short">
              <Label>Short Version</Label>
              <PadLeft value="10px">
                <Toggle
                  label="Make this a short demo campaign."
                  name={short.name}
                  checked={values.short && values.short.length}
                  onChange={(value) => handleChange('short', { key: short.name, value })}
                />
              </PadLeft>
            </FieldSet>
          )}
        </Form>
      </RouteContainer>
    </CSSTransition>
  )
}

CampaignInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  entering: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    short: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    campaign_type: PropTypes.string.isRequired
  }).isRequired
}

export default withStepManager(
  withForm(CampaignInfo),
  STEP.CAMPAIGN_INFO,
  HM_CAMPAIGN_INFO_FORM_NAME
)
