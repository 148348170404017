import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import sanitizeFilename from 'sanitize-filename'
import DropZone from '../DropZone'
import { getDropZoneState } from '../utils'
import { FileDropArea } from './styles'

function FileUpload(props) {
  const { rejectedFile, acceptedFile, updateRejectedFile, updateFile, progress, isProcessing } =
    props

  const accept = '.csv,text/csv'
  const [isDragAccept, setIsDragAccept] = useState(true)
  const [isValid, setValid] = useState(true)

  useEffect(() => {
    setValid(rejectedFile === null)
  }, [rejectedFile])

  const handleUpload = (fileToAccept) => {
    // Sanitize file names
    const sanitizedAcceptedFile = fileToAccept.map(
      (file) => new File([file], sanitizeFilename(file.name), { type: file.type })
    )

    updateFile(sanitizedAcceptedFile)
    updateRejectedFile(null)
  }

  const handleDropRejected = (fileToReject) => {
    updateRejectedFile(fileToReject)
    updateFile(null)
  }

  function handleOnDragEnter(e) {
    e.preventDefault()

    if (e.dataTransfer && e.dataTransfer.items) {
      const { items } = e.dataTransfer
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i]
        setIsDragAccept(accept.includes(item.type))
      }
    }
  }

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: false,
    accept,
    onDrop: handleUpload,
    onDragEnter: handleOnDragEnter,
    onDragLeave: () => setIsDragAccept(true),
    onDropRejected: handleDropRejected
  })

  return (
    <FileDropArea
      data-id="hm-sessions-upload-drop-area"
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
        state: getDropZoneState(isValid)
      })}
    >
      <input {...getInputProps()} />
      <DropZone
        isValid={isValid}
        acceptedFile={acceptedFile}
        progress={progress}
        isProcessing={isProcessing}
        isDragAccept={isDragAccept}
      />
    </FileDropArea>
  )
}

FileUpload.propTypes = {
  progress: PropTypes.number,
  updateFile: PropTypes.func,
  updateRejectedFile: PropTypes.func,
  uploadFail: PropTypes.bool,
  isProcessing: PropTypes.bool.isRequired,
  rejectedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired
  }),
  acceptedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired
  })
}

FileUpload.defaultProps = {
  progress: 0,
  updateFile: () => null,
  updateRejectedFile: () => null,
  uploadFail: false,
  rejectedFile: null,
  acceptedFile: null
}

export default FileUpload
