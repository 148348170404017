import React from 'react'
import PropTypes from 'prop-types'
import { Hint } from './styles'

function InputHint(props) {
  const { highlight, children } = props
  return (
    <Hint highlighted={highlight} data-id="hm-input-hint" data-highlighted={highlight}>
      {children}
    </Hint>
  )
}

InputHint.propTypes = {
  highlight: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired
}

export default InputHint
