import React, { useState, useEffect } from 'react'
import { Icon, Typography, theme } from '@elevate_security/elevate-component-library'
import { CSSTransition } from 'react-transition-group'
import { FlexColumn, FlexRow } from '../../../../../common.styles'
import { RouteContainer } from '../../../styles'

const { H1 } = Typography

function EmailSent() {
  const [triggering, setTriggering] = useState(false)
  const [enter, setEnter] = useState(false)

  useEffect(() => () => {
    if (enter) setEnter(false)

    if (!enter && !triggering) {
      setTriggering(true)
      setTimeout(() => setEnter(true), 100)
    }
  })

  return (
    <CSSTransition in={enter} classNames="scale" timeout={500}>
      <RouteContainer padding="0px">
        <FlexColumn justify="center" align="center" gap="20px" width="100%">
          <FlexRow justify="center" alignItems="flex-end">
            <Icon name="CircleCheckOutline" size="xlg" fill={theme.colors.primary['500']} />
          </FlexRow>
          <FlexRow>
            <H1>Email Sent</H1>
          </FlexRow>
        </FlexColumn>
      </RouteContainer>
    </CSSTransition>
  )
}

export default EmailSent
