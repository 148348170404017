/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import { Provider } from 'react-redux'

// components
import HackersMind from './App'
import store from './services/redux'

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: () => (
    <Provider store={store}>
      <HackersMind />
    </Provider>
  ),
  domElementGetter
})

export const bootstrap = [reactLifecycles.bootstrap]

export const mount = [
  reactLifecycles.mount,
  () => Promise.resolve(console.log('Mounting HackersMind'))
]

export const unmount = [
  reactLifecycles.unmount,
  () => Promise.resolve(console.log('Unmounting HackersMind'))
]

export const unload = [reactLifecycles.unload]

function domElementGetter() {
  const ID = 'hackersmind'
  let el = document.getElementById(ID)
  if (!el) {
    el = document.createElement('div')
    el.id = ID
    document.body.appendChild(el)
  }
  el.setAttribute('version', __COMMIT_HASH__)
  return el
}
