import { HM_NEW_CAMPAIGN_PATH, STEP } from '../../constants'
import {
  CampaignInfo,
  Confirm,
  Participants,
  RollOutType,
  Scheduling,
  CampaignSessions,
  CampaignSessionsUpload,
  EmailTemplateEditor,
  EmailTemplatePreview
} from './components'

const routes = [
  {
    exact: true,
    path: [HM_NEW_CAMPAIGN_PATH, STEP.CAMPAIGN_INFO],
    component: CampaignInfo
  },
  {
    exact: true,
    path: STEP.PARTICIPANTS,
    component: Participants
  },
  {
    exact: true,
    path: STEP.ROLL_OUT_UPDATE_SESSIONS,
    component: CampaignSessions
  },
  {
    exact: true,
    path: STEP.ROLL_OUT_SESSIONS_UPLOAD,
    component: CampaignSessionsUpload
  },
  {
    exact: true,
    path: STEP.ROLL_OUT_TYPE,
    component: RollOutType
  },
  {
    exact: true,
    path: STEP.EMAIL_TEMPLATE_EDITOR,
    component: EmailTemplateEditor
  },
  {
    exact: true,
    path: STEP.EMAIL_TEMPLATE_PREVIEW,
    component: EmailTemplatePreview
  },
  {
    exact: true,
    path: STEP.SCHEDULING_AND_EMAILS,
    component: Scheduling
  },
  {
    exact: true,
    path: STEP.REVIEW,
    component: Confirm
  }
]

export default routes
