export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER'
export const SET_WIZARD_OPEN = 'SET_WIZARD_OPEN'
export const SET_WIZARD_FORM = 'SET_WIZARD_FORM'
export const SET_WIZARD_PROPS = 'SET_WIZARD_PROPS'
export const SET_WIZARD_STEP = 'SET_WIZARD_STEP'
export const SET_STEPS_SUBMITTED = 'SET_STEPS_SUBMITTED'
export const SET_WIZARD_ERROR = 'SET_WIZARD_ERROR'
export const CLEAR_WIZARD_ERROR = 'CLEAR_WIZARD_ERROR'
export const CAMPAIGN_LOADING = 'CAMPAIGN_LOADING'
export const SET_WIZARD_CAMPAIGN = 'SET_WIZARD_CAMPAIGN'
export const SET_CAMPAIGN_ROOMS = 'SET_CAMPAIGN_ROOMS'
export const SET_CAMPAIGN_SESSIONS = 'SET_CAMPAIGN_SESSIONS'
export const SET_CAMPAIGN_LEADS = 'SET_CAMPAIGN_LEADS'
export const SET_CAMPAIGN_TARGETS = 'SET_CAMPAIGN_TARGETS'
export const SET_TAGS = 'SET_TAGS'
export const SET_PARTICIPANT_GROUPS = 'SET_PARTICIPANT_GROUPS'
export const SET_WIZARD_EMPLOYEE_COUNT = 'SET_WIZARD_EMPLOYEE_COUNT'
export const BEFORE_SUBMIT_WIZARD = 'BEFORE_SUBMIT_WIZARD'
export const AFTER_SUBMIT_WIZARD = 'AFTER_SUBMIT_WIZARD'
export const SUBMIT_WIZARD_ERROR = 'SUBMIT_WIZARD_ERROR'
export const WIZARD_FORM_INVALID = 'WIZARD_FORM_INVALID'
export const UNLOAD_WIZARD = 'UNLOAD_WIZARD'
export const SET_EMAIL_TEMPLATES = 'SET_EMAIL_TEMPLATES'
export const SET_DYNAMICALLY_TARGETS = 'SET_DYNAMICALLY_TARGETS'
export const SET_ROLLOUT_TYPE = 'SET_ROLLOUT_TYPE'
