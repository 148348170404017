import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  background-color: #d4e9ea;
  color: #0c7d84;
  overflow: visible;
  button,
  ul,
  li {
    border-radius: 0px;
    margin: 0;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  > button {
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding-right: ${({ hasIcon }) => (hasIcon ? '36px' : '24px')};
    svg {
      position: absolute;
      right: 9px;
    }
  }
`
export const List = styled.ul`
  position: absolute;
  background-color: white;
  border: 1px solid #e8e8e8;
  width: auto;
  min-width: calc(100% - 0px);
  right: 0;
`
export const ListItem = styled.li`
  white-space: nowrap;

  &:after {
    content: '';
    display: block;
    width: calc(100% - 8px);
    height: 1px;
    background-color: #e8e8e8;
    margin: 0 auto;
  }
  &:last-of-type {
    border-bottom: none;
    &:after {
      display: none;
    }
  }
  button {
    width: 100%;
    text-align: right;
  }
`
