import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import {
  Button,
  ButtonLink,
  Input,
  Typography,
  Icon,
  theme
} from '@elevate_security/elevate-component-library'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { STEP } from '../../constants'
import { FlexColumn, FlexItem, FlexRow } from '../../common.styles'
import { ModalHeader } from '../../scenes/CampaignWizard/components/CampaignSessions/styles'
import { FieldSet, FlatLabel } from '../../scenes/CampaignWizard/styles'
import { withForm } from '../../scenes/CampaignWizard/with-form'
import { getEmailEditorForm, fromHtml, HM_EMAIL_EDITOR_FORM } from './form'
import { formOutOfSync, getHTML, getUrl, useActions } from '../../scenes/CampaignWizard/utils'
import { wizardSelector } from '../../services/redux/selectors'
import * as wizardActions from '../../services/redux/wizard/actions'
import CustomOption from './components/CustomOption'
import { ActionsContainer, EditorFieldset } from './styles'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const { H2 } = Typography

function WysiwygEditor({ template, values, save, close, sendEmail, handleChange }) {
  const history = useHistory()
  const { id, templateId } = useParams()
  const { wizardForm } = useSelector(wizardSelector)
  const { setWizardForm } = useActions(wizardActions)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  /**
   * Keep the form in sync by checking if the current form name and id
   * matches this step and the campaign.
   */
  useEffect(() => {
    if (formOutOfSync(templateId, HM_EMAIL_EDITOR_FORM, template && template.id, wizardForm)) {
      setWizardForm(getEmailEditorForm(template))
    }
  })

  /**
   * Updates the editor states when the user navigates in and
   * out of the editor.
   */
  useEffect(() => {
    const html = (!!wizardForm && wizardForm.value('body')) || template.body
    const state = fromHtml(html)

    // const state = fromHtml(template.body)
    if (getHTML(editorState) !== getHTML(state)) {
      setEditorState(state)
    }
  }, [wizardForm, template])

  const sendTestEmail = () => {
    const body = getHTML(editorState)
    return sendEmail && sendEmail(wizardForm.value('subject'), body)
  }

  const saveContent = () => {
    const { name: nameValue, subject: subjectValue } = wizardForm.value()
    return save && save(nameValue, subjectValue, getHTML(editorState), template.id)
  }

  const closeEditor = () => {
    const { name: nameValue, subject: subjectValue } = wizardForm.value()
    return close && close(nameValue, subjectValue, getHTML(), template.id)
  }

  const previewTemplate = () => {
    setWizardForm(wizardForm.update('body', { value: getHTML(editorState) }).cache(template.id))
    history.push(previewUrl)
  }

  const renderHeader = () => (
    <ModalHeader>
      <H2 color="900" fontWeight="bold">
        {template.name}
      </H2>
      <div>
        <ButtonLink onClick={closeEditor}>Cancel</ButtonLink>
      </div>
    </ModalHeader>
  )

  const renderEmail = () => (
    <FlexColumn stretch>
      {renderHeader()}
      <br />
      <FieldSet width="100%" direction="row" gap="12px" height="60px">
        <FlatLabel width="14%">Template Name</FlatLabel>
        <Input value={values.name} onChange={(value) => handleChange('name', { value })} />
      </FieldSet>
      <FieldSet width="100%" direction="row" gap="12px" height="60px">
        <FlatLabel width="14%">Subject</FlatLabel>
        <Input value={values.subject} onChange={(value) => handleChange('subject', { value })} />
      </FieldSet>
      <EditorFieldset width="100%" gap="12px">
        <Editor
          editorState={editorState}
          editorClassName="editor-html"
          toolbarClassName="editor-toolbar"
          onEditorStateChange={(value) => setEditorState(value)}
          toolbarCustomButtons={[<CustomOption />]}
        />
      </EditorFieldset>
    </FlexColumn>
  )

  if (!wizardForm || !wizardForm.is(HM_EMAIL_EDITOR_FORM)) {
    return null
  }

  const previewUrl = getUrl(STEP.EMAIL_TEMPLATE_PREVIEW, { id, templateId }, { referrer: 'editor' })

  return (
    <FlexColumn stretch style={{ overflow: 'hidden' }}>
      {renderEmail()}
      <FlexRow stretch style={{ maxHeight: '50px' }}>
        <FlexItem
          className="text-muted"
          self="stretch"
          grow="1"
          align="center"
          style={{ marginRight: 'auto', overflow: 'hidden' }}
        >
          <small>Template id: {template.id}</small>
        </FlexItem>
        <FlexItem self="stretch" align="center">
          <ActionsContainer stretch align="center">
            <Button theme="secondary" onClick={sendTestEmail}>
              Send Test Email
            </Button>
            <Button theme="secondary" onClick={previewTemplate}>
              <Icon name="Eye" size="xsm" fill={theme.colors.primary['700']} /> Preview
            </Button>
            <Button onClick={saveContent}>
              <Icon name="Check" size="xsm" fill="#fff" /> Save
            </Button>
          </ActionsContainer>
        </FlexItem>
      </FlexRow>
    </FlexColumn>
  )
}

WysiwygEditor.defaultProps = {
  template: {
    id: null,
    name: null,
    subject: null,
    body: null
  },
  values: {
    id: null,
    name: null,
    subject: null,
    body: null
  },
  save: () => null,
  close: () => null,
  sendEmail: () => null,
  handleChange: () => null
}

WysiwygEditor.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string
  }),
  values: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string
  }),
  save: PropTypes.func,
  close: PropTypes.func,
  sendEmail: PropTypes.func,
  handleChange: PropTypes.func
}

export default withForm(WysiwygEditor)
