import styled from 'styled-components'

export const StyledDropDown = styled.div`
  position: relative;
`

export const StyledDropDownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.25rem 0 0;
  font-size: 0.875rem;
  color: #444d58;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e8ec;
`

export const StyledDropDownToggle = styled.button`
  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.3em;
    vertical-align: middle;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
  }

  &:focus {
    outline: 0;
  }
`

export const StyledDropDownHeader = styled.h6`
  display: block;
  padding: 0 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #bcc5d0;
  white-space: nowrap;
`

export const StyledDropDownItem = styled.button`
  display: block;
  width: 100%;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: normal;
  color: #444d58;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;

  &:focus,
  &:hover {
    color: #039ba1;
    text-decoration: none;
    outline: none;
    background-color: #f1f3f8;
  }
  &.active,
  &:active {
    color: #fff;
    text-decoration: none;
    background-color: #039ba1;
  }
  &.disabled,
  &:disabled {
    color: #bcc5d0;
    cursor: not-allowed;
    background-color: transparent;
  }
`
