import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker-wrapper .react-datepicker__input-container input {
    line-height: 38px;
    padding: 0 16px;
    border: 1px solid
      ${({ invalid }) => (invalid ? 'rgb(199, 52, 52)' : 'rgba(189, 197, 209, 0.5)')};
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
    outline: none;
    &:hover {
      border: 1px solid rgba(58, 70, 85, 0.3);
    }
    &:focus {
      border: 1px solid rgb(12, 125, 132);
      box-shadow: rgb(12, 125, 132) 0px 0px 3px;
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }
`
