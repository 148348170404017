import styled from 'styled-components'
import { Typography } from '@elevate_security/elevate-component-library'

const { Text } = Typography

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin: initial;
  }
`

export const CampaignListContainer = styled.div`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
`

export const EmptyListMessage = styled(Text)`
  opacity: 0.7;
  font-size: 18px;
`
export const HeaderWrapper = styled.div`
  [class^='Header__HeaderContainer'] {
    padding-top: 0;
  }
`
