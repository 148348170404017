import React from 'react'
import PropTypes from 'prop-types'
import { InputCheckbox, InputCheckBoxWrapper } from './styles'

function Checkbox({ checked, disabled, indeterminate, onChange, label, id, invalid }) {
  const handleChange = (e) => onChange(e.currentTarget ? e.currentTarget.checked : null, e)
  const props = {
    ...(checked !== null ? { checked } : {})
  }

  return (
    <InputCheckBoxWrapper>
      <InputCheckbox
        indeterminate={indeterminate}
        disabled={disabled}
        onChange={(e) => handleChange(e)}
        id={id}
        invalid={invalid}
        {...props}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </InputCheckBoxWrapper>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  invalid: PropTypes.bool
}

Checkbox.defaultProps = {
  checked: null,
  disabled: false,
  indeterminate: false,
  onChange: () => null,
  label: '',
  id: 'checkbox-0',
  invalid: false
}

export default Checkbox
