/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import { TextWrapper } from './styles'

function ReadOnlyCell({ cell }) {
  const { type } = cell.column.fieldProps
  const { value } = cell

  let formattedValue = value

  if (type === 'datetime')
    formattedValue = value ? Moment(value).format('M/DD/YYYY, hh:mm A') : null

  return <TextWrapper>{formattedValue}</TextWrapper>
}

ReadOnlyCell.propTypes = {
  cell: PropTypes.shape({}).isRequired
}

export default ReadOnlyCell
