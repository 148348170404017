import styled from 'styled-components'
import { theme } from '@elevate_security/elevate-component-library'
import { FlexColumn } from '../../../../common.styles'
import { FieldSet } from '../../styles'

export const PreviewContainer = styled(FlexColumn)`
  padding: 24px 1%;
  overflow: hidden;
  border-radius: 4px;
  width: 100%;
`

export const PreviewFieldset = styled(FieldSet)`
  flex: 1 1 0%;
  padding: 24px;
  border: 1px solid ${theme.colors.border.dark};
  overflow: scroll;
  align-self: stretch;
`

export const PreviewSubjectFieldset = styled(FieldSet)`
  flex: 0;

  & > span {
    &:last-child {
      font-size: 22px;
    }
  }
`
export const PreviewTitle = styled.small`
  display: block;
  font-size: 14px;
  opacity: 0.6;
`

export const PreviewSubject = styled.span`
  display: block;
  font-size: 22px;
`
