import styled from 'styled-components'
import { FlexRow, FlexColumn } from '../../../../../../common.styles'

export const Container = styled(FlexColumn)`
  margin: 20px 0;
  min-width: 100%;
  max-width: 100%;
`

export const TableContainer = styled(FlexRow)`
  .cell-timezone .dropdown-container {
    width: auto;
  }
`

export const InputSearchWrapper = styled.div`
  max-width: 240px;
  margin: 4px 0 15px 0;
  input {
    min-width: 240px;
    background-color: #eef0f3;
    font-size: 14px;
    border: 0;

    &:hover,
    &:focus,
    &:active {
      border: 0;
      box-shadow: none;
    }
  }
`
