import styled from 'styled-components'

export const FileDropArea = styled.div.attrs(({ isDragActive, isDragAccept, state }) => ({
  border: (() => {
    if (isDragActive && isDragAccept) {
      return '2px solid #15785f'
    }
    if ((isDragActive && !isDragAccept) || state === 'error') {
      return '2px solid #c73434'
    }
    return '1px dashed #959da8'
  })(),
  background: (() => {
    if (isDragActive && isDragAccept) {
      return '#ceebd9'
    }
    if ((isDragActive && !isDragAccept) || state === 'error') {
      return '#F6E3E3'
    }
    return '#fffff'
  })()
}))`
  display: flex;
  flex-direction: column;
  border: ${(props) => props.border};
  background-color: ${(props) => props.background};
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 160px;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 28px 24px;
  transition-property: background, border;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease-in, ease-in;
  outline: none;
  & a {
    color: #0c7d84 !important;
  }
`

FileDropArea.displayName = 'FileDropArea'

export const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: stretch;
`

CenterContent.displayName = 'CenterContent'
