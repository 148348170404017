import React, { useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'

const { Chart } = window

function LineGraph({ data, options }) {
  const lineGraph = useRef(null)
  let canvas = null
  let canvasChart = null
  useEffect(() => {
    canvas = lineGraph.current ? lineGraph.current.getContext('2d') : null
    renderChart()
  })

  function renderChart() {
    if (canvasChart) canvasChart.destroy()
    canvas = !canvas ? lineGraph.current.getContext('2d') : canvas
    canvasChart = new Chart(canvas, {
      type: 'line',
      data,
      options
    })
  }

  return <canvas ref={lineGraph} />
}

LineGraph.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.object])
}

LineGraph.defaultProps = {
  data: {},
  options: {}
}

export default memo(LineGraph)
