import styled from 'styled-components'
import { theme } from '@elevate_security/elevate-component-library'
import { FlexRow } from '../../common.styles'
import { FieldSet } from '../../scenes/CampaignWizard/styles'

export const EditorFieldset = styled(FieldSet)`
  flex: 1;
  overflow: hidden;
  padding-bottom: 20px;

  .rdw-editor-wrapper {
    height: auto;
    flex: 1;
    overflow: hidden;

    .rdw-editor-main {
      flex: 1;
      border: 1px solid ${theme.colors.border.dark};
      padding: 16px;
      border-radius: 4px;
    }
  }
`

export const ActionsContainer = styled(FlexRow)`
  button:not(:first-child) {
    margin-left: 12px;
  }
`
