import lodashTemplate from 'lodash.template'
import lodashSettings from 'lodash.templatesettings'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import dompurify from 'dompurify'
import { ButtonLink } from '@elevate_security/elevate-component-library'
import { ActivityIndicator } from '../../../../components'
import { fromHtml } from '../../../../components/WysiwygEditor/form'
import {
  STEP,
  WIZARD_MODAL_SIZE,
  STATIC_EMAIL_TEMPLATES,
  STATIC_EMAIL_TEMPLATES_INFO
} from '../../../../constants'
import { FlexColumn, FlexItem, FlexRow } from '../../../../common.styles'
import {
  getCompany,
  getEmailTemplate,
  getUser,
  wizardSelector
} from '../../../../services/redux/selectors'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import * as globalActions from '../../../../services/redux/actions'
import { userQuery } from '../../../../services/utils'
import { getHTML, getUrl, useActions } from '../../utils'
import {
  PreviewContainer,
  PreviewSubjectFieldset,
  PreviewFieldset,
  PreviewSubject,
  PreviewTitle
} from './styles'

function EmailTemplatePreview() {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { id, templateId } = useParams()
  const { referrer } = userQuery(location.search)
  const { wizardForm, emailTemplates, wizardProps } = useSelector(wizardSelector)
  const { getEmailTemplates, setWizardModalProps } = useActions(wizardActions)
  const { fetchUser } = useActions(globalActions)
  const user = useSelector(getUser)
  const company = useSelector(getCompany)
  const template = useSelector(getEmailTemplate(templateId))
  const [preview, setPreviewContent] = useState(null)
  const sanitizer = dompurify.sanitize

  lodashSettings.evaluate = /{{([\s\S]+?)}}/g
  lodashSettings.interpolate = /{{([\s\S]+?)}}/g
  lodashSettings.escape = /{{([\s\S]+?)}}/g

  useEffect(() => {
    if (wizardProps.size !== WIZARD_MODAL_SIZE.MEDIUM_BLANK) {
      dispatch(setWizardModalProps({ size: WIZARD_MODAL_SIZE.MEDIUM_BLANK }))
    }
  }, [wizardProps])

  useEffect(() => {
    if (!user) {
      fetchUser()
    }
  }, [user])

  /**
   * Load available templates if they have not yet been loaded
   */
  useEffect(() => {
    if (!emailTemplates || !emailTemplates.results.length) {
      getEmailTemplates()
    }
  }, [])

  /**
   * If the user is coming from the template editor, there will
   * be a wizardForm and if the id is the same we use values from the
   * form so users can preview before saving.
   */
  useEffect(() => {
    if (!preview || templateId !== preview.id) {
      // let's check if its a static template
      if (Object.values(STATIC_EMAIL_TEMPLATES).includes(templateId)) {
        dispatch(wizardActions.getStaticEmailTemplates(templateId, id, setStaticTemplateContent))
      }
      if (wizardForm && wizardForm.value('id') === templateId) {
        setPreviewContent({
          id: wizardForm.value('id'),
          previewContent: compile(getHTML(fromHtml(wizardForm.value('body')))),
          previewSubject: compile(wizardForm.value('subject'))
        })
      }
    }
  }, [wizardForm])

  /**
   * If a user is coming with from anywhere besides the editor, we load the
   * content from the template data.
   */
  useEffect(() => {
    if (!preview || templateId !== preview.id) {
      /**
       * There maybe be a form if the user is coming from the schedule or editor
       * pages, we check here to cover only scenarios where there is no form or
       * the form is not the representation of the current templateId, in which
       * cases we load from the template data it self.
       */
      if ((!wizardForm || wizardForm.value('id') !== templateId) && template) {
        setPreviewContent({
          id: template.id,
          previewContent: compile(getHTML(fromHtml(template.body))),
          previewSubject: compile(template.subject)
        })
      }
    }
  }, [template])

  const setStaticTemplateContent = (htmlContent) => {
    const templateKey = Object.keys(STATIC_EMAIL_TEMPLATES).find(
      (key) => STATIC_EMAIL_TEMPLATES[key] === templateId
    )
    setPreviewContent({
      id: templateId,
      title: STATIC_EMAIL_TEMPLATES_INFO[templateKey].title,
      previewContent: htmlContent,
      previewSubject: STATIC_EMAIL_TEMPLATES_INFO[templateKey].subject
    })
  }

  const compile = (content) => {
    try {
      return lodashTemplate(content)({
        ...user,
        ...company,
        session_url: "Hacker's Mind session",
        scheduled_session_url: '[prescheduled sessions signup link]'
      })
    } catch (e) {
      return null
    }
  }

  const getExitUrl = () => {
    if (referrer === 'editor') {
      return {
        label: 'Back to editor',
        url: getUrl(STEP.EMAIL_TEMPLATE_EDITOR, { id, templateId })
      }
    }

    return {
      label: 'Back to schedule & emails',
      url: getUrl(STEP.SCHEDULING_AND_EMAILS, { id })
    }
  }

  if (!preview) {
    return <ActivityIndicator active />
  }

  const { previewSubject, previewContent, title } = preview
  const { label, url } = getExitUrl()

  return (
    <FlexColumn stretch>
      <PreviewContainer>
        <PreviewSubjectFieldset width="100%">
          <FlexRow>
            <FlexItem grow={1}>
              <div>
                <PreviewTitle>{template ? template.name : title}</PreviewTitle>
              </div>
              <div>
                <PreviewSubject> {previewSubject}</PreviewSubject>
              </div>
            </FlexItem>
            <FlexItem>
              <ButtonLink onClick={() => history.push(url)}>{label}</ButtonLink>
            </FlexItem>
          </FlexRow>
        </PreviewSubjectFieldset>
        <PreviewFieldset width="100%" gap="12px" style={{ overflow: 'scroll' }}>
          <p dangerouslySetInnerHTML={{ __html: sanitizer(previewContent) }} />
        </PreviewFieldset>
      </PreviewContainer>
    </FlexColumn>
  )
}

export default EmailTemplatePreview
