import styled from 'styled-components'
import { ButtonLink, Typography } from '@elevate_security/elevate-component-library'

const { Body } = Typography

export const StyledLink = styled(ButtonLink)`
  text-decoration: none;
  &:hover {
    text-decoration: rgb(12, 125, 132);
  }
`

export const StyledText = styled(Body)`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`
