import styled, { css } from 'styled-components'
import { CustomSelect, Typography, theme } from '@elevate_security/elevate-component-library'
import { WIZARD_MODAL_SIZE } from '../../constants'

const { Small, Text, A } = Typography

export const HeaderMetadata = styled(Small)`
  opacity: 0.8;
  text-transform: uppercase;
  padding-bottom: 12px;
  font-size: 12px;
`

export const ModalComponent = styled.div.attrs((props) => {
  switch (props.size) {
    case WIZARD_MODAL_SIZE.LARGE:
      return {
        ...props,
        width: '1296px',
        maxWidth: '1296px',
        maxHeight: '750px',
        height: 'calc(100% - 50px)',
        borderRadius: '8px'
      }
    case WIZARD_MODAL_SIZE.FULL_SCREEN:
      return {
        ...props,
        width: '98%',
        maxWidth: '98%',
        maxHeight: '98%',
        height: '98%'
      }
    default:
      return {
        ...props,
        width: '1096px',
        maxWidth: '1096px',
        maxHeight: '750px',
        height: 'calc(100% - 50px)',
        borderRadius: '8px'
      }
  }
})`
  > [class^='Modalstyles__ModalContainer'] {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    max-width: ${(props) => props.maxWidth};
    max-height: ${(props) => props.maxHeight};
    border-radius: ${(props) => props.borderRadius};
    padding: ${(props) => (props.size !== WIZARD_MODAL_SIZE.MEDIUM ? '0' : '40px')};

    > [class^='Modalstyles__ModalContent'] {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: flex-start;
      padding: 0;
    }

    > [class^='Modalstyles__ModalHeader'],
    > [class^='Modalstyles__ModalFooter'] {
      display: ${(props) => (props.size !== WIZARD_MODAL_SIZE.MEDIUM ? 'none' : 'flex')};
    }
  }
`

export const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  max-height: none;
  width: ${(props) => props.width};
  margin: ${(props) => (props.size !== WIZARD_MODAL_SIZE.MEDIUM ? '0' : '0 auto')};
  position: relative;
  padding: ${(props) => (props.size !== WIZARD_MODAL_SIZE.MEDIUM ? '24px 48px' : '0')};
`
const opacityTime = '300ms'
const transformTime = '300ms'

export const RouteContainer = styled.section`
  flex: 1;
  height: 100%;
  min-height: 100%;
  padding: ${(props) => props.padding || '24px 190px 0 190px'};
  margin: ${(props) => props.margin || 0};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align || 'flex-start'};
  ${(props) => (props.size === WIZARD_MODAL_SIZE.FULL_SCREEN ? 'overflow-x: hidden;' : '')}

  &.slide-enter {
    opacity: 0;
    transform: translateX(-50px);
  }
  &.slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity ${opacityTime}, transform ${transformTime};
  }
  &.slide-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &.slide-exit-active {
    opacity: 0;
    transform: translateX(50px);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: opacity ${opacityTime}, transform ${transformTime};
  }

  &.scale-enter {
    opacity: 0;
    transform: scale(0.98);
  }
  &.scale-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity ${opacityTime}, transform ${transformTime};
  }
  &.scale-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &.scale-exit-active {
    opacity: 0;
    transform: scale(0.98);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: opacity ${opacityTime}, transform ${transformTime};
  }
`

export const SaveForLaterLink = styled(A)`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  color: ${theme.text.color.link};
  &:hover {
    color: ${theme.text.color.linkHover};
  }
`

export const FieldSet = styled.div.attrs((props) => ({
  ...props,
  direction: !props.direction ? 'column' : props.direction
}))`
  position: relative;
  display: flex;
  flex: 1 0 ${(props) => props.width || 'auto'};
  flex-direction: ${(props) => props.direction || 'column'};
  line-height: 30px;
  max-width: ${(props) => props.width || 'auto'};
  min-width: ${(props) => props.width || 'auto'};
  max-height: ${(props) => props.height || 'auto'};
  margin-bottom: ${(props) => props.gap || '24px'};
  ${(props) =>
    props.direction === 'row'
      ? css`
          margin-right: ${props.gap || '24px'};
        `
      : null}

  ${(props) =>
    props.width &&
    css`
      & > div {
        flex: 1;
        input {
          min-width: 100%;
          max-width: 100%;
        }
      }
    `}
`
export const Select = styled(CustomSelect).attrs({ style: { width: '100%' } })``

export const Form = styled.form`
  min-width: 100%;
`

export const Label = styled(Text)`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 14px;
  color: #34383c;
  max-width: ${(props) => props.width || 'auto'};
  min-width: ${(props) => props.width || 'auto'};
`

export const FlatLabel = styled(Label)`
  margin-bottom: 0;
`

export const PadLeft = styled.div`
  padding-left: ${(props) => props.value || 0};
`
export const RadioButtonContainer = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  width: 235px;

  [class^='RadioButtonstyles__Label'] {
    display: inline;

    [class^='Typography__Text'] {
      line-height: 1;
      color: #565d66;
      white-space: nowrap;
    }
  }

  [class^='RadioButtonstyles__RadioButtonWrapper'] > div {
    padding: 0;
    height: 35px;
  }
  [class^='RadioButtonstyles__CheckMark'] {
    top: 3px;
  }
  [class^='styles__FieldSetError'] {
    line-height: 4px;
  }
`
