/* eslint-disable no-undef */
import axios from 'axios'
import AmplifyAuth from '@aws-amplify/auth'

function computePlatformApiEndpoint(baseUrl, prSuffix, reviewType) {
  // Review App Support
  if (prSuffix && reviewType === 'ep-review') {
    return baseUrl.replace(
      '.elevatesecurity-staging.com',
      `${prSuffix}.ep-review.elevatesecurity-staging.com`
    )
  }
  return baseUrl
}

const [subdomain, reviewType] = window.location.host.split('.')
const [, tenant, prSuffix] = subdomain.match(/^(.*?)(-(pr-[0-9]+))?$/)
const instance = axios.create({
  withCredentials: true,
  headers: {
    common: { 'X-Tenant': tenant }
  },
  baseURL: computePlatformApiEndpoint(REACT_APP_SERVER_URL, prSuffix, reviewType),
  tenant
})

instance.interceptors.request.use(
  (config) => {
    // Make sure the request is sent to our API to avoid leaking the token
    if (
      config.baseURL &&
      config.baseURL.includes(REACT_APP_SERVER_URL) &&
      !config.headers.Authorization
    ) {
      // eslint-disable-next-line no-undef
      const token = localStorage.getItem('JWT')
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = token
      }
    }

    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(null, async (error) => {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    const request_for_retry = error.response.config

    if (!request_for_retry.retried) {
      try {
        const session = await AmplifyAuth.currentSession()
        const authorization = `Bearer ${session.getIdToken().getJwtToken()}s`

        window.localStorage.setItem('JWT', authorization)

        request_for_retry.headers.Authorization = authorization
        request_for_retry.retried = true

        return axios.request(request_for_retry)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Failed to retry unauthorized request.', e)
      }
    }

    window.dispatchEvent(
      // eslint-disable-next-line no-undef
      new CustomEvent('global:unauthorized', {
        bubbles: true,
        cancelable: false,
        detail:
          error.response.data && 'detail' in error.response.data ? error.response.data.detail : {}
      })
    )
  }
  return Promise.reject(error)
})

export default instance
