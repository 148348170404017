import invert from 'lodash/invert'

export const HM_BASE_PATH = '/engagement/hackersmind'
export const HM_CAMPAIGNS_BASE_PATH = `${HM_BASE_PATH}/campaigns`
export const HM_NEW_CAMPAIGN_PATH = `${HM_CAMPAIGNS_BASE_PATH}/new-campaign`
export const HM_CAMPAIGN_PATH = `${HM_CAMPAIGNS_BASE_PATH}/campaign`
export const HM_OVERVIEW_BASE_PATH = `${HM_CAMPAIGN_PATH}/overview`

export const ICON_COLOR = {
  SIMPLE: '#959DA8',
  HOVER: '#039BA1'
}

const BASE_STEP = `${HM_CAMPAIGNS_BASE_PATH}/campaign/:id`

export const STEP = {
  NEW_CAMPAIGN_INFO: HM_NEW_CAMPAIGN_PATH,
  CAMPAIGN_INFO: BASE_STEP,
  PARTICIPANTS: `${BASE_STEP}/participants`,
  ROLL_OUT_TYPE: `${BASE_STEP}/roll-out`,
  ROLL_OUT_UPDATE_SESSIONS: `${BASE_STEP}/roll-out/sessions`,
  ROLL_OUT_SESSIONS_UPLOAD: `${BASE_STEP}/roll-out/sessions/upload`,
  SCHEDULING_AND_EMAILS: `${BASE_STEP}/schedule`,
  EMAIL_TEMPLATE_EDITOR: `${BASE_STEP}/schedule/template/:templateId/editor`,
  EMAIL_TEMPLATE_PREVIEW: `${BASE_STEP}/schedule/template/:templateId/preview`,
  REVIEW: `${BASE_STEP}/review`
}

export const STEPS = Array.from(
  Object.keys(STEP).filter(
    (name) =>
      [
        'NEW_CAMPAIGN_INFO',
        'ROLL_OUT_UPDATE_SESSIONS',
        'ROLL_OUT_SESSIONS_UPLOAD',
        'EMAIL_TEMPLATE_EDITOR',
        'EMAIL_TEMPLATE_PREVIEW'
      ].indexOf(name) < 0
  )
)

/**
 * This is currently used to help finding the next valid step to move to
 * when the user clicks "Save & Continue"
 *
 * @type {{[p: string]: string}}
 */
export const STEP_REDIRECTS = {
  [STEP.ROLL_OUT_UPDATE_SESSIONS]: STEP.ROLL_OUT_TYPE,
  [STEP.ROLL_OUT_SESSIONS_UPLOAD]: STEP.ROLL_OUT_TYPE,
  [STEP.EMAIL_TEMPLATE_EDITOR]: STEP.SCHEDULING_AND_EMAILS,
  [STEP.EMAIL_TEMPLATE_PREVIEW]: STEP.SCHEDULING_AND_EMAILS,
  [STEP.REVIEW]: HM_CAMPAIGNS_BASE_PATH
}

export const WIZARD_MODAL_SIZE = {
  MEDIUM: 'medium',
  MEDIUM_BLANK: 'medium-blank',
  LARGE: 'large',
  FULL_SCREEN: 'full-screen'
}

export const BREADCRUMB = {
  [STEP.CAMPAIGN_INFO]: 'Campaign Info',
  [STEP.PARTICIPANTS]: 'Participants',
  [STEP.ROLL_OUT_TYPE]: 'Rollout Type',
  [STEP.SCHEDULING_AND_EMAILS]: 'Scheduling & Emails',
  [STEP.REVIEW]: 'Confirm'
}

export const TITLE = {
  [STEP.NEW_CAMPAIGN_INFO]: 'Enter campaign info',
  [STEP.CAMPAIGN_INFO]: 'Enter campaign info',
  [STEP.PARTICIPANTS]: 'Specify your participants',
  [STEP.ROLL_OUT_TYPE]: 'How do you want to roll out this campaign?',
  [STEP.SCHEDULING_AND_EMAILS]: 'Schedule & customize campaign rollout',
  [STEP.REVIEW]: 'Confirm & schedule your campaign'
}

export const ROLLOUT_TYPE = {
  PEER_SCHEDULING: 1,
  PREDEFINED_LEADS: 2,
  MANAGER_LED: 3
}

export const ROLLOUT_TYPE_NAME = {
  1: 'Peer Scheduling',
  2: 'Predefined Leads'
}

export const CAMPAIGN_TYPE = [
  {
    value: 1,
    label: 'Mandatory',
    tooltipText:
      'Participants will be scored based on attendance (must complete at least 50% of session).'
  },
  {
    value: 3,
    label: 'Optional (AKA extra credit)',
    tooltipText:
      'Participation may boost score. Non-participation will not adversely affect an individual’s score.'
  },
  {
    value: 2,
    label: 'Just a test',
    tooltipText:
      'Test campaigns will run like a normal campaign, but participation will not impact anyone’s scores.'
  }
]

/**
 * Translates to ROLLOUT_TYPE
 * @type {{"1": string, "2": string, "3": string}}
 */
export const EMAIL_TEMPLATE_TYPES = {
  1: 'hm_group-scheduling_participant_template',
  2: 'hm_predefined-leaders_template'
}

export const CAMPAIGN_STATUS = {
  ALL: '',
  DRAFT: 'D',
  SCHEDULED: 'NS',
  IN_PROGRESS: 'I',
  CANCELLED: 'CN',
  COMPLETED: 'C',
  ARCHIVED: 'A'
}

export const CAMPAIGN_STATUS_NAME = {
  [CAMPAIGN_STATUS.ALL]: 'All',
  [CAMPAIGN_STATUS.DRAFT]: 'Draft',
  [CAMPAIGN_STATUS.SCHEDULED]: 'Scheduled',
  [CAMPAIGN_STATUS.IN_PROGRESS]: 'In Progress',
  [CAMPAIGN_STATUS.CANCELLED]: 'Cancelled',
  [CAMPAIGN_STATUS.COMPLETED]: 'Completed',
  [CAMPAIGN_STATUS.ARCHIVED]: 'Archived'
}

export const CAMPAIGN_BUTTONS = {
  EDIT: 'edit',
  COPY: 'copy',
  REMOVE: 'remove',
  CANCEL: 'cancel',
  ARCHIVE: 'archive'
}

export const LANGUAGES = {
  'en-US': 'English',
  'fr-FR': 'French',
  'de-DE': 'German',
  'es-ES': 'Spanish'
}
export const LANGUAGES_INVERTED = invert(LANGUAGES)

export const BEHAVIOR_TAGS = [
  'hackersmind-behavior-phishing',
  'hackersmind-behavior-malware',
  'hackersmind-behavior-passwords',
  'hackersmind-behavior-tailgating',
  'hackersmind-behavior-misconfigured services'
]

export const HM_WIZARD_EVENT_API_ERROR = 'EP-ERROR:CAMPAIGNS:API'

export const linkDemoFile =
  'https://docs.google.com/spreadsheets/d/1KTQBPiDZJfVMLL3emTJz4LKuuNC92AlyzvwEkeoRv9E/export?format=csv'
export const customerSuccessLink = 'mailto:Support@elevatesecurity.com'

export const NOT_AT_ALL = 1
export const PROBABLY_NOT = 2
export const NEUTRAL = 3
export const LIKELY = 4
export const EXTREMELY_LIKELY = 5
export const EMAIL_TEMPLATE_EMPTY_VALUE = 'None'

export const surveyStrings = {
  [NOT_AT_ALL]: 'hm.constants.survey_answers.not_at_all',
  [PROBABLY_NOT]: 'hm.constants.survey_answers.probably_not',
  [NEUTRAL]: 'hm.constants.survey_answers.neutral',
  [LIKELY]: 'hm.constants.survey_answers.likely',
  [EXTREMELY_LIKELY]: 'hm.constants.survey_answers.extremely_likely'
}

export const surveyValues = {
  [NOT_AT_ALL]: 'Not at all likely',
  [PROBABLY_NOT]: 'Probably not likely',
  [NEUTRAL]: 'Neutral',
  [LIKELY]: 'Likely',
  [EXTREMELY_LIKELY]: 'Extremely likely'
}

export const surveyOptionsMapping = {
  not_at_all_likely: 'Not at all likely',
  probably_not_likely: 'Probably not likely',
  neutral: 'Neutral',
  likely: 'Likely',
  extremely_likely: 'Extremely likely'
}

// reverse mapping of surveyValues
export const surveyStringToValues = {}
Object.keys(surveyValues).forEach((k) => (surveyStringToValues[surveyValues[k]] = k))

export const CAMPAIGN_OVERVIEW_CSV_PROPERTIES = {
  rooms: [
    'location',
    'role',
    'start_time',
    'end_time',
    'timezone',
    'room',
    'room_size',
    'dial_in',
    'leader'
  ],
  enrollment: [
    'location',
    'role',
    'start_time',
    'end_time',
    'timezone',
    'enrollment',
    'room',
    'dial_in',
    'leader',
    'participants'
  ],
  data: [
    'first_name',
    'last_name',
    'title',
    'department',
    'email',
    'completed',
    'date_completed',
    'bug',
    'goal',
    'key_asset',
    'survey',
    'leader_interest',
    'winning_team_member',
    'attack_vector'
  ]
}

export const HM_ASSETS_URL = 'https://hm-assets.elevatesecurity.com/master/hm'

export const STATIC_EMAIL_TEMPLATES = {
  DEFAULT_SCHEDULED_SESSION_CONF: 'scheduled_session_confirmation',
  DEFAULT_ONE_DAY_REMAINDER: 'one_day_reminder',
  DEFAULT_ONE_HOUR_REMAINDER: 'one_hour_reminder'
}

export const STATIC_EMAIL_TEMPLATES_INFO = {
  DEFAULT_SCHEDULED_SESSION_CONF: {
    title: 'Default Scheduled Session Confirmation',
    subject: "Hacker's Mind Session Registration"
  },
  DEFAULT_ONE_DAY_REMAINDER: {
    title: 'Default One Day Remainder',
    subject: "Reminder: Your Hacker's Mind Session is scheduled for tomorrow"
  },
  DEFAULT_ONE_HOUR_REMAINDER: {
    title: 'Default One Hour Remainder',
    subject: "Reminder: Your Hacker's Mind Session starts in 1 hour"
  }
}

export const TARGETING_TYPES_STR = {
  ANY: 'Employees who belong at one of these groups',
  ALL: 'Employees who belong to all these groups'
}

export const TARGETING_TYPES = {
  ANY: 1,
  ALL: 2
}
