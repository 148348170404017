import styled from 'styled-components'
/** *
 * todo: The component Link from the elevate library doesn't support custom styles
 */
import { Layout, Typography } from '@elevate_security/elevate-component-library'
import { Link as LibLink } from 'react-router-dom'

const { H4 } = Typography

export const ListItemContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #eef0f3;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
`

export const FlexRow = styled.div`
  display: flex;
  align-items: baseline;
`

export const Title = styled(H4).attrs({ fontWeight: 'bold' })`
  margin-bottom: 6px;
  &:hover {
    color: #039ba1;
    cursor: pointer;
  }
`

export const Link = styled(LibLink)`
  color: #7b828a;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  span {
    text-decoration: underline;
  }
`

export const TitleLink = styled(LibLink)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const ItemListButtons = styled(Layout)`
  justify-content: flex-end;
  padding: 0 15px;
  button {
    padding: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    svg path {
      fill: #959da8;
    }
    svg line {
      stroke: #959da8;
    }
    &:hover {
      background-color: transparent;
      svg path {
        fill: #039ba1;
      }
      svg line {
        stroke: #039ba1;
      }
    }
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
    &:disabled,
    .disabled {
      background-color: transparent !important;
    }

    &[data-icon-name='Database'] {
      padding: 3px 5px;
      span {
        display: block;
        width: 28px;
        height: auto;
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`
