import styled from 'styled-components'
import { FlexColumn } from '../../../../../../common.styles'

export const Wrapper = styled(FlexColumn)`
  position: relative;
  min-width: 100%;
  max-width: 100%;
`

export const TableContainer = styled.div`
  display: block;
  min-width: 100%;
  max-width: 100%;
  overflow-x: scroll;
`
export const TableHeader = styled.div`
  display: flex;
  min-width: 100%;
  overflow: visible;
  font-weight: bold;
`
export const TableBody = styled.div`
  display: flex;
  width: 100%;
`

export const RowContainer = styled.div`
  flex: 1;
  min-width: 1200px;
`

export const TableRow = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid #eef0f3;
  opacity: ${(props) => (props.fadeRow ? 0.6 : 1)};
`

export const TableData = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 5px;
  width: ${({ defaultWidth }) => defaultWidth};
  min-width: ${({ defaultWidth, minWidth }) => minWidth || defaultWidth};
  flex: 0 0 ${({ defaultWidth }) => defaultWidth};
  flex-wrap: nowrap;
  transition: ease width 50ms, ease min-width 50ms, ease flex 50ms;
  font-size: 14px;
  margin: 0 5px;
  line-height: 24px;

  &.checkbox-cell {
    padding-left: 5px;
  }

  &[role='cell'] {
    height: auto;
    min-height: 40px;
    overflow: hidden;
    ${(props) =>
      props.isActionCell
        ? `
      border-left: 1px solid #eef0f3;
      padding-left: 8px;
    `
        : ''}
  }

  input {
    width: 100%;
  }

  &.data-cell {
    [class^='CustomSelectstyles__CustomSelectContainer'] {
      width: 100%;
    }
    .rbt-input-hint-container input {
      line-height: 38px;
      padding: 0 8px;
      border: 1px solid rgba(189, 197, 209, 0.5);
      border-radius: 5px;
      width: 100%;
      &:hover {
        border: 1px solid rgba(58, 70, 85, 0.3);
      }
      &:focus {
        border: 1px solid rgb(12, 125, 132);
        box-shadow: rgb(12, 125, 132) 0px 0px 3px;
      }
    }
  }

  div[data-has-error='true'] input:not(:hover):not(:focus) {
    border: 1px solid rgb(199, 52, 52);
  }
`

export const HeadTextWrapper = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const FixedRightSection = styled.div`
  position: absolute;
  right: 0px;
  background: #ffffff;
  z-index: 1;
`
