import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { Typography } from '@elevate_security/elevate-component-library'
import { Row, ScoreBox, Value, Hr } from './styles'

const { Text } = Typography

function ScoreBoxes({ surveyCount, formattedSurveyScores }) {
  if (!surveyCount) {
    return (
      <Row justify="left">
        {formattedSurveyScores.map(({ title, icon, value, color, displayTitle }) => (
          <ScoreBox key={title}>
            {icon}
            <Hr />
            <Text>{displayTitle}</Text>
            <Value style={{ color }}>{`${value}%`}</Value>
          </ScoreBox>
        ))}
      </Row>
    )
  }
  return (
    Array.isArray(formattedSurveyScores) && (
      <>
        <Row justify="center">
          {formattedSurveyScores.slice(0, 3).map(({ title, icon, value, color, displayTitle }) => (
            <ScoreBox key={title}>
              {icon}
              <Hr />
              <Text>{displayTitle}</Text>
              <Value style={{ color }}>{`${value}%`}</Value>
            </ScoreBox>
          ))}
        </Row>
        <Row justify="center">
          {formattedSurveyScores.slice(3, 5).map(({ title, icon, value, color, displayTitle }) => (
            <ScoreBox key={title}>
              {icon}
              <Hr />
              <Text>{displayTitle}</Text>
              <Value style={{ color }}>{`${value}%`}</Value>
            </ScoreBox>
          ))}
        </Row>
      </>
    )
  )
}

ScoreBoxes.propTypes = {
  surveyCount: PropTypes.bool,
  formattedSurveyScores: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      displayTitle: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired
}

ScoreBoxes.defaultProps = {
  surveyCount: false
}

export default memo(ScoreBoxes)
