import styled from 'styled-components'
import { Typography, theme } from '@elevate_security/elevate-component-library'

const { Text } = Typography

export const NavContainer = styled.nav`
  flex: 1;
`

export const NavList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
`

export const NavItem = styled.a.attrs((props) => ({
  fontWeight: props.crumbIndex === props.wizardIndex ? 'bold' : 'normal',
  color: props.crumbIndex <= props.userIndex && !props.disabled ? '#0C7D84' : 'black',
  colorHover: props.crumbIndex <= props.userIndex && !props.disabled ? '#039ba1' : 'black',
  textDecoration: props.crumbIndex <= props.userIndex && !props.disabled ? 'underline' : 'none',
  opacity: props.crumbIndex <= props.userIndex && !props.disabled ? 1 : 0.7,
  cursor: props.disabled ? 'not-allowed' : 'pointer'
}))`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${theme.breadcrumb.fontSize};
  padding: 0 15px 0 10px;
  color: ${theme.breadcrumb.currentPageColor};
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  position: relative;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: ${(props) => props.colorHover};
    text-decoration: ${(props) => props.textDecoration};
    cursor: ${(props) => props.cursor};
  }

  & > svg {
    height: 10px;
    opacity: 0.6;
    position: absolute;
    right: -3px;
    top: 6px;
  }
`

export const SpanItem = styled(Text)`
  display: inline-block;
  position: relative;
  padding: 0 10px;
  opacity: ${(props) => (props.crumbIndex === props.wizardIndex ? 1 : 0.4)};
  font-weight: ${(props) => (props.crumbIndex === props.wizardIndex ? 'bold' : 'normal')};
  color: ${(props) => (props.crumbIndex <= props.wizardIndex ? '#0C7D84' : 'black')};
  white-space: nowrap;

  & > svg {
    height: 10px;
    opacity: 0.6;
    position: absolute;
    right: -5px;
    top: 3px;
  }
`
