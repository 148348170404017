import Moment from 'moment'
import { TIME_ZONES } from '@elevate_security/elevate-component-library'
import { FormManager } from '../../../../services/form'
import { ControlType } from '../../../../services/form.types'
import { Constrain } from '../../../../services/validator'
import { getEmailTemplateOptions } from './utils'

export const HM_SCHEDULING_AND_EMAILS_FORM_NAME = 'scheduling-emails-form'

const timeZoneOptions = Object.keys(TIME_ZONES).map((key) => ({
  label: key,
  value: TIME_ZONES[key]
}))

export const getDatesValidations = (data, startBefore, endAfter) => ({
  start_date: [
    { test: Constrain.REQUIRED, value: true, message: 'Start date is required' },
    {
      test: Constrain.DATE,
      value: true,
      after: Moment(),
      message: 'Start time cannot be in the past',
      timezone: data.timezone
    },
    // campaign start date must be before or equal to earliest session start time
    ...(startBefore
      ? [
          {
            test: Constrain.DATE,
            value: true,
            sameOrBefore: startBefore,
            message: 'Start time must be before session',
            timezone: data.timezone
          }
        ]
      : [])
  ],
  end_date: [
    { test: Constrain.REQUIRED, value: true, message: 'End date is required' },
    {
      test: Constrain.DATE,
      value: true,
      after: data.start_date || Moment(),
      message: 'End time must be after start time',
      timezone: data.timezone
    },
    // campaign end date must be after or equal to last session end time
    ...(endAfter
      ? [
          {
            test: Constrain.DATE,
            value: true,
            sameOrAfter: endAfter,
            message: 'End time must be after session',
            timezone: data.timezone
          }
        ]
      : [])
  ]
})

export const getSchedulingAndEmailsForm = ({ data, emailTemplates }) => {
  const templateEmailOptions = getEmailTemplateOptions(emailTemplates, data)
  const cacheKey = `${HM_SCHEDULING_AND_EMAILS_FORM_NAME}-${data.id}`

  return new FormManager(
    HM_SCHEDULING_AND_EMAILS_FORM_NAME,
    {
      id: {
        type: ControlType.HIDDEN,
        dirty: true
      },
      start_date: {
        type: ControlType.DATE,
        validations: getDatesValidations(data).start_date
      },
      end_date: {
        type: ControlType.DATE,
        validations: getDatesValidations(data).end_date
      },
      timezone: {
        defaultValue: '',
        options: timeZoneOptions,
        type: ControlType.SELECT,
        validations: [{ test: Constrain.REQUIRED, value: true, message: 'Timezone is required' }]
      },
      participant_template: {
        defaultValue: templateEmailOptions[0],
        options: templateEmailOptions,
        type: ControlType.SELECT
      },
      do_not_send_emails: {
        type: ControlType.BOOL,
        value: data.do_not_send_emails || null
      },
      leader_template: {
        type: ControlType.HIDDEN
      }
    },
    data
  )
    .addBeforeSubmitCallBack((form) => {
      const do_not_send_emails = form.value('do_not_send_emails')
      let participant_template = form.value('participant_template')
      participant_template = !do_not_send_emails
        ? !participant_template
          ? templateEmailOptions[0].value
          : participant_template
        : null

      return form
        .update('participant_template', { value: participant_template })
        .update('leader_template', { value: participant_template })
        .clearCache(cacheKey)
    })
    .restore(cacheKey, data, false)
}
