import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { InputErrorsContainer, FieldSetError } from './styles'

function InputErrors({ errors, relative, multiple }) {
  const [enter, setEnter] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setEnter(true)
    }, 100)
  }, [])

  return (
    <CSSTransition in={enter} classNames="error" timeout={200}>
      <InputErrorsContainer data-id="hm-form-input-errors" relative={relative}>
        {errors.map(
          (error, index) =>
            (multiple || (!multiple && index === 0)) && <FieldSetError>{error}</FieldSetError>
        )}
      </InputErrorsContainer>
    </CSSTransition>
  )
}

InputErrors.propTypes = {
  relative: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  multiple: PropTypes.bool
}

InputErrors.defaultProps = {
  relative: false,
  multiple: false
}

export default InputErrors
