import styled from 'styled-components'

export const FixedEditor = styled.section`
  min-height: 100%;
  height: 100%;

  .rdw-editor-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    .rdw-editor-main {
      flex: 1;
    }
  }
`
