import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import GlobalStyle from '@elevate_security/elevate-component-library/dist/GlobalStyle'
import { HM_BASE_PATH, HM_CAMPAIGNS_BASE_PATH, HM_OVERVIEW_BASE_PATH } from './constants'
import Campaigns from './scenes/Campaigns'
import CampaignOverview from './scenes/CampaignOverview'
import { GlobalStyles as HMLevelGlobalStyles } from './global.styles'
import 'react-datepicker/dist/react-datepicker.css'

function HackersMindMVP() {
  useEffect(() => {})

  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route
            path={[
              `${HM_OVERVIEW_BASE_PATH}/:id`,
              `${HM_OVERVIEW_BASE_PATH}/:id/manage-sessions`,
              `${HM_OVERVIEW_BASE_PATH}/:id/manage-sessions/upload`
            ]}
            component={CampaignOverview}
          />
          <Route path={HM_CAMPAIGNS_BASE_PATH} component={Campaigns} />
          <Redirect from={HM_BASE_PATH} to={HM_CAMPAIGNS_BASE_PATH} />
        </Switch>
        <GlobalStyle />
        <HMLevelGlobalStyles />
      </div>
    </BrowserRouter>
  )
}

export default HackersMindMVP
