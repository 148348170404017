import React from 'react'
import PropTypes from 'prop-types'
import dompurify from 'dompurify'
import { ButtonIcon } from '@elevate_security/elevate-component-library'
import { Container, ToastWrapper, ButtonContainer, toastTheme } from './styles'
import * as campaignActions from '../../services/redux/campaigns/actions'
import { useActions } from '../../scenes/CampaignWizard/utils'

function CampaignToasts(props) {
  const { messages, fixed, width, onRemove } = props
  const { closeCampaignMessage } = useActions(campaignActions)
  const sanitizer = dompurify.sanitize

  if (!messages || !messages.length) {
    return null
  }

  return (
    <Container fixed={fixed} width={width} count={messages.length} data-id="hm-toast-container">
      {messages.map((config) => {
        const { id, level, message, children } = config
        return (
          <ToastWrapper
            key={id}
            fixed={fixed}
            onClick={() => closeCampaignMessage(id)}
            level={level}
            data-id={`hm-toast-${id}`}
          >
            {message && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(message)
                }}
              />
            )}
            {children && <div>{children}</div>}
            <ButtonContainer stroke={toastTheme.ToastBorder[level]}>
              {' '}
              <ButtonIcon icon="Close" size="sm" theme="ghost" onClick={() => onRemove(config)} />
            </ButtonContainer>
          </ToastWrapper>
        )
      })}
    </Container>
  )
}

CampaignToasts.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      message: PropTypes.node.isRequired,
      children: PropTypes.element,
      level: PropTypes.oneOf(['success', 'warn', 'info', 'error'])
    })
  ),
  width: PropTypes.string,
  fixed: PropTypes.bool,
  onRemove: PropTypes.func
}

CampaignToasts.defaultProps = {
  messages: [],
  fixed: false,
  width: '660px',
  onRemove: () => null
}

export default CampaignToasts
