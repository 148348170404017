import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledDropDown,
  StyledDropDownHeader,
  StyledDropDownItem,
  StyledDropDownMenu,
  StyledDropDownToggle
} from './styles'

function Dropdown(props) {
  const { options, id, className, style, dropdownStyle, title, buttonStyle } = props

  const renderButton = () => (
    <StyledDropDownToggle
      className={className || ''}
      style={style}
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      disabled={!options.length}
    >
      {title || <i className="fa fa-cog" aria-hidden="true" />}
    </StyledDropDownToggle>
  )

  const renderOption = (index, { header, component, name, disabled, action }) =>
    header ? (
      <StyledDropDownHeader key={index.toString()}>{component || name}</StyledDropDownHeader>
    ) : (
      // eslint-disable-next-line react/button-has-type
      <StyledDropDownItem
        key={index.toString()}
        disabled={disabled}
        style={buttonStyle}
        onClick={() => action({ id, name })}
      >
        {component || name}
      </StyledDropDownItem>
    )

  return (
    <StyledDropDown>
      {renderButton()}
      <StyledDropDownMenu
        className="dropdown-menu dropdown-menu-right"
        style={dropdownStyle}
        aria-labelledby="dropdownMenuButton"
      >
        {options.map((option, i) => renderOption(i, option))}
      </StyledDropDownMenu>
    </StyledDropDown>
  )
}
Dropdown.defaultProps = {
  id: null,
  className: null,
  style: undefined,
  dropdownStyle: {},
  title: null,
  buttonStyle: undefined
}

Dropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.bool,
      // eslint-disable-next-line react/forbid-prop-types
      component: PropTypes.object,
      name: PropTypes.string,
      disabled: PropTypes.bool,
      action: PropTypes.func
    })
  ).isRequired,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  dropdownStyle: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string,
  buttonStyle: PropTypes.oneOfType([PropTypes.object])
}
export default Dropdown
