import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@elevate_security/elevate-component-library'
import Typography from '@elevate_security/elevate-component-library/dist/Typography'

import { CAMPAIGN_STATUS, CAMPAIGN_STATUS_NAME } from '../../../../constants'
import { TabsContainer } from './styles'

const { Text } = Typography
function Filter(props) {
  const { onChange, active, campaignStatuses, currentTab } = props
  const [activeTab, setActiveTab] = useState(currentTab)

  const status = Object.values(CAMPAIGN_STATUS)
  const countByStatus =
    campaignStatuses &&
    campaignStatuses.reduce((obj, item) => Object.assign(obj, { [item.status]: item.total }), {})

  if (countByStatus) {
    Object.keys(countByStatus).forEach((key) => {
      if (!countByStatus['']) {
        countByStatus[''] = 0
      }
      countByStatus[''] += countByStatus[key]
    })
  }

  const handleOnChange = (key) => {
    if (typeof onChange === 'function') onChange(key)
    setActiveTab(key)
  }

  const colorTab = (key) => (activeTab === key ? '900' : '500')
  const weightTab = (key) => (activeTab === key ? 'bold' : 'medium')

  const title = (key) => (
    <Text color={colorTab(key)} fontWeight={weightTab(key)}>
      {CAMPAIGN_STATUS_NAME[key]}
      <Text color={colorTab(key)} fontWeight={weightTab(key)} padding="0 0 0 2px">
        ({countByStatus && countByStatus[key] ? countByStatus[key] : 0})
      </Text>
    </Text>
  )

  return (
    <TabsContainer active={active} data-id="hm-campaigns-tabs">
      <Tabs onChange={(index) => handleOnChange(status[index])}>
        {status.map((key) => (
          <Tab title={title(key)} key={key} selected={key === activeTab} />
        ))}
      </Tabs>
    </TabsContainer>
  )
}
Filter.defaultProps = {
  campaignStatuses: [],
  currentTab: ''
}
Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  currentTab: PropTypes.string,
  campaignStatuses: PropTypes.shape([])
}

export default memo(Filter)
