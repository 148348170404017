import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 5px 10px 15px;
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #959da8;
    left: 0;
    top: 14px;
  }
  &:after {
    content: '';
    display: ${({ isLastBullet }) => (!isLastBullet ? 'block' : 'none')};
    position: absolute;
    width: 1px;
    height: calc(100% - 5px);
    background-color: #959da8;
    left: 3px;
    top: 20px;
  }
`
export const Label = styled.div`
  width: 50%;
`
export const InputBox = styled.div`
  width: 50%;
  margin-right: 0;
  margin-left: auto;
`
