import React from 'react'
import invert from 'lodash/invert'
import { TIME_ZONES, PROMINENT_TIME_ZONES } from '@elevate_security/elevate-component-library'
import { STEPS, STEP, STEP_REDIRECTS } from '../../constants'
import { getStepIndex } from '../../scenes/CampaignWizard/utils'

const invertedTimezones = invert(TIME_ZONES)

export const getCompany = () => JSON.parse(localStorage.getItem('company'))

export const hmSelector = (state) => state.hmReducer || {}

export const getUser = (state) => hmSelector(state).me

export const getBreakpoints = (state) => hmSelector(state).mediaQuery

export const getOrganizationId = (state) => getCompany(state).id

export const isShortVersionEnabled = (state) => getCompany(state).enable_hm_short_demo

export const wizardSelector = (state) => state.wizardReducer || {}

export const getWizardForm = (state) => wizardSelector(state).wizardForm

export const getCampaignInstance = (state) => wizardSelector(state).campaign

export const getCampaignId = (state) => wizardSelector(state).campaign.id()

export const getWizardCurrentStep = (state) => wizardSelector(state).wizardStep

export const getCurrentStepIndex = (state) => getStepIndex(getWizardCurrentStep(state))

export const getDefaultTimezone = (state) => (getCompany(state) || {}).default_timezone

export const getDefaultTimezoneOption = (state) =>
  getTimezoneOptions(state).find((option) => option.value === getDefaultTimezone(state))

export const getTimezoneOptions = (state) => {
  const companyDefaultTimezone = getDefaultTimezone(state)
  const opts = []

  // company's default timezone
  if (companyDefaultTimezone) {
    opts.push({
      label: invertedTimezones[companyDefaultTimezone],
      value: companyDefaultTimezone
    })
  }

  // some prominent timezones
  Object.keys(PROMINENT_TIME_ZONES).forEach((key) => {
    if (!companyDefaultTimezone || PROMINENT_TIME_ZONES[key] !== companyDefaultTimezone) {
      opts.push({
        label: key,
        value: PROMINENT_TIME_ZONES[key]
      })
    }
  })

  // divider
  opts.push({
    group: 'OTHER TIMEZONES',
    render: <hr />
  })

  // other timezones
  Object.keys(TIME_ZONES).forEach((key) => {
    if (!PROMINENT_TIME_ZONES[key]) {
      opts.push({
        label: key,
        value: TIME_ZONES[key]
      })
    }
  })

  return opts
}

export const shouldRedirect = (state) => {
  const currentUserStep = getWizardCurrentStep(state)
  if (currentUserStep in STEP_REDIRECTS) {
    return STEP_REDIRECTS[currentUserStep]
  }

  return null
}

export const getWizardNextStep = (state) => {
  const currentStepIndex = getCurrentStepIndex(state)
  return STEPS.reduce((rs, step, index) => {
    if (index === currentStepIndex + 1) {
      return STEP[step]
    }
    return rs
  }, null)
}

export const getWizardPreviousStep = (state) => {
  const currentStepIndex = getCurrentStepIndex(state)
  return STEPS.reduce((rs, step, index) => {
    if (index === currentStepIndex - 1) {
      return STEP[step]
    }
    return rs
  }, null)
}

export const getNextStep = (state, mode) => {
  if (mode === 'previous') {
    return getWizardPreviousStep(state)
  }

  const redirect = shouldRedirect(state)

  if (redirect) {
    return redirect
  }

  return getWizardNextStep(state)
}

export const canSkipWizardStep = (state, mode, exactStep) => {
  const form = getWizardForm(state)
  const campaign = getCampaignInstance(state)
  const nextStep = getNextStep(state, mode)

  if (!form) {
    return true
  }

  const exactStepIndex = getStepIndex(exactStep)

  switch (mode) {
    case 'previous':
      return (!form.valid() || !form.isDirty()) && getStepIndex(nextStep) >= 0
    case 'final':
      return !form.valid() || !form.isDirty()
    case 'exact':
      return (
        (!form.valid() || !form.isDirty()) &&
        exactStepIndex >= 0 &&
        exactStepIndex <= campaign.getCurrentStep()
      )
    default:
      return false
  }
}

export const getEmailTemplate = (id) => {
  return (state) => {
    const { emailTemplates } = wizardSelector(state)
    const templates = emailTemplates ? emailTemplates.results : []
    return templates.find((template) => template.id === id) || null
  }
}

export const campaignsSelector = (state) => state.campaignsReducer || {}
export const getListCampaign = (state, id) =>
  campaignsSelector(state).campaignList.find((camp) => id === camp.id)
export const getCampaignListLoading = (state) => campaignsSelector(state).loading
export const getFilteredCampaigns = (state) => campaignsSelector(state).campaignListFiltered
export const getCampaignsCurrentTab = (state) => campaignsSelector(state).currentTab
