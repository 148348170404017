import styled from 'styled-components'
import { FlexColumn, FlexRow } from '../../../../common.styles'

export const Header = styled(FlexRow)`
  margin-top: 24px;
  padding: 24px;
  width: 100%;
  background: rgb(245, 246, 248);
`

export const SessionContainer = styled(FlexColumn)`
  position: relative;
`
