import styled, { css } from 'styled-components'
import { theme } from '@elevate_security/elevate-component-library'
import { FlexRow } from '../../common.styles'

export const ErrorMessageContainer = styled(FlexRow)`
  height: 0;
  opacity: 0;
  min-height: 0;
  background-color: ${theme.colors.bg.errorInverse};
  align-items: center;
  justify-content: center;
  transition: height 130ms, min-height 130ms, opacity 70ms, margin-bottom 70ms;
  animation-timing-function: ease-in-out;
  position: sticky;
  z-index: 100;
  top: -25px;
  margin-top: -25px;
  margin-bottom: 10px;
  min-width: 100%;
  padding: 0;
  ${(props) =>
    props.hasMessage &&
    css`
      height: auto;
      min-height: auto;
      opacity: 1;
      margin-bottom: 10px;
      padding: 16px 0;
    `}
`

export const MessageBody = styled.div`
  flex: 1;
  margin: 0;

  & > small,
  & > p {
    color: ${theme.colors.white};
    display: block;
  }

  & > small {
    color: #4e1717;
  }
`

export const MessageDismissButton = styled.div`
  flex: 0 1 auto;

  button {
    color: ${theme.colors.white};
  }

  span {
    display: block;
    line-height: 40px;
  }

  svg {
    stroke: ${theme.colors.white};
  }
`

export const ReloadLink = styled.a`
  &:not([href]):not([tabindex]) {
    color: #4e1717;
    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }
`
