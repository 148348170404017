import { createRequest, METHOD } from '../api'
import axios from './axios-config'

export const fetchUser = createRequest(
  () =>
    axios({
      method: METHOD.GET,
      url: '/employee/me/'
    }),
  {
    400: `<p>Error while fetching current logged-in user.<p>`,
    404: `<p>The requested user was not found in our database.</p>`
  }
)
