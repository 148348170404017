import styled from 'styled-components'

export const IconsRow = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const SquareIconWrapper = styled.span`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  height: 40px;
  width: 40px;
  border: 1px solid #0c7d84;
  border-radius: 4px;
  svg {
    margin: auto;
  }
  ${({ right }) =>
    right &&
    `margin-left: 4px;
    background-color: #0C7D84;
  `}
`

export const IconWrapper = styled.span`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  svg path {
    fill: #959da8;
  }
  svg line {
    stroke: #959da8;
  }
  ${(props) =>
    props.disabled
      ? ''
      : `
    &:hover {
      background-color: transparent;
      svg path {
        fill: #039ba1;
      }
      svg line {
        stroke: #039ba1;
      }
    }
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
  `}
`

export const CheckboxHeader = styled.span`
  display: flex;
  align-items: center;
  [class^='CustomSelectstyles__InputContainer'] {
    height: 20px;
  }
  ul[class^='CustomSelectstyles__OptionsContainer'] {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  input[type='checkbox'] {
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  }
  input[type='text'] {
    height: 20px;
    width: 25px;
    cursor: ${(props) => (props.disabled ? '' : 'pointer')};
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  .dropdown-container {
    width: auto;
    top: 20px;
    transform: none !important;
    z-index: 999;
  }
`

export const TextWrapper = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const LoaderWrapper = styled.div`
  position: relative;
  > div {
    left: 25px;
    top: -12px;
  }
`
