import PropTypes from 'prop-types'
import React from 'react'
import Moment from 'moment'
import Picker from 'react-datepicker'
import { Container } from './styles'

/**
 * import 'react-datepicker/dist/react-datepicker.css' at
 * some global level to apply styles for react-datepicker
 */

function DatePicker({ value, onChange, invalid, minDate, maxDate, minTime, maxTime }) {
  const handleChange = (e) => {
    const val = e ? Moment(e).format().slice(0, 16) : null
    onChange({ value: val })
  }

  const hasTimeRange = minTime || maxTime

  const rangeProps = {
    ...(minDate && { minDate }),
    ...(maxDate && { maxDate }),
    /**
     * if adding time range to react-datepicker
     * then it is cumpulsory to provide both time limits
     */
    ...(hasTimeRange && {
      minTime: minTime || Moment().startOf('day'),
      maxTime: maxTime || Moment().endOf('day')
    })
  }

  return (
    <Container invalid={invalid}>
      <Picker
        type="date"
        placeholderText="Click to select"
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat="l, LT"
        selected={value ? Moment(value) : null}
        onChange={handleChange}
        {...rangeProps}
      />
    </Container>
  )
}

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  minDate: PropTypes.instanceOf(Moment),
  maxDate: PropTypes.instanceOf(Moment),
  minTime: PropTypes.instanceOf(Moment),
  maxTime: PropTypes.instanceOf(Moment)
}

DatePicker.defaultProps = {
  invalid: false,
  minDate: null,
  maxDate: null,
  minTime: null,
  maxTime: null
}

export default DatePicker
