import { FormManager } from '../../../../services/form'
import { ControlType } from '../../../../services/form.types'
import { Constrain } from '../../../../services/validator'
import { createCampaignMessage } from '../../../../services/redux/campaigns/actions'
import { CAMPAIGN_STATUS, STEP } from '../../../../constants'
import { getCampaignMessage } from '../../../Campaigns/utils'

export const HM_REVIEW_FORM_NAME = 'hm-review-form'

export const getConfirmForm = ({ campaign }) => {
  const data = campaign.data()
  const status = data.status !== CAMPAIGN_STATUS.DRAFT
  return new FormManager(
    HM_REVIEW_FORM_NAME,
    {
      id: {
        type: ControlType.HIDDEN,
        dirty: true
      },
      status: {
        type: ControlType.TEXT,
        validations: [
          {
            test: Constrain.REQUIRED,
            value: true,
            message: 'Please confirm your campaign details.'
          }
        ]
      }
    },
    {
      id: campaign.id(),
      status
    }
  )
    .addBeforeSubmitCallBack((form, { mode }) => {
      const statusField = form.get('status')
      statusField.valid = true
      statusField.errors = null
      if (mode === 'next') {
        /**
         * If user clicks on next, this is the final step, so
         * set the status to scheduled if the confirm checkbox
         * was checked.
         */
        if (statusField.value) {
          statusField.value = CAMPAIGN_STATUS.SCHEDULED
        } else {
          /**
           * manual validation
           */
          statusField.valid = false
          statusField.errors = ['Please confirm your campaign details.']
        }
      } else {
        statusField.value = CAMPAIGN_STATUS.DRAFT
      }

      const updatedForm = form.update('status', { ...statusField }, true)

      return updatedForm
    })
    .addAfterSubmitCallBack((form, { mode, wizardStep, dispatch }) => {
      if (wizardStep === STEP.REVIEW && mode === 'next') {
        dispatch(
          createCampaignMessage(getCampaignMessage(campaign.properties.name, 'created & scheduled'))
        )
      }
    })
}
