import { Icon } from '@elevate_security/elevate-component-library'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FlexItem, FlexRow, TextCenter } from '../../../../common.styles'
import { SET_WIZARD_ERROR } from '../../../../services/redux/wizard/types'
import { IconContainer, MainTitle, SubTitle, StyledButtonLink } from './styles'

/**
 * This error handler is responsible to catch UI errors at runtime.
 * If any component enters an error state, it's caught by the `componentDidCatch`
 * method, and a message is displayed to the user.
 */
class ErrorHandler extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error
    }
  }

  componentDidCatch(error, errorInfo) {
    const { onError, dispatch } = this.props
    const data = { message: error.message, info: error, metadata: errorInfo }

    dispatch({
      type: SET_WIZARD_ERROR,
      data
    })

    onError(data)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <FlexRow justify="center" align="center" stretch>
          <FlexItem justify="center" align="center" width="60%">
            <IconContainer>
              <Icon name="CircleExclamation" fill="#c73434" size="xlg" />
            </IconContainer>
            <MainTitle>Sorry</MainTitle>
            <SubTitle>We have encountered a problem while processing your request.</SubTitle>
            <br />
            <FlexRow justify="center" align="center" stretch>
              <FlexItem justify="center" align="center" width="80%">
                <TextCenter>
                  Please try{' '}
                  <StyledButtonLink onClick={() => window.location.reload()}>
                    refreshing your page
                  </StyledButtonLink>{' '}
                  and if the problem persists, contact our support team.
                </TextCenter>
              </FlexItem>
            </FlexRow>
          </FlexItem>
        </FlexRow>
      )
    }

    return children
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.node.isRequired,
  onError: PropTypes.func,
  dispatch: PropTypes.func.isRequired
}

ErrorHandler.defaultProps = {
  onError: () => null
}

const mapDispatchToProps = (dispatch) => ({
  dispatch
})

export default connect(null, mapDispatchToProps)(ErrorHandler)
