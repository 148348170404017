import styled from 'styled-components'
import { FlexRow } from '../../../../../../common.styles'

export const ButtonRow = styled(FlexRow)`
  min-width: 64px;
  justify-content: flex-end;
  align-items: center;
  min-height: 100%;

  input {
    width: 240px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;

    &:first-child {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    &:disabled {
      opacity: 0;
      cursor: default;
    }
    svg path {
      fill: #959da8;
    }
  }

  button:hover {
    background: #fff;
    svg path {
      fill: #0c7d84;
    }
  }

  button:active,
  button:focus {
    box-shadow: none;
    background: #fff;
  }
`

export const TimelineContainer = styled.div`
  .dropdown-container {
    width: auto;
  }
`
