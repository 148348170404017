import styled from 'styled-components'
import { CAMPAIGN_STATUS } from '../../constants'

const badgeStatusColor = {
  [CAMPAIGN_STATUS.DRAFT]: '#EEF0F3',
  [CAMPAIGN_STATUS.SCHEDULED]: '#FFF3CA',
  [CAMPAIGN_STATUS.COMPLETED]: '#E3F6F2',
  [CAMPAIGN_STATUS.IN_PROGRESS]: '#E3ECF6',
  [CAMPAIGN_STATUS.CANCELLED]: '#F6E3E3',
  [CAMPAIGN_STATUS.ARCHIVED]: '#FFFFFF'
}

const Badge = styled.span`
  display: inline-block;
  background-color: ${({ badgeColor }) => badgeStatusColor[badgeColor]};
  ${({ badgeColor }) =>
    badgeColor === CAMPAIGN_STATUS.ARCHIVED ? 'border: 1px solid #C9CFD6;' : ''}
  border-radius: 12px;
  padding: 4px 8px;
  color: #565d66;
  font-size: 13px;
  line-height: 16px;
  margin-left: 8px;
  font-weight: 400;
`
export default Badge
