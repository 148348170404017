import styled from 'styled-components'

export const ModalWrapper = styled.div`
  [class^='Modalstyles__ModalHeader'] {
    padding-bottom: 13px;
  }
  [class^='CustomSelectstyles__OptionsContainer'] {
    max-height: 100px;
  }
  [class^='Modalstyles__ModalContainer'] {
    width: 625px;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: bold;
`

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-weight: normal;
`
export const CustomSelectWrapper = styled.div`
  position: absolute;
  right: 9%;
`

export const HeaderWrapper = styled.div`
  margin-top: 10px;
`
