import React, { Suspense, useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Header,
  Typography,
  Modal,
  Link
} from '@elevate_security/elevate-component-library'
import { Route, Switch, useParams, useLocation } from 'react-router-dom'
import { CampaignToasts } from '../../components'
import ActivityIndicator from '../../components/ActivityIndicator'
import Breadcrumbs from '../../components/Breadcrumbs'
import {
  HM_BASE_PATH,
  HM_CAMPAIGNS_BASE_PATH,
  HM_NEW_CAMPAIGN_PATH,
  HM_WIZARD_EVENT_API_ERROR,
  STEP,
  STEPS,
  TITLE,
  CAMPAIGN_STATUS,
  HM_OVERVIEW_BASE_PATH,
  WIZARD_MODAL_SIZE
} from '../../constants'
import { HM_SCHEDULING_AND_EMAILS_FORM_NAME } from './components/SchedulingStep/form'
import { wizardSelector } from '../../services/redux/selectors'
import * as wizardActions from '../../services/redux/wizard/actions'
import { SET_WIZARD_ERROR, UNLOAD_WIZARD } from '../../services/redux/wizard/types'
import ToastManager from '../../services/toast-manager'
import { FormManager } from '../../services/form'
import ErrorHandler from './components/ErrorHandler'
import {
  campaignOutOfSync,
  useActions,
  stepOutOfSync,
  getStepIndex,
  getStepFromPathName
} from './utils'
import { BodyContainer, HeaderMetadata, ModalComponent, SaveForLaterLink } from './styles'
import routes from './routes'

const { H2 } = Typography
const totalSteps = STEPS.length

function CampaignWizard(props) {
  const { history } = props
  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { id } = params
  const { campaign, wizardStep, wizardForm, wizardProps, behaviors, error } =
    useSelector(wizardSelector)
  const { submit, getCampaign, getTags, setWizardStep } = useActions(wizardActions)
  const [toastManager, setToastManager] = useState(new ToastManager([]))
  const [errorReady, setErrorReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState(null)

  /**
   * Clear previous wizard cache from local storage when opened
   */

  useEffect(() => {
    const form = new FormManager('dummy', {}, {})
    form.clearCache(`${HM_SCHEDULING_AND_EMAILS_FORM_NAME}-${id}`)
  }, [])

  /**
   * Load the campaign if there's no campaign set or if the
   * current campaign id is different than the one we picked from
   * the url params.
   */
  useEffect(() => {
    const isLocationOverview = location.pathname.includes('overview')
    if (campaignOutOfSync(id, campaign) && !loading && !isLocationOverview) {
      /**
       * Covers the case where there is a campaign loaded
       */
      getCampaign(id)
      getTags()
      setLoading(true)
    } else if (!id && (!behaviors || !behaviors.length) && !loading && !isLocationOverview) {
      /**
       * Covers the case where there's no id present, user is
       * creating a new campaign.
       */
      getTags()
      setLoading(true)
    }
  })

  /**
   * Updates the step as the route changes
   */
  useEffect(() => {
    if (location.pathname !== url) {
      const next = getStepFromPathName(location.pathname)
      if (stepOutOfSync(wizardStep, next || HM_NEW_CAMPAIGN_PATH)) {
        setWizardStep(next || HM_NEW_CAMPAIGN_PATH)
        setUrl(location.pathname)
        setLoading(true)
      }
    }
  }, [location])

  /**
   * If there is no id, but the current campaign instance has an id
   * unload he wizard properties.
   */
  useEffect(() => {
    if (!id && campaign.id()) {
      dispatch({
        type: UNLOAD_WIZARD
      })
      setLoading(true)
    }
  }, [id])

  /**
   * If all is loaded, update the loading property so
   * the wizard can begin rendering.
   */
  useEffect(() => {
    const next = getStepFromPathName(location.pathname, params)
    if (
      !stepOutOfSync(wizardStep, next) &&
      behaviors.length &&
      (!id || !campaignOutOfSync(id, campaign)) &&
      loading
    ) {
      setLoading(false)
    }
  }, [id, wizardStep, campaign, behaviors])

  /**
   * Here we listen to a special event that will inform the Wizard if
   * an API call error other than 400 has occurred.
   */
  useEffect(() => {
    const bubble = ({ detail }) => {
      /**
       * Dont dispatch 400 and 404 since this are not user blocking errors.
       */
      if ([400, 404].indexOf(detail.status) < 0) {
        dispatch({
          type: SET_WIZARD_ERROR,
          data: detail
        })
      }

      setToastManager(
        toastManager.add({
          id: '400-error',
          message: detail.message,
          level: 'error'
        })
      )
    }

    if (!errorReady) {
      document.addEventListener(HM_WIZARD_EVENT_API_ERROR, bubble)
      setErrorReady(true)
    }

    return () => window.removeEventListener(HM_WIZARD_EVENT_API_ERROR, bubble)
  }, [errorReady])

  const { status } = campaign.data()
  const isCampaignForOverview = status !== null && status !== CAMPAIGN_STATUS.DRAFT
  if (isCampaignForOverview) {
    dispatch({
      type: UNLOAD_WIZARD
    })
    return history.push(`${HM_OVERVIEW_BASE_PATH}/${id}`)
  }

  const submitIfValid = (mode) => {
    return submit(history, mode)
  }

  const modalTitle = (
    <Header>
      <HeaderMetadata color="500" fontWeight="bold" data-id="hm-wizard-header-metadata">
        {`Create a Hacker's Mind Campaign ${getStepIndex(wizardStep) + 1} of ${totalSteps}`}
      </HeaderMetadata>
      <H2 color="900" fontWeight="bold" data-id="hm-wizard-header-title">
        {TITLE[wizardStep]}
      </H2>
    </Header>
  )

  const breadcrumb = (
    <Breadcrumbs
      id={id}
      wizardStep={wizardStep}
      userStep={campaign.getCurrentStep()}
      disabled={!!error || campaign.busy}
      onNavigate={(step) => submit(history, 'exact', step)}
    />
  )

  const closeButton =
    !error &&
    (id ? (
      <SaveForLaterLink
        href="/#"
        onClick={(e) => {
          e.preventDefault()
          submitIfValid('final')
        }}
        disabled={loading || campaign.busy}
        data-id="hm-wizard-save-for-later-button"
      >
        Save & Finish Later
      </SaveForLaterLink>
    ) : (
      <span data-id="hm-wizard-cancel-button">
        <Link to={`${HM_BASE_PATH}`} disabled={loading}>
          Cancel
        </Link>
      </span>
    ))

  const rightButtons = () => {
    return [
      <Button
        type="submit"
        onClick={() => submit(history, 'next')}
        key="next_btn"
        fontWeight="bold"
        disabled={
          loading ||
          !wizardForm ||
          !wizardForm.valid() ||
          (!wizardForm.isDirty() && !wizardForm.isSafe()) ||
          campaign.busy ||
          error
        }
        data-id="hm-wizard-save-button"
      >
        {wizardStep === STEP.REVIEW ? 'Schedule Campaign' : 'Save & Continue'}{' '}
      </Button>
    ]
  }

  const leftButtons = !loading &&
    getStepIndex(wizardStep) > 0 && [
      <Button
        type="submit"
        onClick={() => submitIfValid('previous')}
        key="previous_btn"
        fontWeight="bold"
        theme="ghost"
        disabled={loading}
        data-id="hm-wizard-previous-button"
      >
        Previous
      </Button>
    ]

  const handleClose = () => {
    if (!wizardForm || wizardForm.valid()) {
      dispatch({
        type: UNLOAD_WIZARD
      })
      history.push(HM_CAMPAIGNS_BASE_PATH)
    }
  }

  return (
    <ModalComponent size={wizardProps.size} data-id="hm-wizard-modal">
      <Modal
        key="modal"
        header={modalTitle}
        rightButtons={rightButtons()}
        leftButtons={leftButtons || []}
        closeButton={closeButton}
        breadcrumb={breadcrumb}
        isOpen
        onClose={handleClose}
      >
        {getStepIndex(wizardStep) > -1 && wizardProps.size !== WIZARD_MODAL_SIZE.FULL_SCREEN && (
          <CampaignToasts
            onRemove={(toast) => setToastManager(toastManager.remove(toast))}
            messages={toastManager.get()}
          />
        )}
        <BodyContainer width="100%" size={wizardProps.size} data-id="hm-wizard-body">
          <ErrorHandler>
            <Suspense fallback={<ActivityIndicator active name="suspence" />}>
              <Switch>
                {routes.map(({ path, exact, component: Component }) => (
                  <Route key={path} exact={exact} path={path} component={Component} />
                ))}
              </Switch>
            </Suspense>
          </ErrorHandler>
        </BodyContainer>
      </Modal>
    </ModalComponent>
  )
}

CampaignWizard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
}

export default memo(CampaignWizard)
