import union from 'lodash/union'
import { FormManager } from '../../../../services/form'
import { ControlType } from '../../../../services/form.types'
import { mapAudienceToOptions, getSelectedAudienceFromTags } from './utils'
import { Constrain } from '../../../../services/validator'

export const HM_PARTICIPANTS_FORM_NAME = 'participants-form'

export const getParticipantsForm = (campaign, audiences) => {
  const data = campaign.data()
  const audienceOptions = mapAudienceToOptions(audiences) || []
  const selectedAudience = getSelectedAudienceFromTags(data.tags, audienceOptions)
  const defaultGroupsValue = data.groups

  return (
    new FormManager(
      HM_PARTICIPANTS_FORM_NAME,
      {
        id: {
          type: ControlType.HIDDEN,
          dirty: true
        },
        tags: {
          type: ControlType.RADIO,
          options: audienceOptions,
          defaultValue: audienceOptions.length ? audienceOptions[0].value : null
        },
        groups: {
          type: ControlType.MULTI_SELECT,
          validations: [
            {
              test: Constrain.REQUIRED,
              value: true,
              message: 'Select at least one group'
            }
          ],
          defaultValue: defaultGroupsValue
        }
      },
      {
        ...data,
        tags: selectedAudience && selectedAudience.name
      }
    )
      /**
       * Because radio buttons only hold a single value, we update it
       * to an array just before is submitted so the server can accept it.
       */
      .addBeforeSubmitCallBack((form) => {
        const tags = form.get('tags')
        const groupsForm = form.get('groups')
        const groupsArray = groupsForm.value.map((group) => group.id)
        const scopeTags = tags.options.map((tag) => tag.value)
        const otherTags = campaign.getTags().filter((tag) => scopeTags.indexOf(tag) === -1)

        return form
          .update('tags', {
            value: union(otherTags, [!tags.value ? tags.defaultValue : tags.value])
          })
          .update('groups', {
            value: groupsArray
          })
      })
  )
}
