import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Timezone from 'moment-timezone'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Typography, TIME_ZONES_INVERTED } from '@elevate_security/elevate-component-library'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import { HM_REVIEW_FORM_NAME, getConfirmForm } from './form'
import {
  STEP,
  ROLLOUT_TYPE_NAME,
  LANGUAGES,
  HM_ASSETS_URL,
  CAMPAIGN_TYPE
} from '../../../../constants'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { wizardSelector } from '../../../../services/redux/selectors'
import { InputErrors, Checkbox } from '../../../../components'
import { withForm } from '../../with-form'
import { withStepManager } from '../../with-step'
import { RouteContainer, FieldSet } from '../../styles'
import { useActions, formOutOfSync, mapNamesToBehaviors } from '../../utils'
import { getGroupListAsString } from '../../../../services/utils'
import { Content, TextWrapper, WordSpacing } from './styles'

const { Text, H3 } = Typography
const envelopeImg = `${HM_ASSETS_URL}/stamp-envelope.svg`
function ConfirmStep(props) {
  const { id } = useParams()
  const { handleChange, entering, ready } = props
  const [loading, setLoading] = useState(false)
  const { setWizardForm, getGroups } = useActions(wizardActions)
  const { campaign, wizardForm, behaviors, participants } = useSelector(wizardSelector)
  const data = campaign.data()

  useEffect(() => {
    if (formOutOfSync(id, HM_REVIEW_FORM_NAME, campaign.id(), wizardForm) && behaviors.length > 0) {
      setWizardForm(getConfirmForm({ campaign }))
    }
  })

  useEffect(() => {
    if (!participants.length && !loading) {
      getGroups()
      setLoading(true)
    } else if (participants.length && loading) {
      setLoading(false)
    }
  }, [participants])

  /**
   * Deffer if not ready
   */
  if (!ready || loading || !wizardForm) {
    return <ActivityIndicator active name="confirm not ready" />
  }

  const { status } = wizardForm.get()
  const timeWithTimezone = Timezone.tz(data.start_date, data.timezone).format(
    'MMMM DD, YYYY [at] hh:mm A'
  )
  const selectedBehaviours = mapNamesToBehaviors(data.tags, behaviors).join(', ')
  const selectedType = ROLLOUT_TYPE_NAME[data.rollout_type]
  const groupListAsString = getGroupListAsString(data.groups)
  const campaignType = CAMPAIGN_TYPE.find(
    (obj) => obj.value === data.campaign_type
  ).label.toLowerCase()

  return (
    <CSSTransition in={entering} classNames="scale" timeout={500}>
      <RouteContainer>
        <Content justify="center" align="center" width="100%">
          <img src={envelopeImg} alt="Envelope" />
          <Text color="900" fontWeight="normal">
            On
          </Text>
          <H3 fontWeight="bold" color="900" data-id="hm-review-timezone">
            {timeWithTimezone} {TIME_ZONES_INVERTED[data.timezone]}
          </H3>
          <TextWrapper>
            <Text color="900" fontWeight="normal">
              <WordSpacing>we will roll out</WordSpacing>
            </Text>
            <H3 fontWeight="bold" color="900">
              {campaignType}
            </H3>
            <Text color="900" fontWeight="normal">
              <WordSpacing>campaign</WordSpacing>
            </Text>
          </TextWrapper>
          <TextWrapper>
            <Text color="900" fontWeight="normal">
              <WordSpacing>called</WordSpacing>
            </Text>
            <H3 fontWeight="bold" color="900">
              <span data-id="hm-review-name">{data.name}</span>
            </H3>{' '}
            <WordSpacing>
              <Text color="900" fontWeight="normal">
                in
              </Text>
            </WordSpacing>
            <H3 fontWeight="bold" color="900">
              <span data-id="hm-review-language">{LANGUAGES[data.language]}</span>
            </H3>
          </TextWrapper>
          <Text color="900" fontWeight="normal">
            focusing on
          </Text>
          <H3 fontWeight="bold" color="900" data-id="hm-review-behaviors">
            {selectedBehaviours}
          </H3>
          <Text color="900" fontWeight="normal" style={{ padding: '0' }}>
            via
          </Text>
          <TextWrapper>
            <H3 fontWeight="bold" color="900" data-id="hm-review-rollout-type">
              {selectedType}
            </H3>{' '}
            <WordSpacing>
              <Text color="900" fontWeight="normal">
                {' '}
                to
              </Text>
            </WordSpacing>
            <H3 fontWeight="bold" color="900">
              {groupListAsString}
            </H3>
            <WordSpacing>
              <Text color="900" fontWeight="normal">
                (<span data-id="hm-review-participants-count">{`${data.participants_count}`}</span>{' '}
                {`${data.participants_count !== 1 ? 'participants' : 'participant'}`})
              </Text>
            </WordSpacing>
          </TextWrapper>
          <FieldSet style={{ marginTop: '10px' }} height="32px" data-id="hm-review-confirmation">
            <Checkbox
              onChange={(value) => handleChange('status', { value })}
              checked={status.value}
              label="Yep, everything looks correct!"
              invalid={!status.valid}
            />
            {!status.valid && <InputErrors errors={status.errors} />}
          </FieldSet>
        </Content>
      </RouteContainer>
    </CSSTransition>
  )
}

ConfirmStep.propTypes = {
  handleChange: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  entering: PropTypes.bool.isRequired
}

export default withStepManager(withForm(ConfirmStep), STEP.REVIEW, HM_REVIEW_FORM_NAME)
