import { MEDIA_QUERY_CHANGED } from './types'
import { SET_AUTHENTICATED_USER } from './wizard/types'

const defaultQueries = {
  xSmall: false,
  small: false,
  medium: false,
  large: false,
  xLarge: false
}

const INITIAL_STATE = {
  me: null,
  mediaQuery: {
    ...defaultQueries
  }
}

export default function hmReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      return {
        ...state,
        me: action.data
      }
    case MEDIA_QUERY_CHANGED:
      return {
        ...state,
        mediaQuery: {
          ...state.mediaQuery,
          ...action.data
        }
      }
    default:
      return { ...state }
  }
}
