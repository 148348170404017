import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Input, Icon } from '@elevate_security/elevate-component-library'
import * as wizardActions from '../../../../../../services/redux/wizard/actions'
import { wizardSelector } from '../../../../../../services/redux/selectors'
import { copyToClipboard } from '../../../../../../services/utils'
import { useActions } from '../../../../utils'
import DeleteConfirmationModal from '../DeleteConfimationModal'
import NoSessionsFound from '../NoSessionsFound'
import SessionForm from '../SessionForm'
import Table from '../Table'
import { Container, InputSearchWrapper } from './styles'
import { getFormattedRoomsForTable, getFormattedRoomForForm, columnDefs } from './utils'

function Sessions({ filter, modal, actions }) {
  const { campaign } = useSelector(wizardSelector)
  const { getCampaignRooms, deleteCampaignRooms, saveSessionRoom } = useActions(wizardActions)
  const { id: campaignId } = useParams()
  const [loading, setLoading] = useState({ update: false, create: false, list: false })
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [roomsToDelete, setRoomsToDelete] = useState([])
  const [columns, setColumns] = useState([])
  const [editing, setEditing] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [forms, setForms] = useState({
    create: null,
    update: null
  })
  const rooms = getFormattedRoomsForTable(campaign.getRooms())
  const getRooms = (cb, search) =>
    getCampaignRooms({
      campaignId,
      filter: `ordering=created${search ? `&search=${search}` : ''}`,
      cb
    })

  let timeout = 0

  useEffect(() => {
    if (campaign.id()) {
      getRooms(() => setLoading({ ...loading, list: false }), searchText)
    }
  }, [searchText])

  useEffect(() => {
    setColumns(columnDefs)
    setLoading({ ...loading, list: true })
    getRooms(() => setLoading({ ...loading, list: false }))
  }, [])

  const updateForms = (type, form) => {
    if (type === 'update') {
      setForms({ ...forms, update: form || null })
    } else {
      setForms({ ...forms, create: form || null })
    }
  }

  const handleSave = (type, form) => {
    setLoading({ ...loading, [type]: true })

    saveSessionRoom({
      form,
      rooms: campaign.getRooms(),
      formatted: true,
      cb: ({ error, data, status }, invalidForm) => {
        setLoading({ ...loading, [type]: false })

        if (!error) {
          if ([200, 201].indexOf(status) > -1) {
            setEditing(null)
            updateForms(type, null)
          }

          if (invalidForm) {
            updateForms(type, invalidForm)
          }
        } else if (status === 400) {
          updateForms(type, form.mapErrors(data[0]))
        }
      }
    })
  }

  const handleDelete = () => {
    setLoading({ ...loading, list: true })
    setShowConfirmModal(false)
    deleteCampaignRooms({
      roomsToDelete,
      rooms: campaign.getRooms(),
      cb: () => {
        setRoomsToDelete([])
        setLoading({ ...loading, list: false })
      }
    })
  }

  const handleCancelDelete = () => {
    setShowConfirmModal(false)
    setRoomsToDelete([])
  }

  const handleConfirmDelete = (userDeletedRooms) => {
    if (userDeletedRooms.length) {
      setRoomsToDelete(userDeletedRooms)
      setShowConfirmModal(true)
    }
  }

  const handleEdit = (row) => {
    setEditing(getFormattedRoomForForm(row))
  }

  const handleCancel = () => {
    setEditing(null)
    setForms({
      ...forms,
      update: null
    })
  }

  const handleOnReady = (type, form) => {
    setForms({
      ...forms,
      [type]: form
    })
  }

  const handleCopy = ({ session: roomId }) => {
    copyToClipboard(`${window.location.origin}/hackersmind/${roomId}`)
  }

  const handleSearch = (text) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => setSearchText(text), 500)
  }

  const isEditing = editing !== null

  const schedule = campaign ? campaign.getSchedule() : null
  const status = campaign ? campaign.getStatus() : null

  return (
    <Container grow={0}>
      {filter && (
        <InputSearchWrapper>
          <Input
            placeholder="Filter sessions..."
            width="full"
            iconLeft={<Icon name="Search" size="sm" fill="#565D66" stroke="#565D66" />}
            onChange={(value) => handleSearch(value)}
          />
        </InputSearchWrapper>
      )}
      <SessionForm
        campaign={schedule}
        status={status}
        form={forms.create}
        row={{}}
        loading={loading.create}
        onSave={(form) => handleSave('create', form)}
        modal={false}
        onForm={(form) => handleOnReady('create', form)}
      />
      {(loading.list || campaign.busy || (!loading.list && rooms.length > 0)) && (
        <Table
          columns={columns}
          data={rooms || []}
          editable
          bulkAction
          actions={actions}
          loading={loading.list}
          onEdit={handleEdit}
          onCopy={handleCopy}
          onDelete={handleConfirmDelete}
        />
      )}
      {!loading.list && !campaign.busy && rooms.length === 0 && (
        <NoSessionsFound>
          {searchText.length ? 'No campaign sessions found.' : 'No campaign sessions added yet.'}
        </NoSessionsFound>
      )}
      {showConfirmModal && (
        <DeleteConfirmationModal
          rooms={roomsToDelete}
          onClose={handleCancelDelete}
          onConfirm={handleDelete}
        />
      )}
      {isEditing && (
        <SessionForm
          campaign={schedule}
          status={status}
          form={forms.update}
          row={editing || {}}
          loading={loading.update}
          onSave={(form) => handleSave('update', form)}
          onCancel={handleCancel}
          modal={modal}
          onForm={(form) => handleOnReady('update', form)}
        />
      )}
    </Container>
  )
}

Sessions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.oneOf(['copy', 'edit', 'delete'])),
  filter: PropTypes.bool,
  modal: PropTypes.bool
}

Sessions.defaultProps = {
  actions: ['copy', 'edit', 'delete'],
  filter: false,
  modal: false
}

export default Sessions
