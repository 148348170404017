import styled from 'styled-components'
import { Layout } from '@elevate_security/elevate-component-library'

export const Container = styled(Layout)`
  padding: 20px 0;
`
export const Row = styled(Layout).attrs({ container: true })`
  padding: 2px 8px;
`
export const Column = styled(Layout).attrs({ item: true })`
  min-width: 140px;
  padding: 4px 0;
`
