/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Moment from 'moment'
import { useParams } from 'react-router-dom'
import { Button } from '@elevate_security/elevate-component-library'
import { StyledAsyncTypeAhead } from '../../../../components/StyledAsyncTypeAhead'
import { wizardSelector } from '../../../../services/redux/selectors'
import { ActivityIndicator, EmployeesMenu } from '../../../../components'
import { mapToId } from '../../../../services/utils'
import { useActions } from '../../../CampaignWizard/utils'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { FlexColumn, FlexItem } from '../../../../common.styles'
import Session from './Session'
import { Header, SessionContainer } from './styles'

function PredefinedLeadSessionsManager() {
  const { id } = useParams()
  const { campaign } = useSelector(wizardSelector)
  const { searchEmployees, createCampaignSession, getCampaign, getCampaignSessions } =
    useActions(wizardActions)
  const [leader, setLeader] = useState([])
  const [employeeResults, setEmployeeResults] = useState([])
  const [sessions, setSessions] = useState([])
  const [state, setState] = useState({ loading: false, loadingSessions: false })
  const [loading, setLoading] = useState(false)
  const inputRef = React.createRef()

  useEffect(() => {
    if (!campaign || !campaign.id()) {
      if (!state.loading) {
        getCampaign(id)
        setState({ ...state, loading: true })
      }
    } else if (state.loading) {
      setState({ ...state, loading: false })
    }
  })

  useEffect(() => {
    if (campaign.id() && !campaign.isSessionsLoaded()) {
      if (!state.loadingSessions) {
        getCampaignSessions({})
        setState({ ...state, loadingSessions: true })
      }
    } else if (state.loadingSessions) {
      setState({ ...state, loadingSessions: false })
    }
  }, [campaign])

  useEffect(() => {
    setSessions(
      campaign
        .getSessions()
        .sort((left, right) => Moment.utc(right.created).diff(Moment.utc(left.created)))
    )
  }, [campaign])

  const handleSearchEmployees = (text) => {
    searchEmployees({
      text,
      cb: (data) => {
        setEmployeeResults(
          data.filter(
            // do not show employees in dropdown list that are already selected
            ({ id: leadId }) => leadId !== leader
          )
        )
      }
    })
  }

  const handleCreateSession = () => {
    setLoading(true)
    createCampaignSession({
      leader,
      cb: () => {
        setLeader([])
        setLoading(false)
        setEmployeeResults([])
      }
    })
  }

  const selectLeader = (employees) => {
    setLeader(mapToId(employees)[0])
  }

  if (!campaign.id()) {
    return null
  }

  return (
    <FlexColumn>
      <Header gap="24px">
        <FlexItem width="300px">
          <StyledAsyncTypeAhead
            ref={inputRef}
            placeholder="Select Hacker's Mind Session Leader"
            labelKey="email"
            selected={leader}
            disabled={loading}
            options={employeeResults}
            maxResults={10}
            onSearch={handleSearchEmployees}
            onChange={selectLeader}
            ItemComponent={EmployeesMenu}
            labels={['first_name', 'last_name']}
          />
        </FlexItem>
        <FlexItem>
          <Button disabled={!leader} onClick={handleCreateSession}>
            Create new Hacker's Mind session
          </Button>
        </FlexItem>
      </Header>
      <br />
      <SessionContainer width="100%">
        {sessions.map((session, index) => (
          <Session
            key={session.id}
            index={index}
            id={session.id}
            created={session.created}
            leader={session.leader_email}
            total_played={session.total_played}
            total_completed={session.total_completed}
          />
        ))}
        <ActivityIndicator active={loading} />
      </SessionContainer>
    </FlexColumn>
  )
}

export default PredefinedLeadSessionsManager
