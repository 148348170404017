import React from 'react'
import Moment from 'moment'
import Md1 from 'react-icons/lib/md/sentiment-very-dissatisfied'
import Md2 from 'react-icons/lib/md/sentiment-dissatisfied'
import Md3 from 'react-icons/lib/md/sentiment-neutral'
import Md4 from 'react-icons/lib/md/sentiment-satisfied'
import Md5 from 'react-icons/lib/md/sentiment-very-satisfied'

import { surveyOptionsMapping } from '../../constants'

export function getInfoPieGraphSurvey(formattedSurveyPercentages) {
  return {
    datasets: [
      {
        data: formattedSurveyPercentages.map((score) => score.value),
        backgroundColor: formattedSurveyPercentages.map((score) => score.color)
      }
    ],
    labels: formattedSurveyPercentages.map((score) => score.title)
  }
}

export function formatSurveyPercentages(surveyPercentages) {
  return [
    {
      title: surveyOptionsMapping.not_at_all_likely,
      displayTitle: surveyOptionsMapping.not_at_all_likely.replace(/ /g, '\u00a0'),
      icon: <Md1 size={30} color="#ED6A5A" />,
      color: '#ED6A5A',
      value: surveyPercentages.not_at_all_likely
    },
    {
      title: surveyOptionsMapping.probably_not_likely,
      displayTitle: surveyOptionsMapping.probably_not_likely.replace(/ /g, '\u00a0'),
      icon: <Md2 size={30} color="#FFAA49" />,
      color: '#FFAA49',
      value: surveyPercentages.probably_not_likely
    },
    {
      title: surveyOptionsMapping.neutral,
      displayTitle: surveyOptionsMapping.neutral.replace(/ /g, '\u00a0'),
      icon: <Md3 size={30} color="#FFD149" />,
      color: '#FFD149',
      value: surveyPercentages.neutral
    },
    {
      title: surveyOptionsMapping.likely,
      displayTitle: surveyOptionsMapping.likely.replace(/ /g, '\u00a0'),
      icon: <Md4 size={30} color="#2EC6D3" />,
      color: '#2EC6D3',
      value: surveyPercentages.likely
    },
    {
      title: surveyOptionsMapping.extremely_likely,
      displayTitle: surveyOptionsMapping.extremely_likely.replace(/ /g, '\u00a0'),
      icon: <Md5 size={30} color="#5BC176" />,
      color: '#5BC176',
      value: surveyPercentages.extremely_likely
    }
  ]
}

export function getCampaignCompletionDataset(HMCompletion, surveyCompletion) {
  return {
    datasets: [
      {
        label: 'Campaign Completed',
        backgroundColor: 'rgb(30, 168, 221)',
        borderColor: 'rgba(30, 168, 221, .5)',
        fill: false,
        lineTension: 0,
        data: HMCompletion
      },
      {
        label: 'Survey Responses Over Time',
        backgroundColor: 'rgb(255,192,73)',
        borderColor: 'rgba(255,192,73, .5)',
        fill: false,
        lineTension: 0,
        data: surveyCompletion
      }
    ]
  }
}

export function getCampaignCompletionGraph(campaignDates) {
  const completionGraph = []
  const surveyCompletionGraph = []

  // Fill Dataset Objects
  Object.keys(campaignDates).forEach((key) => {
    completionGraph.push({
      x: key,
      y: campaignDates[key]['completion_acum_percentage']
    })
    surveyCompletionGraph.push({
      x: key,
      y: campaignDates[key]['survey_acum_percentage']
    })
  })

  return {
    datasets: [
      {
        label: 'Campaign Completion',
        backgroundColor: 'rgb(30, 168, 221)',
        borderColor: 'rgba(30, 168, 221, .5)',
        fill: false,
        lineTension: 0,
        data: completionGraph
      },
      {
        label: 'Survey Responses Over Time',
        backgroundColor: 'rgb(255,192,73)',
        borderColor: 'rgba(255,192,73, .5)',
        fill: false,
        lineTension: 0,
        data: surveyCompletionGraph
      }
    ]
  }
}

export function getSurveyProps(scores) {
  return {
    datasets: [
      {
        data: scores.map((score) => score.value),
        backgroundColor: scores.map((score) => score.color)
      }
    ],
    labels: scores.map((score) => score.title)
  }
}

export function getlineGRaphOptions(
  type = 'percentOverTime',
  fontColor = 'rgba(30, 168, 221, .5)'
) {
  const graphTypes = {
    percentOverTime: {
      responsive: true,
      hoverMode: 'index',
      stacked: false,
      legend: { display: false },
      title: { display: false },
      scales: {
        xAxes: [
          {
            type: 'time',
            distribution: 'linear',
            display: true,
            scaleLabel: {
              display: true
            },
            bounds: 'ticks',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM DD YYYY'
              }
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              source: 'auto',
              major: {
                fontStyle: 'bold',
                fontColor: '#FF0000'
              }
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
              max: 100,
              callback: (value) => `${value}%`
            },
            scaleLabel: { display: false },
            display: true
          }
        ]
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem) => `${tooltipItem[0].yLabel}%`,
          label: (tooltipItem) => Moment(tooltipItem.xLabel).format('MMM DD YYYY')
        },
        position: 'average',
        mode: 'nearest',
        intersect: false,
        yPadding: 10,
        xPadding: 10,
        caretSize: 8,
        backgroundColor: 'rgb(255, 255, 255)',
        titleFontColor: fontColor,
        titleFontSize: 24,
        bodyFontSize: 14,
        bodyFontColor: fontColor,
        borderColor: 'rgba(0,0,0, .5)',
        borderWidth: 1
      }
    }
  }

  return graphTypes[type]
}

export const copyToClipboard = (text, cb) => {
  if (!navigator.clipboard) {
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      if (cb) cb(successful)
    } catch (err) {
      if (cb) cb(false)
    }

    document.body.removeChild(textArea)
    return
  }

  navigator.clipboard.writeText(text, cb)
}

export const buttonsDownloadCsvDropdown = ({ isPeerScheduledRollout, onClick = () => null }) => [
  {
    label: 'Download Results',
    onClick: () => onClick({ name: 'download-results' }),
    isVisible: true
  },
  {
    isVisible: isPeerScheduledRollout,
    label: 'Download Session Links',
    onClick: () => onClick({ name: 'download-session-links' })
  },
  {
    isVisible: isPeerScheduledRollout,
    label: 'Download Enrollment',
    onClick: () => onClick({ name: 'download-enrollment' })
  }
]
