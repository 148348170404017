class SelectionManager {
  selected = new Map()

  constructor(selected) {
    this.selected = new Map(selected || null)
  }

  select(keyOrKey, item) {
    this.selected.set(keyOrKey, item || keyOrKey)
    return new SelectionManager(this.selected)
  }

  deselect(keyOrKey) {
    this.selected.delete(keyOrKey)
    return new SelectionManager(this.selected)
  }

  has(keyOrKey) {
    return this.selected.has(keyOrKey)
  }

  count() {
    return this.selected.size
  }

  get() {
    return Array.from(this.selected.values())
  }

  reset() {
    this.selected = new Map()
    return new SelectionManager(this.selected)
  }
}

export default SelectionManager
