import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { TypeAheadContainer } from './styles'

export function StyledAsyncTypeAhead({
  options,
  selected,
  placeholder,
  maxResults,
  onSearch,
  onChange,
  labelKey,
  multiple,
  disabled,
  ItemComponent,
  labels
}) {
  const input = useRef()

  return (
    <TypeAheadContainer>
      <AsyncTypeahead
        ref={input}
        multiple={multiple}
        selectHintOnEnter
        placeholder={placeholder}
        labelKey={labelKey}
        defaultSelected={selected}
        options={options}
        maxResults={maxResults}
        onSearch={onSearch}
        onChange={onChange}
        disabled={disabled}
        renderMenu={(results, menuProps) => (
          <ItemComponent results={results} menuProps={menuProps} labels={labels} />
        )}
        filterBy={() => true}
      />
    </TypeAheadContainer>
  )
}

StyledAsyncTypeAhead.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  maxResults: PropTypes.number,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  ItemComponent: PropTypes.element.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired
}

StyledAsyncTypeAhead.defaultProps = {
  multiple: false,
  disabled: false,
  options: [],
  selected: [],
  maxResults: 5,
  placeholder: 'Search employees'
}
