import styled from 'styled-components'

export const InputCheckbox = styled.input.attrs({
  type: 'checkbox'
})`
  && {
    -webkit-appearance: none;
    background-color: #ffffff;
    border: 1px solid ${(props) => (props.invalid ? '#c73434' : '#b1b8c1')};
    box-shadow: none;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    outline: 0;

    &:active,
    &:checked:active {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    &:checked {
      background-color: #0c7d84;
      border: 1px solid #0c7d84;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
      color: #ffffff;
      border-radius: 4px;

      &:after {
        content: '';
        font-size: 14px;
        position: absolute;
        color: #ffffff;
        ${(props) =>
          props.indeterminate
            ? `
          top: 6px;
          left: 2px;
          width: 10px;
          height: 0;
        `
            : `
          top: 1px;
          left: 5px;
          width: 5px;
          height: 10px;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        `}
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
      }
    }
  }
`
export const InputCheckBoxWrapper = styled.div`
  label {
    transform: translateY(-3px);
  }
`
