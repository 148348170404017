import styled, { css } from 'styled-components'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { Label } from '../../../../styles'
import { FlexColumn, FlexRow } from '../../../../../../common.styles'
import { ModalHeader } from '../../styles'

export const RowFormBackdrop = styled(FlexColumn)`
  min-width: 100%;
  max-width: 100%;
  & > *:last-child {
    margin-bottom: auto;
  }
  ${(props) =>
    !props.modal
      ? css`
          background: rgb(245, 246, 248);
          padding: 0 24px;
          @media (min-width: 1600px) {
            padding: 0 10%;
          }
          margin-bottom: 24px !important;
        `
      : css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(23, 33, 44, 0.5);
          border-radius: 8px;
          z-index: 100;
        `}
`

export const RowFormContainer = styled(FlexRow)`
  position: relative;
  flex: 0 1 0%;
  max-width: 100%;
  min-width: 100%;
  padding-top: 24px;
  margin-right: -24px;
  > .hm-session-fieldset {
    width: 200px;
    min-width: 200px;
    max-width: auto;
  }
  > .hidden-fieldset {
    height: 0;
    flex: 1 0 auto;
  }
  ${(props) =>
    !props.modal
      ? css`
          input {
            background: #fff !important;
          }
          div.rbt-input-hint > input {
            background: transparent !important;
          }
        `
      : null}
`

export const ColumnFormContainer = styled.div`
  position: relative;
  flex: 0 1 0%;
  margin: auto;
  max-width: 730px;
  background: #fff;
  border-radius: 10px;
  padding: 24px 48px;
`

export const SessionFormHeader = styled(ModalHeader)`
  margin-bottom: 25px;
`

export const SessionFormRow = styled(FlexRow).attrs({
  grow: 0,
  gap: '24px',
  width: '100%'
})`
  margin-bottom: 20px;
  min-width: ${(props) => props.width || 'auto'};
  max-width: ${(props) => props.width || 'auto'};
`

export const SessionFooterDivider = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(58, 70, 85, 0.15);
`

const inputStyle = css`
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  color: rgb(52, 56, 60);
  background-color: transparent;
  z-index: 1;
  font-size: 14px;
  border-radius: 5px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(189, 197, 209, 0.5);
  border-image: initial;
  transition: border-color 125ms ease 0s;
  padding: 0px 16px;
`

const dropdownPositionValues = {
  top: 'translateY(calc(-100% - 48px))',
  bottom: 'translateY(0)'
}
export const TypeAheadInput = styled(AsyncTypeahead)`
  input {
    ${inputStyle}
  }
  .dropdown-menu {
    transform: ${({ dropdownPosition }) =>
      !dropdownPositionValues[dropdownPosition]
        ? dropdownPositionValues.bottom
        : dropdownPositionValues[dropdownPosition]};
  }
`

export const CustomLabel = styled(Label)`
  font-size: 14px;
  color: #34383c;
`
