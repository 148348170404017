import PropTypes from 'prop-types'
import React, { memo } from 'react'
import Breadcrumb from '@elevate_security/elevate-component-library/dist/Breadcrumb'
import Link from '@elevate_security/elevate-component-library/dist/Link'
import Typography from '@elevate_security/elevate-component-library/dist/Typography'

import { PageHeaderWrapper, Row, Column } from './styles'

const { H1, Text } = Typography

function PageHeader(props) {
  const { title, breadcrumbLinks, contentOnTheRightOfTitle } = props
  return (
    <PageHeaderWrapper>
      <Breadcrumb data-id="hm-navigation-breadcrumbs">
        {breadcrumbLinks.map(({ label, link }) => (
          <Link to={link} key={label + link}>
            <Text color="300">{label}</Text>
          </Link>
        ))}
      </Breadcrumb>
      <Row alignItems="center" justify="space-between">
        <Column>
          <H1 fontWeight="heavy" color="900">
            {title}
          </H1>
        </Column>
        {contentOnTheRightOfTitle && <Column>{contentOnTheRightOfTitle}</Column>}
      </Row>
    </PageHeaderWrapper>
  )
}

PageHeader.defaultProps = {
  contentOnTheRightOfTitle: null
}
PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  breadcrumbLinks: PropTypes.shape([]).isRequired,
  contentOnTheRightOfTitle: PropTypes.node
}

export default memo(PageHeader)
