import { API_ERROR_MESSAGE, createRequest, METHOD } from '../../api'
import { formatDatesToUI } from '../../utils'
import axios from '../axios-config'

import { CAMPAIGN_STATUS } from '../../../constants'

export const fetchCampaignList = createRequest(
  ({ filter }) =>
    axios({
      method: METHOD.GET,
      url: `/hackersmindcampaigns/?deleted=false&ordering=-created&${filter || ''}`
    }),
  {
    400: `<p>Sorry, there was an unexpected error while loading your list of campaigns.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
    404: '<p>No campaigns have been created yet.</p>'
  },
  {
    onFetch: ({ results }) => ({
      results: results.map((campaign) => formatDatesToUI(campaign))
    })
  }
)

export const fetchCompletionReport = createRequest(
  (params) =>
    axios({
      method: METHOD.GET,
      params,
      url: `/organizations/${params.orgId}/completion_report/`
    }),
  {
    400: `<p>The completion report could not be downloaded at this time.</p>
         ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  }
)

export const fetchCompletionYears = createRequest((params) =>
  axios({
    method: METHOD.GET,
    url: `/organizations/${params.orgId}/completion_years/`
  })
)

export const fetchCampaignLeads = createRequest(
  ({ campaignId }) =>
    axios({
      method: METHOD.GET,
      url: `/hackersmindcampaigns/${campaignId}/predefined_leaders/`
    }),
  {
    400: `<p>Sorry, there was an unexpected error while loading the list of leads.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`,
    404: '<p>No leads found for this organization.</p>'
  }
)

export const deleteCampaign = createRequest(
  ({ id }) =>
    axios({
      method: METHOD.DELETE,
      url: `/hackersmindcampaigns/${id}/`
    }),
  {
    400: `<p>Sorry, there was an unexpected error while deleting the campaign.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  }
)

export const cancelCampaign = createRequest(
  ({ id }) =>
    axios({
      method: METHOD.PATCH,
      url: `/hackersmindcampaigns/${id}/`,
      data: { status: CAMPAIGN_STATUS.CANCELLED }
    }),
  {
    400: `<p>Sorry, there was an unexpected error while canceling the campaign.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  }
)

export const archiveCampaign = createRequest(
  (campaignId) =>
    axios({
      method: METHOD.PATCH,
      url: `/hackersmindcampaigns/${campaignId}/`,
      data: { status: CAMPAIGN_STATUS.ARCHIVED }
    }),
  {
    400: `<p>Sorry, there was an unexpected error while archiving the campaign.</p>
          ${API_ERROR_MESSAGE.REFRESH_TRY_AGAIN_CONTACT_SUPPORT}`
  }
)

export const fetchCampaignStatuses = createRequest(() =>
  axios({
    method: METHOD.GET,
    url: '/hackersmindcampaigns/statuses/'
  })
)
