import { ContentState, convertFromHTML, EditorState } from 'draft-js'
import { FormManager } from '../../services/form'
import { ControlType } from '../../services/form.types'
import { Constrain } from '../../services/validator'

export const HM_EMAIL_EDITOR_FORM = 'HM_EMAIL_EDITOR_FORM'

export const fromHtml = (data) => {
  const blocksFromHTML = convertFromHTML(data)
  const updatedState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  )

  return EditorState.createWithContent(updatedState)
}

export const getEmailEditorForm = (template) =>
  new FormManager(
    HM_EMAIL_EDITOR_FORM,
    {
      id: {
        type: ControlType.HIDDEN
      },
      name: {
        type: ControlType.TEXT,
        validations: [{ test: Constrain.REQUIRED, value: true, message: 'Email must have a name.' }]
      },
      subject: {
        type: ControlType.TEXT,
        validations: [
          { test: Constrain.REQUIRED, value: true, message: 'Email must have a subject.' }
        ]
      },
      body: {
        type: ControlType.TEXTAREA,
        validations: [{ test: Constrain.REQUIRED, value: true, message: 'Email must have a body.' }]
      }
    },
    template
  )
