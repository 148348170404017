import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import {
  Typography,
  CustomSelect,
  Icon,
  Button,
  ButtonIcon,
  Tooltip
} from '@elevate_security/elevate-component-library'
import { useHistory } from 'react-router-dom'
import { STEP, STATIC_EMAIL_TEMPLATES } from '../../../../../../constants'
import { FlexRow } from '../../../../../../common.styles'
import { getUrl } from '../../../../utils'
import { ButtonRow, TimelineContainer } from './styles'

import Timeline from '../../../../../../components/Timeline'

const { Body, Text } = Typography

function TimelineSelectEmailTemplate({ campaignId, wizardForm, onChange }) {
  const history = useHistory()
  const { participant_template } = wizardForm.get()
  const { value, defaultValue } = participant_template
  const templateId = value || (defaultValue && defaultValue.value)

  function handleOnChange(changedValue) {
    if (onChange && typeof onChange === 'function') onChange(changedValue)
  }

  const settings = [
    {
      label: (
        <>
          <Text fontWeight="bold" color="900">
            Introduction
          </Text>
          <Body>Sent immediately upon start of campaign</Body>
        </>
      ),
      input: (
        <FlexRow data-id="hm-schedule-form-email-template">
          <CustomSelect
            placeholder="Select a template"
            defaultValue={templateId}
            options={participant_template.options}
            onChange={({ value: pValue }) =>
              handleOnChange({ name: 'participant_template', value: pValue })
            }
            maxHeightDropdown="250px"
          />
          <ButtonRow>
            <Tooltip body="Edit email template" readOnly size="sm">
              <ButtonIcon
                theme="ghost"
                icon="Pencil"
                color="#959DA8"
                className="edit-action"
                onClick={() =>
                  history.push(getUrl(STEP.EMAIL_TEMPLATE_EDITOR, { id: campaignId, templateId }))
                }
              />
            </Tooltip>
            <Tooltip body="Review email template" readOnly size="sm">
              <ButtonIcon
                theme="ghost"
                icon="Eye"
                color="#959DA8"
                className="view-action"
                type="button"
                onClick={() =>
                  history.push(
                    getUrl(STEP.EMAIL_TEMPLATE_PREVIEW, {
                      id: campaignId,
                      templateId
                    })
                  )
                }
              />
            </Tooltip>
          </ButtonRow>
        </FlexRow>
      )
    },
    {
      label: (
        <>
          <Text fontWeight="bold" color="900">
            Enrollment Confirmation
          </Text>
          <Body>Sent upon session enrollment</Body>
        </>
      ),
      input: (
        <FlexRow>
          <CustomSelect
            placeholder="Sent upon session enrollment (Default)"
            options={[]}
            disabled
            maxHeightDropdown="250px"
          />
          <ButtonRow>
            <Button
              theme="ghost"
              className="edit-action"
              type="button"
              onClick={() =>
                history.push(
                  getUrl(STEP.EMAIL_TEMPLATE_PREVIEW, {
                    id: campaignId,
                    templateId: STATIC_EMAIL_TEMPLATES.DEFAULT_SCHEDULED_SESSION_CONF
                  })
                )
              }
            >
              <Tooltip body="Review email template" readOnly size="sm">
                <Icon name="Eye" fill="#959DA8" />
              </Tooltip>
            </Button>
          </ButtonRow>
        </FlexRow>
      )
    },
    {
      label: (
        <>
          <Text fontWeight="bold" color="900">
            Employee Reminder: Day Before
          </Text>
          <Body>Sent 1 day before session</Body>
        </>
      ),
      input: (
        <FlexRow>
          <CustomSelect
            placeholder="Next Day Reminder (Default)"
            options={[]}
            disabled
            maxHeightDropdown="250px"
          />
          <ButtonRow>
            <Button
              theme="ghost"
              className="edit-action"
              type="button"
              onClick={() =>
                history.push(
                  getUrl(STEP.EMAIL_TEMPLATE_PREVIEW, {
                    id: campaignId,
                    templateId: STATIC_EMAIL_TEMPLATES.DEFAULT_ONE_DAY_REMAINDER
                  })
                )
              }
            >
              <Tooltip body="Review email template" readOnly size="sm">
                <Icon name="Eye" fill="#959DA8" />
              </Tooltip>
            </Button>
          </ButtonRow>
        </FlexRow>
      )
    },
    {
      label: (
        <>
          <Text fontWeight="bold" color="900">
            Employee Reminder: 1 Hour Before
          </Text>
          <Body>Sent 1 hour before session</Body>
        </>
      ),
      input: (
        <FlexRow>
          <CustomSelect
            placeholder="1-hour Reminder (Default)"
            options={[]}
            disabled
            maxHeightDropdown="250px"
          />
          <ButtonRow>
            <Button
              theme="ghost"
              className="edit-action"
              type="button"
              onClick={() =>
                history.push(
                  getUrl(STEP.EMAIL_TEMPLATE_PREVIEW, {
                    id: campaignId,
                    templateId: STATIC_EMAIL_TEMPLATES.DEFAULT_ONE_HOUR_REMAINDER
                  })
                )
              }
            >
              <Tooltip body="Review email template" readOnly size="sm">
                <Icon name="Eye" fill="#959DA8" />
              </Tooltip>
            </Button>
          </ButtonRow>
        </FlexRow>
      )
    }
  ]
  return (
    <TimelineContainer data-id="hm-schedule-form-email-options">
      <Timeline settings={settings} />
    </TimelineContainer>
  )
}

TimelineSelectEmailTemplate.defaultProps = {
  campaignId: null
}

TimelineSelectEmailTemplate.propTypes = {
  campaignId: PropTypes.string,
  wizardForm: PropTypes.shape({ get: PropTypes.func }).isRequired,
  onChange: PropTypes.func.isRequired
}

export default TimelineSelectEmailTemplate
