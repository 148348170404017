import Moment from 'moment'
import { FormManager } from '../../../../../../services/form'
import { ControlType } from '../../../../../../services/form.types'
import { Constrain } from '../../../../../../services/validator'
import { timeAndTzToUTC } from '../../../../../../services/utils'

export const HM_SESSION_FORM_NAME = 'session-form'

const getLabel = (headers) => (prop) => {
  return (headers.find((header) => header.accessor === prop) || {}).Header
}
const getProps = (headers) => (prop) => {
  const header = headers.find((hd) => hd.accessor === prop) || {
    fieldProps: {}
  }
  return header.fieldProps
}

export const getDatesValidations = (data, campaignStart, campaignEnd) => ({
  start_time: [
    { test: Constrain.REQUIRED, value: true, message: 'Start date is required' },
    {
      test: Constrain.DATE,
      value: true,
      after: timeAndTzToUTC({ date: Moment(), timezone: data.timezone }),
      message: 'Start time cannot be in the past',
      timezone: data.timezone
    },
    // session start time must be with in campaign start and end time
    ...(campaignStart
      ? [
          {
            test: Constrain.DATE,
            value: true,
            sameOrAfter: campaignStart,
            message: 'Start time must be after campaign start',
            timezone: data.timezone
          }
        ]
      : []),
    ...(campaignEnd
      ? [
          {
            test: Constrain.DATE,
            value: true,
            sameOrBefore: campaignEnd,
            message: 'Start time must be before campaign end',
            timezone: data.timezone
          }
        ]
      : [])
  ],
  end_time: [
    {
      test: Constrain.DATE,
      value: true,
      after: timeAndTzToUTC({ date: data.start_time, timezone: data.timezone }) || Moment(),
      message: 'End time must be after start time',
      timezone: data.timezone
    },
    // session end time must be with in campaign start and end time
    ...(campaignStart
      ? [
          {
            test: Constrain.DATE,
            value: true,
            sameOrAfter: campaignStart,
            message: 'End time must be after campaign start',
            timezone: data.timezone
          }
        ]
      : []),
    ...(campaignEnd
      ? [
          {
            test: Constrain.DATE,
            value: true,
            sameOrBefore: campaignEnd,
            message: 'End time must be before campaign end',
            timezone: data.timezone
          }
        ]
      : [])
  ]
})

export const getSessionForm = (row, headers, defaultTimezone) => {
  const label = getLabel(headers)
  const props = getProps(headers)
  const data =
    row && Object.keys(row).length
      ? row
      : {
          id: null,
          location: null,
          room: null,
          room_size: 1,
          start_time: null,
          end_time: null,
          timezone: null,
          leader: [],
          dial_in: null,
          language: props('language').options[0]
        }

  return new FormManager(
    HM_SESSION_FORM_NAME,
    {
      id: {
        type: ControlType.HIDDEN
      },
      campaign: {
        type: ControlType.HIDDEN
      },
      organization: {
        type: ControlType.HIDDEN
      },
      location: {
        type: ControlType.TEXT,
        label: label('location'),
        validations: [{ test: Constrain.REQUIRED, value: true, message: 'Location is required.' }]
      },
      room: {
        type: ControlType.TEXT,
        label: label('room'),
        validations: [{ test: Constrain.REQUIRED, value: true, message: 'Room is required.' }]
      },
      room_size: {
        type: ControlType.NUMBER,
        label: label('room_size'),
        validations: [
          { test: Constrain.REQUIRED, value: true, message: 'Room size is required.' },
          {
            test: Constrain.INTEGER,
            value: true,
            message: '',
            min: 1
          }
        ]
      },
      start_time: {
        type: ControlType.DATE,
        label: label('start_time'),
        validations: getDatesValidations(data).start_time
      },
      end_time: {
        type: ControlType.DATE,
        label: label('end_time'),
        validations: getDatesValidations(data).end_time
      },
      timezone: {
        type: ControlType.SELECT,
        label: label('timezone'),
        options: props('timezone').options,
        searchable: props('timezone').isSearch,
        validations: [{ test: Constrain.REQUIRED, value: true, message: 'Timezone is required.' }],
        value: (defaultTimezone || {}).value
      },
      leader: {
        type: ControlType.TEXT,
        label: label('leader'),
        options: [],
        props: props('leader'),
        validations: [
          { test: Constrain.MIN_SELECTIONS, value: 1, message: 'You must select a leader.' }
        ]
      },
      dial_in: {
        type: ControlType.TEXT,
        label: label('dial_in'),
        validations: []
      },
      language: {
        type: ControlType.SELECT,
        label: label('language'),
        options: props('language').options,
        validations: [
          { test: Constrain.REQUIRED, value: true, message: 'A language must be selected.' }
        ]
      }
    },
    data
  )
}
