import styled from 'styled-components'
import { theme, Typography } from '@elevate_security/elevate-component-library'

const { A } = Typography

export const IconContainer = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > svg {
    stroke: ${theme.colors.bg.errorInverse};
    fill: ${theme.colors.bg.errorInverse};
  }
`

export const MainTitle = styled.h2`
  text-align: center;
  font-size: ${theme.text.fontSize.xxxlg};
  margin-bottom: 15px;
`

export const SubTitle = styled.h3`
  text-align: center;
  font-size: ${theme.text.fontSize.lg};
`

export const StyledButtonLink = styled(A)`
  &:not([href]):not([tabindex]) {
    color: ${theme.colors.primary};
  }
  &:hover {
    cursor: pointer;
  }
`
