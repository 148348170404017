import keys from 'lodash/keys'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'

/**
 * The CampaignManager helps managing the campaign user data
 * as the user progress through the steps in the wizard.
 *
 * The wizard reducer is already set up to always create a new
 * instance when the campaign data is updated, so re-render can
 * propagate through the wizard.
 */
export class CampaignManager {
  properties = {
    id: null,
    tags: [],
    type: null,
    rollout_type: null,
    group_data: null,
    created: null,
    updated: null,
    name: null,
    start_date: null,
    end_date: null,
    timezone: null,
    status: null,
    anonymous_filter: null,
    deleted: false,
    targets_count: 0,
    participants_count: 0,
    is_bonus: false,
    campaign_type: null,
    /**
     * Sets the campaign to allow dynamically added participants.
     */
    update_targets: false,
    language: 'en-US',
    current_wizard_step: 0,
    organization: null,
    group: null,
    groups: null,
    leader_template: null,
    participant_template: null,
    scheduled_confirmation_template: null
  }

  rooms = []

  roomsLoaded = false

  sessions = []

  sessionsLoaded = false

  leads = []

  leadsLoaded = false

  targets = []

  targetsLoaded = false

  busy = false

  constructor(campaign, rooms, leads, targets, sessions) {
    keys(campaign).forEach((key) => {
      if (key in this.properties && typeof this.properties[key] !== 'function') {
        this.properties[key] = campaign[key]
      }
    })
    this.rooms = rooms || []
    this.leads = leads || []
    this.targets = targets || []
    this.sessions = sessions || []
    this.busy = false
  }

  id() {
    return this.properties.id
  }

  data() {
    return cloneDeep(this.properties)
  }

  new(data, rooms, leads, targets, sessions) {
    return new CampaignManager(
      data,
      cloneDeep(rooms || this.rooms || []),
      cloneDeep(leads || this.leads || []),
      cloneDeep(targets || this.targets || []),
      cloneDeep(sessions || this.sessions || [])
    )
      .setRoomsLoaded(this.isRoomsLoaded())
      .setLeadsLoaded(this.isLeadsLoaded())
      .setTargetsLoaded(this.isTargetsLoaded())
      .setSessionsLoaded(this.isSessionsLoaded())
  }

  update(campaign, rooms, leads, targets, sessions) {
    const data = this.data()
    keys(campaign).forEach((key) => {
      if (key in data && typeof data[key] !== 'function') {
        data[key] = campaign[key]
      }
    })
    return this.new(data, rooms, leads, targets, sessions)
  }

  setBusy(value) {
    this.busy = value
    return this
  }

  getName() {
    return this.properties.name
  }

  getType() {
    return this.properties.rollout_type
  }

  updateLeads(leads) {
    this.setLeadsLoaded(true)
    return this.new(this.data(), null, leads || [])
  }

  updateRooms(rooms) {
    this.setRoomsLoaded(true)
    return this.new(this.data(), rooms || [])
  }

  updateSessions(sessions) {
    this.setSessionsLoaded(true)
    return this.new(this.data(), null, null, null, sessions)
  }

  updateTargets(targets) {
    this.setTargetsLoaded(true)
    return this.new(this.data(), null, null, targets || [])
  }

  hasRooms() {
    return this.rooms.length > 0
  }

  hasSessions() {
    return this.sessions.length > 0
  }

  hasLeads() {
    return this.leads.length > 0
  }

  hasTargets() {
    return this.targets.length > 0
  }

  getCurrentStep() {
    return this.properties.current_wizard_step
  }

  getCreatedDate() {
    return this.properties.created
  }

  getLastUpdatedDate() {
    return this.properties.updated
  }

  getStatus() {
    return this.properties.status
  }

  getOrganization() {
    return this.properties.organization
  }

  getSchedule() {
    return pick(this.properties, ['start_date', 'end_date', 'timezone'])
  }

  getInfo() {
    return pick(this.properties, ['name', 'tags', 'language'])
  }

  getParticipants() {
    return pick(this.properties, ['anonymous_filter', 'group', 'tags', 'updated_targets'])
  }

  getRollOutType() {
    return this.properties.rollout_type
  }

  getLeads() {
    return this.leads
  }

  getRooms() {
    return this.rooms
  }

  getSessions() {
    return this.sessions
  }

  getTargets() {
    return this.targets
  }

  getTags() {
    return this.properties.tags
  }

  getEmails() {
    return pick(this.properties, [
      'leader_template',
      'participant_template',
      'scheduled_confirmation_template'
    ])
  }

  getUserStep() {
    return this.properties.current_wizard_step
  }

  isDeleted() {
    return this.properties.deleted
  }

  isDynamicallyTargets() {
    return this.properties.update_targets
  }

  setDynamicallyTargets(update_targets) {
    return this.new({ ...this.data(), update_targets })
  }

  setLeadsLoaded(value) {
    this.leadsLoaded = value
    return this
  }

  setRoomsLoaded(value) {
    this.roomsLoaded = value
    return this
  }

  setSessionsLoaded(value) {
    this.sessionsLoaded = value
    return this
  }

  setTargetsLoaded(value) {
    this.targetsLoaded = value
    return this
  }

  setRollOutType(rollout_type) {
    return this.new({ ...this.data(), rollout_type })
  }

  isLeadsLoaded() {
    return this.leadsLoaded
  }

  isRoomsLoaded() {
    return this.roomsLoaded
  }

  isSessionsLoaded() {
    return this.sessionsLoaded
  }

  isTargetsLoaded() {
    return this.targetsLoaded
  }

  loaded() {
    return this.id() && this.isRoomsLoaded() && this.isLeadsLoaded()
  }
}
