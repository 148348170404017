import * as api from './api'
import { parseDateForUI, downloadCSV, exportCSVFromApi } from '../../utils'
import { CAMPAIGN_OVERVIEW_CSV_PROPERTIES } from '../../../constants'

export const downloadCompletionReportCSV = (campaignId) => async () => {
  const { data, error } = await api.fetchCampaignReport({ campaignId })
  if (!error) return exportCSVFromApi({ data, filename: 'campain_completion_report.csv' })
  return null
}

export const downloadScheduleSessionLinksCSV = (campaignId) => async () => {
  const { data, error } = await api.fetchCampaignScheduleLinks({ campaignId })
  if (!error) return exportCSVFromApi({ data, filename: 'campain_session_links.csv' })
  return null
}

export const downloadScheduleEnrollmentCSV =
  ({ rooms, campaignName }) =>
  () => {
    const type = 'enrollment'
    const rows = rooms.map((room) =>
      CAMPAIGN_OVERVIEW_CSV_PROPERTIES[type].map((key) => {
        if (key === 'enrollment')
          return `${room.participants.length || 0}/${room.room_size || '--'}`
        if (key === 'participants') return room.participants.map((emp) => emp.email).join(', ')
        if (key.endsWith('time'))
          return parseDateForUI({ date: room[key], timezone: room.timezone, time: true })
        return `${room[key] || ''}`
      })
    )
    downloadCSV({ rows, type, name: campaignName, csvProperties: CAMPAIGN_OVERVIEW_CSV_PROPERTIES })
  }

export const getCampaignStatistics = (campaignId, cb) => async () => {
  const { data, error } = await api.fetchCampaignStatistics({ campaignId })
  if (!error) {
    if (cb) cb(data)
  }
}
