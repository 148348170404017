import PropTypes from 'prop-types'
import React, { Fragment, memo } from 'react'
import {
  Typography,
  Toggle,
  TIME_ZONES_INVERTED
} from '@elevate_security/elevate-component-library'
import DropdownButton from '../../../../components/DropdownButton'
import { Container, Row, Column, ClipboardLink } from './styles'
import { ROLLOUT_TYPE_NAME, ROLLOUT_TYPE, CAMPAIGN_STATUS } from '../../../../constants'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { useActions } from '../../../CampaignWizard/utils'
import { copyToClipboard, buttonsDownloadCsvDropdown } from '../../utils'
import { parseDateForUI, getGroupListAsString } from '../../../../services/utils'

const { Text } = Typography

function CampaignInfoHeader({ campaign, behaviors = [], handleDownloadCsv }) {
  const {
    id,
    tags,
    rollout_type,
    participants_count,
    start_date,
    end_date,
    timezone,
    update_targets,
    status,
    groups
  } = campaign

  if (!campaign) return null

  const { setDynamicallyTargets } = useActions(wizardActions)

  const UIbehaviors = behaviors
    .filter((item) => tags.find((tag) => tag === item.name))
    .map((item) => item.label)
    .join(', ')

  const UIGroups = getGroupListAsString(groups)

  const isTypePeersScheduling = rollout_type === ROLLOUT_TYPE.PEER_SCHEDULING
  const enrollmentLink = `${window.location.origin}/${id}/scheduled`

  const copyLink = (e) => {
    e.preventDefault()
    const { target } = e
    const { classList } = target
    classList.add('copied')
    target.innerHTML = 'Copied'

    setTimeout(() => {
      classList.remove('copied')
      target.innerHTML = 'Copy Link'
    }, 2000)
    copyToClipboard(enrollmentLink)
  }

  const handleUpdateTargets = ({ checked }) =>
    setDynamicallyTargets({ campaignId: id, update_targets: checked })

  const renderLaunchDate = (
    <>
      <Column>
        <Text>Launch Date:</Text>
      </Column>
      <Column>
        <Text>{parseDateForUI({ date: start_date, timezone, time: true }) || 'N/A'}</Text>
      </Column>
    </>
  )

  const renderCompletionDate = (
    <>
      <Column>
        <Text>Completion Date:</Text>
      </Column>
      <Column>
        <Text>{parseDateForUI({ date: end_date, timezone, time: true }) || 'N/A'}</Text>
      </Column>
    </>
  )

  const renderTimezone = (
    <>
      <Column>
        <Text>Timezone:</Text>
      </Column>
      <Column>
        <Text>{TIME_ZONES_INVERTED[timezone] || 'N/A'}</Text>
      </Column>
    </>
  )

  const renderEnrollmentLink = (
    <>
      <Column>
        <Text>Enrollment link</Text>
      </Column>
      <Column>
        <ClipboardLink
          onClick={(e) => copyLink(e)}
          isExternal
          to={enrollmentLink}
          rel="noopener noreferrer"
          target="_blank"
          title="Click to copy the enrollment link"
        >
          Copy Link
        </ClipboardLink>
      </Column>
    </>
  )

  const renderBehavior = (
    <>
      <Column>
        <Text>Behaviors:</Text>
      </Column>
      <Column>
        <Text>{UIbehaviors || 'N/A'}</Text>
      </Column>
    </>
  )

  const renderRolloutType = (
    <>
      <Column>
        <Text>Rollout Type:</Text>
      </Column>
      <Column>
        <Text>{ROLLOUT_TYPE_NAME[rollout_type] || 'N/A'}</Text>
      </Column>
    </>
  )

  const renderParticipants = (
    <>
      <Column>
        <Text>Participants:</Text>
      </Column>
      <Column>
        <Text>{participants_count}</Text>
      </Column>
    </>
  )
  const renderGroup = (
    <>
      <Column>
        <Text>Groups:</Text>
      </Column>
      <Column>
        <Text>{UIGroups || 'N/A'}</Text>
      </Column>
    </>
  )

  const renderDropdownButton = () => {
    return (
      campaign &&
      campaign.rollout_type &&
      campaign.status && (
        <DropdownButton
          title="Downloads"
          buttons={buttonsDownloadCsvDropdown({
            isPeerScheduledRollout: campaign.rollout_type === ROLLOUT_TYPE.PEER_SCHEDULING,
            onClick: handleDownloadCsv
          })}
        />
      )
    )
  }

  return (
    <Container>
      <Row>
        <Column md={6} lg={6} sm={12}>
          <Row noWrap>{renderLaunchDate}</Row>
          <Row noWrap>{renderCompletionDate}</Row>
          <Row noWrap>{renderTimezone}</Row>
          {isTypePeersScheduling && <Row>{renderEnrollmentLink}</Row>}
        </Column>
        <Column md={6} lg={6} sm={12}>
          <Row noWrap>{renderBehavior}</Row>
          <Row noWrap>{renderRolloutType}</Row>
          <Row noWrap>{renderParticipants}</Row>
          <Row noWrap>{renderGroup}</Row>
          <Row noWrap>
            <Toggle
              onChange={handleUpdateTargets}
              label="Dynamically add new users to this campaign"
              checked={update_targets}
              disabled={status === CAMPAIGN_STATUS.COMPLETED}
            />
          </Row>
        </Column>
      </Row>
      <div>{renderDropdownButton()}</div>
    </Container>
  )
}

CampaignInfoHeader.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string,
    tags: PropTypes.shape([]),
    rollout_type: PropTypes.number,
    participants_count: PropTypes.number,
    groups: PropTypes.shape([]),
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    timezone: PropTypes.string,
    anonymous_filter: PropTypes.bool,
    group_data: PropTypes.oneOfType([PropTypes.string, PropTypes.shape([])]),
    update_targets: PropTypes.bool,
    status: PropTypes.string
  }).isRequired,
  behaviors: PropTypes.shape([]).isRequired,
  handleDownloadCsv: PropTypes.func.isRequired
}

export default memo(CampaignInfoHeader)
