import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { Typography } from '@elevate_security/elevate-component-library'

import { PieGraph } from '../Charts'
import { Row } from './styles'

import ScoreBoxes from '../ScoreBoxes'
import ScoreSummary from '../ScoreSummary'

const { H3 } = Typography

function SurveyResponses({ surveyCount, scoreBoxes, surveyProps, scoreSummary }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    title: {
      display: false
    }
  }
  return (
    <>
      <Row>
        <H3 color="900">Survey Responses</H3>
      </Row>
      <div style={{ display: 'flex' }}>
        <div style={{ width: surveyCount ? '60%' : '100%' }}>{scoreBoxes}</div>
        {surveyCount && (
          <>
            <div style={{ width: '30%' }}>
              <div style={{ height: '150px' }}>
                <PieGraph width="150px" height="150px" data={surveyProps} options={options} />
              </div>
            </div>
            <div>{scoreSummary}</div>
          </>
        )}
      </div>
    </>
  )
}

SurveyResponses.propTypes = {
  surveyCount: PropTypes.bool,
  scoreBoxes: PropTypes.shape([ScoreBoxes]).isRequired,
  scoreSummary: PropTypes.shape(ScoreSummary).isRequired,
  surveyProps: PropTypes.shape({}).isRequired
}

SurveyResponses.defaultProps = {
  surveyCount: false
}

export default memo(SurveyResponses)
