import React from 'react'
import PropTypes from 'prop-types'
import { EditorState, Modifier } from 'draft-js'
import Dropdown from '../DropDown'

function CustomOption({ editorState, onChange }) {
  const { getCurrentContent, getSelection, getCurrentInlineStyle } = editorState

  const createOption = (keyword) => {
    const contentState = Modifier.replaceText(
      getCurrentContent(),
      getSelection(),
      keyword,
      getCurrentInlineStyle()
    )
    onChange(EditorState.push(editorState, contentState, 'insert-characters'))
  }

  const addFirstName = () => createOption('{{ first_name }}')

  const addEmail = () => createOption('{{ email }}')

  const addHMSession = () => createOption('{{ session_url }}')

  const addSignUpLink = () => createOption('{{ scheduled_session_url }}')

  return (
    <Dropdown
      className="rdw-inline-wrapper rdw-option-wrapper rdw-dropdown-wrapper justify-content-between"
      style={{ padding: '0 5px', width: '110px', color: '#444D58', fontSize: '15px' }}
      options={[
        { name: 'first name', action: addFirstName },
        { name: 'email', action: addEmail },
        { name: 'session', action: addHMSession },
        { name: 'room signup', action: addSignUpLink }
      ]}
      buttonStyle={{ width: '88%' }}
      title="Insert macro"
    />
  )
}

CustomOption.propTypes = {
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  onChange: PropTypes.func
}

CustomOption.defaultProps = {
  onChange: () => null
}

export default CustomOption
