import styled from 'styled-components'
import { Typography } from '@elevate_security/elevate-component-library'
import 'react-datepicker/dist/react-datepicker.css'

const { Body } = Typography

export const Flex = styled.div`
  display: flex;
  flex-grow: ${(props) => (props.grow >= 0 ? props.grow : 1)};
  flex-shrink: ${(props) => (props.shrink >= 0 ? props.shrink : 1)};
  flex-basis: ${(props) => (props.basis ? props.basis : 'auto')};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'flex-start'};
  align-self: ${(props) => props.self || 'initial'};
`

export const FlexRow = styled(Flex)`
  flex-direction: row;
  align-items: ${(props) => props.alignItems || 'flex-start'};
  min-width: ${(props) => (props.stretch ? '100%' : 'auto')};
  max-width: ${(props) => (props.stretch ? '100%' : 'auto')};
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  height: ${(props) => props.height || 'auto'};
  min-height: ${(props) => props.height || 'auto'};

  & > * {
    margin-right: ${(props) => props.gap || 0};

    &:last-child {
      margin-right: 0;
    }
  }
`

export const FlexColumn = styled(Flex)`
  flex-direction: column;
  min-height: ${(props) => (props.stretch ? '100%' : 'auto')};
  max-height: ${(props) => (props.stretch ? '100%' : 'auto')};
  width: ${(props) => props.width || 'auto'};
  min-width: ${(props) => props.width || 'auto'};

  & > * {
    margin-bottom: ${(props) => props.gap || 0};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const FlexItem = styled.div`
  flex-basis: ${(props) => props.width || 'auto'};
  flex-grow: ${(props) => props.grow || 0};
  flex-shrink: ${(props) => props.shrink || 1};
  min-width: ${(props) => props.width || 'auto'};
  max-width: ${(props) => props.width || 'auto'};
  align-self: ${(props) => props.self || 'initial'};
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  height: ${(props) => props.height || 'auto'};
`

export const TextCenter = styled(Body)`
  text-align: center;
`
