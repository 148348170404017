import styled from 'styled-components'

export const ModalHeader = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(58, 70, 85, 0.15);
  padding-bottom: 18px;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;

  h2 {
    margin: 0;
  }
`

export const Container = styled.div`
  margin: 20px 0;
`

export const Toast = styled.div`
  max-width: 97%;
  background-color: #fff3ca;
  border-radius: 4px;
  margin-top: 25px;
  padding: 20px;
`
export const OkWrapper = styled.span`
  float: right;
  color: #0c7d84;
  font-weight: bold;
`

export const GoToSessionsUpload = styled.div`
  margin-top: 20px;
`
